<template>
  <div dusk="contact-information">
    <TransferStepper current-step="contactInformation" />

    <div class="container--sm my-20">
      <h2>Kontaktní údaje podepisující osoby...</h2>

      <form
        dusk="contact-form"
        @submit.prevent="submitStep"
      >
        <LitAlert
          v-show="signer.type.value === 'committee'"
          class="mb-20 mt-20"
        >
          Budeme potřebovat rozsudek opatrovnického soudu, kterým soud schvaluje
          právní jednání za opatrovaného, kterým je ukončení penzijního spoření
          za opatrovance podle žádosti opatrovníka a listinu o jmenování opatrovníka.
          Kopie musí být ověřena podle originálu
          (samolepka vidimace/ razítko souhlasí s originálem/ text „souhlasí s originálem“).
        </LitAlert>

        <ContactInformation
          store-path="contactInformation"
          namespace="transfer"
          title="Podpis na ukončení je podpisem"
          :signer-types="signerTypes"
          :validate-fnc="validateField"
        />
      </form>
    </div>

    <div class="container my-30">
      <hr>
    </div>

    <StepButtons
      namespace="transfer"
      previous-step="salesInterview"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import ContactInformation from '../../common/ContactInformation.vue';
import StepButtons from '../../common/StepButtons.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import TransferStepper from '@/js/components/Steppers/TransferStepper.vue';
import { contactInformationFields } from '@/js/data/validationFields/commonFields';

import gtm from '@/js/services/gtm';

import { scrollToError } from '@/js/utils';
import { useTransferStore } from '@/js/stores';

const route = useRoute();
const router = useRouter();

const store = useTransferStore();
const { validateFields, validateField } = store;
const { signer } = storeToRefs(store);

const signerTypes = shallowRef([
  { value: 'participant', label: 'účastníka' },
  { value: 'committee', label: 'opatrovníka' },
]);

const validating = ref(false);
const contractDocumentsRequired = ref(false);

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;
    contractDocumentsRequired.value = store.beforeStart.isTiedAgent.value;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        ...contactInformationFields,
        'contactInformation.phonePrefix',
        'signer.document.type',
      ],
      documentsSent: contractDocumentsRequired.value,
    });

    validating.value = false;

    await router.push({
      name: 'transfer.personalData',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('kontaktni-udaje');
  } catch (e) {
    validating.value = false;
    contractDocumentsRequired.value = false;

    scrollToError();

    console.warn(`There was a validation personalDataError: ${e}`);
  }
}
</script>
