<template>
  <div dusk="investment-questionnaire">
    <OnlineAgreementStepper :current-step="3" />

    <div class="container--sm">
      <h2 dusk="contribution-heading">
        Výše příspěvku, který si budete měsíčně platit
      </h2>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="onlineAgreement"
        field-path="contributionAndStrategy.contributionAmount"
        :validate-on-input="true"
        @validate="validateField"
      >
        <ContributionSelection
          v-model:contribution-group="contributionGroup"
          class="mb-30"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <LitAlert
        v-if="canParticipantBeInRent"
        alert-type="warning"
      >
        Upozorňujeme, že na státní příspěvky nemají nárok klienti, kterým byl přiznán starobní důchod.
      </LitAlert>

      <h2 dusk="questionnaire-heading">
        Nastavte si správnou strategii penzijního spoření
      </h2>

      <LitAlert>
        Musíme se zeptat na vaše znalosti a zkušenosti z oblasti financí
        a investičních nástrojů, abychom vám mohli doporučit vhodnou

        <button
          type="button"
          class="btn-simple"
          @click="showSavingsStrategy = true"
        >
          strategii spoření
        </button>.
      </LitAlert>

      <div class="stepper-vertical">
        <div
          class="step"
          dusk="invest-questionnaire"
        >
          <div
            id="questionnaire"
            class="step__header"
          >
            <div class="step__icon">
              <span class="step__icon--number">
                1
              </span>
            </div>

            <div class="step__label">
              Vyplňte krátký penzijní dotazník
            </div>
          </div>

          <div class="step__container">
            <div class="step__content">
              <LitAlert
                v-if="isQuestionnaireProcessed"
                dusk="questionnaire-successful-alert"
                alert-type="success"
                class="mb-20"
              >
                Penzijní dotazník byl úspěšně vyplněn a vyhodnocen.
              </LitAlert>

              <LitAlert
                v-if="!showStrategySelection"
                dusk="choose-own-strategy-alert"
                class="mb-20"
              >
                Pokud si nepřejete vyplňovat krátký penzijní dotazník,

                <button
                  class="btn-simple"
                  type="button"
                  @click="onCustomStrategyClick"
                >
                  vyberte si sami vlastní strategii
                </button>.
              </LitAlert>

              <LitAlert
                v-if="selectCustomStrategy"
                dusk="recommended-strategy-choice-alert"
                alert-type="warning"
                class="mb-20"
              >
                Pokud si chcete nechat doporučit vhodnou strategii pro vás,

                <button
                  class="btn-simple"
                  type="button"
                  @click="onFillQuestionnaireClick"
                >
                  vyplňte krátký penzijní dotazník
                </button>.
              </LitAlert>

              <div
                v-if="isQuestionnaireProcessed"
                dusk="toggle-show-questionnaire"
                class="btn-simple mb-20"
                @click="showQuestionnaire = !showQuestionnaire"
              >
                <span v-if="!showQuestionnaire">
                  Zobrazit vaše odpovědi penzijního dotazníku, případně dotazník upravit
                </span>

                <span v-else>
                  Skrýt odpovědi penzijního dotazníku
                </span>

                <img
                  class="ml-7"
                  :class="{ rotated: showQuestionnaire }"
                  :src="getIconUrl('arrow_down-green')"
                  alt="šipka"
                >
              </div>

              <div v-show="!loading && showQuestionnaire">
                <div
                  v-for="question in questions"
                  :key="question.id"
                  class="question mb-20"
                  :dusk="`question${question.id}`"
                  :class="{ selected: question.answer !== null }"
                >
                  <!--
                  When the user receives a KO, dont display the following question (+0).
                  Otherwise, show (+1).
                  -->

                  <template
                    v-if="question.id <= lastAnsweredQuestion + (status.isKo ? 0 : 1)"
                  >
                    <div class="question__header">
                      <div class="question__count">
                        {{ question.id }}.
                      </div>

                      <div class="question__label">
                        {{ question.label }}
                      </div>

                      <div class="question__action">
                        <button
                          v-if="question.answer !== null"
                          type="button"
                          dusk="change-answer"
                          class="btn-simple"
                          @click="resetAnswer(question.id)"
                        >
                          <i>
                            <img
                              :src="getIconUrl('icon-edit')"
                              alt="Ikona upravit"
                            >
                          </i>
                          Změnit odpověď
                        </button>
                      </div>
                    </div>

                    <div class="question__content">
                      <ServerValidatedFormField
                        v-slot="{ value, input, errors }"
                        namespace="onlineAgreement"
                        :debounce="0"
                        :default-value="question.answer"
                        :field-path="`contributionAndStrategy.questionnaire.question${question.id}`"
                        :validate-on-input="true"
                        @validate="validateField"
                      >
                        <LitRadio
                          :show-only-selected="question.answer !== null"
                          :name="`question${question.id}Options`"
                          :options="question.options"
                          :model-value="value"
                          :error="errors.length > 0 ? errors[0] : ''"
                          @update:model-value="input"
                        />
                      </ServerValidatedFormField>
                    </div>
                  </template>
                </div>
              </div>

              <div v-if="loading">
                <div class="flex flex-center mb-20 flip">
                  <img
                    :src="getIconUrl('icon-loader_big')"
                    alt="Načítání..."
                    class="spin"
                  >
                </div>
                <div class="text-warning text-center text-bold">
                  Vyhodnocujeme Vaše odpovědi, chvíli strpení
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          dusk="strategy-step"
          class="step"
          :class="{ disabled: !showStrategySelection }"
        >
          <div
            id="customStrategy"
            class="step__header"
          >
            <div class="step__icon">
              <span class="step__icon--number">
                2
              </span>
            </div>

            <div class="step__label">
              Vyberte si strategii
            </div>
          </div>

          <div class="step__container">
            <div class="step__content">
              <LitAlert
                v-if="!showStrategySelection"
                dusk="step-over-questionnaire-alert"
                alert-type="warning"
              >
                Vyplňte výše uvedený penzijní dotazník. Na jeho základě doporučíme spořicí strategii
                vhodnou právě pro vás. Pokud si přejete vybrat strategii sami,

                <button
                  class="btn-simple"
                  type="button"
                  @click="onCustomStrategyClick"
                >
                  přeskočte penzijní dotazník
                </button>.
              </LitAlert>

              <div
                v-if="selectCustomStrategy"
              >
                <p class="mb-30">
                  Výběrem vlastní strategie spoření se vystavujete riziku,
                  že zvolená strategie spoření nebude odpovídat
                  vašim cílům, odborným znalostem nebo zkušenostem potřebným
                  pro pochopení rizik souvisejících s investováním.
                </p>

                <ServerValidatedFormField
                  v-slot="{ value, input, errors }"
                  namespace="onlineAgreement"
                  field-path="contributionAndStrategy.selectedStrategy"
                  :debounce="0"
                  :validate-on-input="true"
                  @validate="validateField"
                >
                  <CustomStrategySelection
                    v-model:fund-combination="fundCombination"
                    :model-value="value"
                    :error="errors.length > 0 ? errors[0] : ''"
                    :prefer-conservative-fund="preferConservativeFund"
                    :meet-reinvestment-condition="transferExistingRetirementSavings"
                    @update:model-value="input"
                  />
                </ServerValidatedFormField>
              </div>

              <div
                v-if="isQuestionnaireProcessed"
              >
                <p class="mb-30">
                  Na základě vašich odpovědí vám doporučujeme níže uvedené strategie.
                </p>

                <ServerValidatedFormField
                  v-slot="{ value, input, errors }"
                  namespace="onlineAgreement"
                  field-path="contributionAndStrategy.selectedStrategy"
                  :debounce="0"
                  :validate-on-input="true"
                  @validate="validateField"
                >
                  <StrategySelection
                    dusk="strategy-selection"
                    :total-points="totalPoints"
                    :model-value="value"
                    :error="errors.length > 0 ? errors[0] : ''"
                    :prefer-conservative-fund="preferConservativeFund"
                    :prefer-reinvestment="transferExistingRetirementSavings"
                    @update:model-value="input"
                  />
                </ServerValidatedFormField>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <div class="container--sm flex flex-between">
      <router-link
        :to="{ name: 'onlineAgreement.legalRequirements' }"
        class="btn btn-big btn-icon-back btn-outline"
        dusk="step-back"
      >
        <LitIconSvg
          class="btn__icon btn__icon--big"
          icon-name="arrow_green-left"
        />

        <span>
          Zpět
        </span>
      </router-link>

      <button
        class="btn btn-green btn-big btn-icon-right"
        type="button"
        dusk="submit-step"
        :class="{ disabled: !canBeSubmitted }"
        :disabled="!canBeSubmitted"
        @click="submitStep"
      >
        <span>
          Pokračovat
        </span>

        <LitIconSvg
          class="btn__icon btn__icon--end btn__icon--end--big"
          icon-name="arrow_white-right"
        />
      </button>
    </div>

    <StrategyOfPensionSavingModal
      :model-value="showSavingsStrategy"
      form-type="online"
      @close="showSavingsStrategy = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import StrategyOfPensionSavingModal from '@/js/components/Modals/StrategyOfPensionSavingModal.vue';

import ContributionSelection from '@/js/components/ContributionSelection.vue';

import gtm from '@/js/services/gtm';

import StrategySelection from '@/js/components/StrategySelection.vue';
import CustomStrategySelection from '@/js/components/CustomStrategySelection.vue';
import OnlineAgreementStepper from '@/js/components/Steppers/OnlineAgreementStepper.vue';
import { contributionAndStrategyFields } from '@/js/data/validationFields/commonFields';
import availableAnswers from '@/js/data/availableFinancialQuestionnaireAnswers';

import { canEnterRent, getIconUrl, personalIdNumber, scrollToError } from '@/js/utils';
import { useOnlineAgreementStore } from '@/js/stores';
import { measureAdobeAnalytics, updateContract } from '@/js/stores/utils';
import { EContractType } from '@/js/types/contract.ts';

const store = useOnlineAgreementStore();
const {
  contractSelection,
  participantsBirthDay,
  contributionAndStrategy,
} = storeToRefs(store);
const { validateField, validateFields } = store;

const route = useRoute();
const router = useRouter();

const loading = ref(false);
const showSavingsStrategy = ref(false);
const validating = ref(false);

const availableQuestions = shallowRef(availableAnswers);

const preferConservativeFund = computed(() => canEnterRent(participantsBirthDay.value));

const questions = computed(() => {
  return availableQuestions.value.map(({ id, answer, ...rest }) => ({
    id,
    // @ts-expect-error: TODO - fix this
    answer: contributionAndStrategy.value
      .questionnaire[`question${id}`].value,
    ...rest,
  }));
});

const canParticipantBeInRent = computed(() => {
  const age = personalIdNumber(store.personalData.personalIdNumber.value)?.age;

  return typeof age !== 'undefined' && age >= 60;
});

const lastAnsweredQuestion = computed(() => {
  let lastAnsweredQuestion = 0;

  questions.value.forEach((question) => {
    if (question.answer !== null) {
      lastAnsweredQuestion = question.id;
    }
  });

  return lastAnsweredQuestion;
});

const status = computed(() => {
  let questionsToAnswer = questions.value.length;
  let points = 0;

  for (let i = 0; i < questions.value.length; i += 1) {
    const question = questions.value[i];
    const answerId = question.answer as Partial<keyof typeof question.optionPoints>;

    if (question.answer !== null) {
      questionsToAnswer -= 1;

      if (answerId === question.koAnswer) {
        return {
          totalPoints: 0,
          unAnsweredQuestions: questionsToAnswer,
          isKo: true,
        };
      }

      if (question.optionPoints && answerId in question.optionPoints) {
        points += question.optionPoints[answerId] || 0;
      }
    }
  }

  return {
    totalPoints: points,
    unansweredQuestions: questionsToAnswer,
    isKo: false,
  };
});

const totalPoints = computed({
  get () {
    return contributionAndStrategy.value.totalPoints.value;
  },
  set (value) {
    contributionAndStrategy.value.totalPoints.value = value;
  },
});

const isQuestionnaireProcessed = computed(() => {
  return totalPoints.value !== null && !loading.value;
});

const selectCustomStrategy = computed({
  get () {
    return contributionAndStrategy.value.selectCustomStrategy.value;
  },
  set (value) {
    contributionAndStrategy.value.selectCustomStrategy.value = value;
  },
});

const showStrategySelection = computed(() => {
  return isQuestionnaireProcessed.value || selectCustomStrategy.value;
});

const fundCombination = computed({
  get () {
    return contributionAndStrategy.value.fundCombination.value;
  },
  set (value) {
    contributionAndStrategy.value.fundCombination.value = value;
  },
});

const isFundCombinationComplete = computed(() => {
  const sum = Object.values(fundCombination.value).reduce((accumulator, current) => accumulator + current, 0);

  return sum === 100;
});

const selectedStrategy = computed({
  get () {
    return contributionAndStrategy.value.selectedStrategy.value;
  },
  set (value) {
    contributionAndStrategy.value.selectedStrategy.value = value;
  },
});

const canBeSubmitted = computed(() => {
  if (selectedStrategy.value === 'customFundCombination') {
    return isFundCombinationComplete.value;
  }

  return selectedStrategy.value !== null;
});

const contributionGroup = computed({
  get () {
    return contributionAndStrategy.value.contributionGroup.value;
  },
  set (value) {
    contributionAndStrategy.value.contributionGroup.value = value;
  },
});

const showQuestionnaire = computed({
  get () {
    return contributionAndStrategy.value.showQuestionnaire.value;
  },
  set (value) {
    contributionAndStrategy.value.showQuestionnaire.value = value;
  },
});

const transferExistingRetirementSavings = computed(() => {
  return contractSelection.value.type.value !== EContractType.Online;
});

function setStrategy () {
  if (transferExistingRetirementSavings.value && selectedStrategy.value === 'lifeCycle') {
    selectedStrategy.value = 'reinvestment';
  }

  if (!transferExistingRetirementSavings.value && selectedStrategy.value === 'reinvestment') {
    selectedStrategy.value = 'lifeCycle';
  }
}

function setTotalPointsFromStatus () {
  if ((status.value.isKo || status.value.unansweredQuestions === 0)
    && totalPoints.value === null) {
    totalPoints.value = status.value.totalPoints;
  }
}

function setContributionGroup () {
  switch (contributionAndStrategy.value.contributionAmount.value) {
    case 0:
      contributionGroup.value = 'option-1';
      break;
    case 500:
      contributionGroup.value = 'option-500';
      break;
    case 1700:
      contributionGroup.value = 'option-1000';
      break;
    case 5700:
      contributionGroup.value = 'option-5000';
      break;
    default:
      contributionGroup.value = 'option-custom';
  }
}

function setShowQuestionnaire () {
  if (!selectCustomStrategy.value) {
    showQuestionnaire.value = !isQuestionnaireProcessed.value;
  } else {
    showQuestionnaire.value = false;
  }
}

function setAnswer (questionId: number, answer: string | null) {
  // @ts-expect-error: TODO - fix this
  contributionAndStrategy.value.questionnaire[`question${questionId}`].value = answer;
}

function resetAnswer (questionId: number) {
  totalPoints.value = null;

  questions.value.forEach((question) => {
    if (question.id >= questionId) {
      setAnswer(question.id, null);
    }
  });
}

function onCustomStrategyClick () {
  resetAnswer(1);

  selectedStrategy.value = null;
  selectCustomStrategy.value = true;
  showQuestionnaire.value = false;
}

function onFillQuestionnaireClick () {
  selectedStrategy.value = null;
  selectCustomStrategy.value = false;
  showQuestionnaire.value = true;
}

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: contributionAndStrategyFields,
    });

    await router.push({
      params: {
        contractUuid,
      },
      name: 'onlineAgreement.contractSettings',
    });

    gtm.onStepSubmit('vyse-prispevku-a-strategie');

    if (!transferExistingRetirementSavings.value) {
      measureAdobeAnalytics({
        state: store.$state,
        action: 'ufSubmit',
        contractUuid,
        path: route.path,
        fields: [
          { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
          { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
          { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
          { storePath: 'contactInformation.email.value', fieldName: 'email' },
          { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
          { fetcher: () => true, fieldName: 'marketingagreement' },
        ],
      });
    }

    await router.push({
      name: 'onlineAgreement.contractSettings',
      params: {
        contractUuid,
      },
    });
  } catch (e) {
    console.warn(`There was a validation contractSettingsError: ${e}`);
  } finally {
    validating.value = false;

    scrollToError();
  }
}

watch(status, (newVal) => {
  if (newVal.unansweredQuestions === 0 || newVal.isKo) {
    totalPoints.value = newVal.totalPoints;
    loading.value = true;
    showQuestionnaire.value = false;

    setTimeout(() => {
      loading.value = false;
    }, 1700);
  }
});

watch(showStrategySelection, (newVal) => {
  if (newVal === false) {
    selectedStrategy.value = null;
  }
});

watch(selectCustomStrategy, async (val) => {
  const { contractUuid } = route.params;

  if (val === true) {
    showQuestionnaire.value = false;
  }

  await validateFields({
    contractUuid,
    throwOnErrors: false,
    fields: [
      {
        value: val,
        path: 'contributionAndStrategy.selectCustomStrategy',
      },
    ],
  });
});

watch(fundCombination, () => {
  const { contractUuid } = route.params;

  updateContract({
    state: store.$state,
    contractUuid: contractUuid as string,
  });
}, {
  deep: true,
});

onMounted(() => {
  setTotalPointsFromStatus();
  setContributionGroup();
  setShowQuestionnaire();
  setStrategy();

  const { contractUuid } = route.params;

  measureAdobeAnalytics({
    action: 'formStepView',
    contractUuid,
    path: route.path,
  });
});
</script>

<style lang="scss" scoped>
  @import '@sass/tools/variables';
  @import '@sass/tools/mixins';
  @import '@sass/tools/functions';

  .stepper-vertical {
    margin-bottom: 20px;

    .step {
      &__header {
        font-family: $fontSecondary;
        font-size: 21px;
        display: flex;
        align-items: center;
        padding: 18px 0;

        @include media(min, $sm) {
          font-size: 22px;
        }
      }

      &__icon {
        border-radius: 50%;
        height: 23px;
        width: 23px;
        flex-shrink: 0;
        position: relative;
        font-size: $root-mini;
        font-weight: $bold;
        background: getColor(default);
        color: getColor(white);
        margin-right: 10px;

        @include media(min, $sm) {
          height: 41px;
          width: 41px;
          font-size: 21px;
        }

        &--number {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 13px;

          @include media(min, $sm) {
            height: 20.5px;
          }
        }
      }

      &__container {
        margin-left: 11.5px;
        border: 0;
        position: relative;

        @include media(min, $sm) {
          margin-left: 20.5px;
        }

        [class*='alert-'] {
          min-height: 55px;
        }

        .underlined {
          text-decoration: underline;

          .rotated {
            transform: rotate(180deg);
          }
        }
      }

      &:not(:last-of-type) {
        .step__container {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            border-left: 1px solid getColor(default);
            top: -6px;
            bottom: -6px;

            @include media(min, $sm) {
              left: -1px;
              border-left-width: 2px;
            }
          }
        }
      }

      &__content {
        padding: 5px 0  5px 30.5px;
      }

      &.disabled {
        .step__icon {
          background: getColor(light-blue);
          color: getColor(default);
        }
      }
    }
  }

  .question {
    &__header {
      display: flex;
      margin-bottom: 10px;
      font-weight: $bold;
      line-height: 1.45;
    }

    &__count {
      flex-shrink: 0;
      margin-right: 15px;
    }

    &__label {
      margin-right: 10px;
      flex-grow: 1;
    }

    &__action {
      flex-shrink: 0;
      justify-self: flex-end;
      font-weight: $normal;
    }

    &__content {
      margin-left: 10px;
    }

    &.selected &__header > *:not(.question__action) {
      opacity: .5;
    }

    &.selected &__content > * {
      opacity: .5;
    }
  }
</style>
