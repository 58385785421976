<template>
  <div class="container--sm py-20">
    <h1>Showcase</h1>

    <h2>Inputs</h2>

    <LitInput
      v-model="litInput"
      class="mb-20"
      label="Label text"
      name="litInput"
      placeholder="Placeholder"
    />

    <DatePicker
      v-model="datePicker"
      class="mb-20"
      label="Label text"
      name="datePicker"
    />

    <AddressAutocomplete
      v-model="addressAutocomplete"
      label="Autocomplete address"
      name="addressAutocomplete"
      class="mb-20"
    />

    <hr>
    <h2>Select</h2>
    <LitSelect
      v-model="litSelect"
      :options="[
        'Option 1',
        'Option 2'
      ]"
      label="Label text"
      class="mb-20"
    />
    <hr>

    <h2>Checkbox</h2>
    <LitCheckbox
      v-model="litCheckbox"
      class="mb-20"
    >
      Label text
    </LitCheckbox>
    <hr>

    <h2>Radio</h2>
    <LitRadioGroup
      v-model="litRadioGroup"
      title="Label text"
      name="litRadioGroup"
      class="mb-20"
      :options="{
        0: 'Label 1',
        1: 'Label 2'
      }"
    />

    <h4>Radio in row</h4>
    <LitRadio
      v-model="litRadio"
      :options="{
        0: 'Ano',
        1: 'Ne'
      }"
      name="litRadio"
      :show-inline="true"
      tooltip="Testovací text"
      class="mb-20"
      label="Label text"
    />

    <hr>
    <h4>Radio in column</h4>
    <LitRadio
      v-model="litRadio"
      :options="{
        0: 'Ano',
        1: 'Ne'
      }"
      name="litRadio2"
      class="mb-20"
    />

    <hr>
    <h2>Alerts</h2>

    <LitAlert class="mb-20">
      Lorem ipsum
    </LitAlert>

    <LitAlert
      class="mb-20"
      alert-type="success"
    >
      Lorem ipsum
    </LitAlert>

    <LitAlert
      class="mb-20"
      alert-type="warning"
    >
      Lorem ipsum
    </LitAlert>

    <LitAlert
      class="mb-20"
      alert-type="danger"
    >
      Lorem ipsum
    </LitAlert>

    <hr>
    <h2>Modal</h2>

    <button
      class="btn btn-primary"
      @click="showModal = !showModal"
    >
      Show modal
    </button>

    <LitModal
      v-model="showModal"
    >
      <template #header>
        <h3>
          Modal header
        </h3>
      </template>

      <template #body>
        Lorem ipsum
      </template>

      <template #footer>
        <div class="modal__buttons">
          <button
            dusk="close-modal"
            class="btn btn-primary btn-outline"
            @click="showModal = false"
          >
            Cancel
          </button>

          <button
            class="btn btn-green btn-primary"
          >
            Save
          </button>
        </div>
      </template>
    </LitModal>

    <hr>

    <h2>Funds</h2>
    <FundsChart fund-type="balanced" />

    <hr>

    <h2>Tabs</h2>

    <LitTabs>
      <LitTab
        title="Strategie životního cyklu"
        :title-info="[
          'Text 1'
        ]"
      >
        Content tab 1
      </LitTab>

      <LitTab
        title="Konzervativní fond"
        label="Doporučujeme"
        label-type="warning"
        time-indicator="2 prac. dny"
        indicator-type="danger"
      >
        Tab Content 2
      </LitTab>
    </LitTabs>
  </div>
</template>

<script>
import LitRadioGroup from '../components/Base/LitRadioGroup.vue';
import LitCheckbox from '../components/Base/LitCheckbox.vue';
import LitSelect from '../components/Base/LitSelect.vue';
import LitRadio from '../components/Base/LitRadio.vue';
import LitInput from '../components/Base/LitInput.vue';
import LitAlert from '../components/Base/LitAlert.vue';
import LitModal from '../components/Base/LitModal.vue';
import LitTabs from '../components/Base/Tabs/LitTabs.vue';
import LitTab from '../components/Base/Tabs/LitTab.vue';
import DatePicker from '../components/DatePicker.vue';
import AddressAutocomplete from '../components/AddressAutocomplete.vue';
import FundsChart from '../components/FundsChart.vue';

export default {
  components: {
    LitTab,
    LitTabs,
    LitRadioGroup,
    LitRadio,
    LitAlert,
    LitModal,
    LitCheckbox,
    LitSelect,
    LitInput,
    DatePicker,
    AddressAutocomplete,
    FundsChart,
  },

  data: () => ({
    litRadioGroup: '',
    litRadio: '',
    showModal: false,
    litCheckbox: false,
    litSelect: '',
    litInput: '',
    datePicker: '',
    addressAutocomplete: null,
  }),
};
</script>
