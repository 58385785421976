<script setup lang="ts" generic="T extends SignerReturn">
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

import { useCitizenShipTranslation } from '@/js/composables/useCitizenShipTranslation';
import { usePermanentAddressAreaTranslation } from '@/js/composables/usePermanentAddressAreaTranslation';
import { useCountryTranslation } from '@/js/composables/useCountryTranslation';
import type { SignerReturn } from '@/js/stores/createStoreFields';
import Document from '@/js/views/common/recapitulation/Document.vue';
import RecapitulationTableRow from '@/js/components/RecapitulationTableRow.vue';

interface Props {
  storePath: T
  namespace: string
}

const props = defineProps<Props>();

function signerType (value: string) {
  switch (true) {
    case value === 'participant':
      return 'účastník';
    case value === 'legalRepresentative':
      return 'zákonný zástupce';
    case value === 'committee':
      return 'opatrovník';
    case value === 'curator':
      return 'poručník';
    case value === 'procuracy':
      return 'jednající na základě plné moci';
    default:
      return value;
  }
}
</script>

<template>
  <table class="recapitulation-table mb-16">
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Osobní údaje podepisující osoby</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${props.namespace}.personalData` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <RecapitulationTableRow :path="props.storePath.type" table-key="Podepisující osoba je">
        <template #tableValue>
          {{ signerType(props.storePath.type.value) }}
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow :path="props.storePath.citizenship" table-key="Státní občanství">
        <template #tableValue>
          {{ useCitizenShipTranslation(props.storePath.citizenship.value) }}
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow
        v-if="props.storePath.permanentAddressArea.value === 'cz'"
        :path="props.storePath.personalIdNumber"
        table-key="Rodné číslo"
      />

      <RecapitulationTableRow
        v-else-if="props.storePath.permanentAddressArea.value !== 'cz'
          && props.storePath.personalIdNumber.value"
        table-key="Číslo pojištěnce zdr. pojišťovny"
        :path="props.storePath.personalIdNumber"
      />

      <RecapitulationTableRow :path="props.storePath.birthDate" table-key="Datum narození" />
      <RecapitulationTableRow :path="props.storePath.birthplace" table-key="Místo narození" />

      <RecapitulationTableRow :path="props.storePath.permanentAddressArea" table-key="Trvalé bydliště">
        <template #tableValue>
          {{ usePermanentAddressAreaTranslation(props.storePath.permanentAddressArea.value) }}
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow
        v-if="typeof props.storePath.permanentAddress !== 'undefined'"
        :path="props.storePath.permanentAddress.street"
        table-key="Trvalá adresa"
        :additional-validation-paths="[
          props.storePath.permanentAddress.city,
          props.storePath.permanentAddress.zip,
          props.storePath.permanentAddress.country]"
      >
        <template #tableValue>
          <ul
            class="list-unstyled"
          >
            <li>
              {{
                props.storePath.permanentAddress.street.value
                  ? props.storePath.permanentAddress.street.value
                  : props.storePath.permanentAddress.city.value
              }} {{ props.storePath.permanentAddress.streetNumber.value }}
            </li>
            <li>{{ props.storePath.permanentAddress.city.value }}</li>
            <li>{{ props.storePath.permanentAddress.zip.value }}</li>
            <li>{{ useCountryTranslation(props.storePath.permanentAddress.country.value) }}</li>
          </ul>
        </template>
      </RecapitulationTableRow>

      <Document
        v-if="typeof props.storePath.document !== 'undefined'"
        :store-path="props.storePath.document"
        :namespace="namespace"
      />
    </tbody>
  </table>
</template>
