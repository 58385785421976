import axios from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@unhead/vue';
import * as Sentry from '@sentry/vue';

import env from './env';
import { router } from './router';

import OnboardingForm from './views/OnboardingForm.vue';

import Error404 from './views/Error404.vue';
import ServerValidatedFormField from './components/ServerValidatedFormField.vue';

const app = createApp({});
const pinia = createPinia();

app.use(router);
app.use(pinia);

app.use(createHead());

const replay = Sentry.replayIntegration();

Sentry.init({
  app,

  dsn: env.SENTRY_DSN,
  environment: env.APP_ENV,
  release: env.APP_VERSION,
  replaysSessionSampleRate: Number.parseFloat(env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE || '0.0'),
  replaysOnErrorSampleRate: Number.parseFloat(env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || '0.0'),

  integrations: [
    replay,
  ],

  beforeSend (event, hint) {
    // Ignore client validation errors (422)
    if (hint.originalException && (hint.originalException as any)?.response?.status === 422) {
      return null;
    }

    return event;
  },
});

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

if (env.WS_ENABLE) {
  window.Pusher = Pusher;

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: env.WS_APP_KEY,
    wsHost: env.WS_SERVER_HOST,
    wsPort: env.WS_SERVER_PORT,
    forceTLS: false,
    encrypted: false,
    disableStats: true,
    enableTransports: ['ws', 'wss'],
  });
}

// Components
app.component('ServerValidatedFormField', ServerValidatedFormField);

// Views
app.component('OnboardingForm', OnboardingForm);
app.component('Error404', Error404);

app.mount('#app');
