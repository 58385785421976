<template>
  <div dusk="contact-information">
    <DistributionStepper current-step="contactInformation" />

    <div class="container--sm my-20">
      <h2>Kontaktní údaje podepisující osoby...</h2>

      <form
        dusk="contact-form"
        @submit.prevent="submitStep"
      >
        <LitAlert
          v-show="signerType === 'committee'"
          class="mb-20 mt-20"
        >
          Budeme potřebovat rozsudek soudu nebo listinu o jmenování opatrovníka
          s vyznačenou právní mocí, která by neměla být starší déle než 3 roky.
          Kopie musí být ověřena podle originálu (samolepka vidimace/razítko
          souhlasí s originálem).
        </LitAlert>

        <LitAlert
          v-show="signerType === 'legalRepresentative'"
          class="mb-20 mt-20"
        >
          Budeme potřebovat doložit <strong>kopii rodného listu vašeho dítěte</strong>,
          abychom si ověřili, že jste oprávněn/a jeho jménem uzavřít smlouvu
          o penzijním spoření.
        </LitAlert>

        <ContactInformation
          store-path="contactInformation"
          namespace="distribution"
          :validate-fnc="validateField"
        />
      </form>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <StepButtons
      namespace="distribution"
      previous-step="salesInterview"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import ContactInformation from '@/js/views/common/ContactInformation.vue';
import DistributionStepper from '@/js/components/Steppers/DistributionStepper.vue';
import StepButtons from '@/js/views/common/StepButtons.vue';

import gtm from '@/js/services/gtm';
import { scrollToError } from '@/js/utils';
import { useDistributionStore } from '@/js/stores';
import { contactInformationFields } from '@/js/data/validationFields/commonFields';

const store = useDistributionStore();
const { signer } = storeToRefs(store);
const { validateFields, validateField } = store;

const validating = ref(false);
const contractDocumentsRequired = ref(false);

const route = useRoute();
const router = useRouter();

const signerType = computed(() => signer.value.type.value);

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;
    contractDocumentsRequired.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        ...contactInformationFields,
        'contactInformation.phonePrefix',
        'signer.document.type',
      ],
      documentsSent: contractDocumentsRequired.value,
    });

    validating.value = false;

    await router.push({
      name: 'distribution.contractDocumentsAndConsents',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('kontaktni-udaje');
  } catch (e) {
    validating.value = false;
    contractDocumentsRequired.value = false;

    scrollToError();

    console.warn(`There was a validation contact information error: ${e}`);
  }
}
</script>
