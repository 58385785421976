<template>
  <div dusk="legal-requirements">
    <DistributionStepper current-step="legalRequirements" />

    <div class="container--sm mt-20">
      <h2>Pár státem požadovaných údajů…</h2>

      <h3>
        Údaje osoby, pro kterou je sjednáváno penzijní spoření
      </h3>

      <LegalRequirements
        namespace="distribution"
        store-path="legalRequirements"
        :is-participant="isParticipant"
        :is-from-termination="isFromTermination"
        :validate-fnc="validateField"
      />
    </div>

    <div class="container--sm mt-30">
      <hr>
    </div>

    <StepButtons
      namespace="distribution"
      previous-step="personalData"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import DistributionStepper from '@/js/components/Steppers/DistributionStepper.vue';
import { legalRequirementsFields } from '@/js/data/validationFields/commonFields';

import gtm from '@/js/services/gtm';

import { scrollToError } from '@/js/utils';
import { useDistributionStore } from '@/js/stores';
import StepButtons from '@/js/views/common/StepButtons.vue';
import LegalRequirements from '@/js/views/common/LegalRequirements.vue';

const route = useRoute();
const router = useRouter();

const store = useDistributionStore();
const {
  isParticipant,
  isFromTermination,
  contractSettings,
  terminationUuid,
  transferUuid,
} = storeToRefs(store);
const { validateFields, validateField } = store;

const validating = ref(false);

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: legalRequirementsFields,
    });

    validating.value = false;

    await router.push({
      name: 'distribution.contributionAndStrategy',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zakonne-pozadavky');
  } catch (e) {
    validating.value = false;
    scrollToError();

    console.warn(`There was a validation Error: ${e}`);
  }
}

onMounted(() => {
  if (route.query.transferUuid) {
    transferUuid.value.value = route.query.transferUuid as string;
  }

  if (route.query.currentContractNumber) {
    contractSettings.value.currentContract.contractNumber.value = route.query.currentContractNumber as string;
  }

  if (route.query.terminationUuid) {
    terminationUuid.value.value = route.query.terminationUuid as string;
  }
});
</script>
