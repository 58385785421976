<template>
  <div class="body">
    <TheHeader />
    <main />
    <TheFooter />
  </div>
</template>

<script lang="ts">
import TheHeader from '../../TheHeader.vue';
import TheFooter from '../../TheFooter.vue';

import { contracts, signatures } from '../../../api';

export default {
  components: {
    TheHeader,
    TheFooter,
  },

  async beforeRouteEnter (to, _from, next) {
    // @ts-expect-error: TODO: fix this
    const bankIdFailed = ((new URL(document.location)).searchParams).has('bankIdSign');

    try {
      const response = await contracts.create();

      const signature = [
        {
          signer: 'client',
          signMethod: 'offline',
        },
      ];

      await signatures.create(response.contract.uuid, JSON.stringify(signature));

      next({
        name: 'onlineAgreement.contactInformation',
        params: { contractUuid: response.contract.uuid },
        query: bankIdFailed ? { bankIdSign: 'failed' } : to.query,
      });
    } catch (e: any) {
      const status = e?.response?.status;
      const conflictingUuid = e?.response?.headers['x-conflicts-with'];

      if (status === 409 && conflictingUuid) {
        console.warn('Redirecting to ongoing contract', conflictingUuid);

        next({
          name: 'onlineAgreement.contactInformation',
          params: { contractUuid: conflictingUuid },
          query: bankIdFailed ? { bankIdSign: 'failed' } : to.query,
        });
      } else {
        next({
          name: 'error',
        });
      }
    }
  },
};
</script>
