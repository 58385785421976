<template>
  <footer class="container">
    <div :class="props.displayLink ? 'footer-line' : ''">
      <DistributionChangeConfirmModal
        v-if="props.displayLink"
        storage-key="distribution"
        route-name="distribution.home"
      />

      &copy;&nbsp;2016 - {{ format(new Date(), 'yyyy') }} ČSOB Penzijní společnost
    </div>
  </footer>
</template>

<script setup lang="ts">
import { format } from 'date-fns';

import DistributionChangeConfirmModal from '@/js/components/Modals/DistributionChangeConfirmModal.vue';

interface Props {
  displayLink?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  displayLink: true,
});
</script>

<style lang="scss">
@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins.scss';

footer {
  border-top: 1px solid getColor(light-grey);
  text-align: center;
  padding: 30px;

  a {
    color: #092f68;

    @include media(max, $xs) {
      padding-bottom: 10px;
    }
  }
}

.footer-line {
  display: flex;
  justify-content: space-between;
  padding-right: 488px;

  @include media(max, $lg) {
    padding-right: 340px;
  }

  @include media(max, $md) {
    padding-right: 0;
  }

  @include media(max, $xs) {
    flex-direction: column;
    gap: 8px;
    padding-right: 0;
  }
}
</style>
