<template>
  <div class="body">
    <TheHeader>
      <template #heading>
        <h1><strong>Generování čísla smlouvy</strong></h1>
      </template>
    </TheHeader>

    <div class="benefits bg-blue pt-30 pb-10">
      <div class="container--sm" />
    </div>

    <main>
      <router-view />
    </main>

    <TheFooter />
  </div>
</template>

<script lang="ts">
import TheHeader from '../TheHeader.vue';
import TheFooter from '../TheFooter.vue';

export default {
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>
