<template>
  <div
    class="tooltip"
    :class="`tooltip--${position}`"
  >
    <label>
      <span class="tooltip__text">
        <slot>
          {{ text }}
        </slot>
      </span>

      <img
        :src="getIconUrl(isIcon)"
        alt="Tooltip"
      >
    </label>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { getIconUrl } from '@/js/utils';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },

  iconPath: {
    type: String,
    default: '',
  },

  position: {
    type: String,
    default: 'top',
    validator (val: string) {
      return ['top', 'bottom', 'left', 'right'].includes(String(val));
    },
  },
});

const isIcon = computed(() => {
  return props.iconPath === '' ? 'icon-tooltip' : props.iconPath;
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

.tooltip {
  margin-left: 10px;
  flex-shrink: 0;

  label {
    margin-right: 0;
    position: relative;
    display: block;

    &:hover, &:focus {
      cursor: pointer;

      &::before {
        visibility: visible;
        opacity: 1;
      }

      .tooltip__text {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }

    &::before {
      content: '';
      position: absolute;
      visibility: hidden;
      opacity: 0;
      z-index: 1000001;
      pointer-events: none;
      transition: 250ms ease-in-out;
      border: 5px solid transparent;
    }
  }

  &__text {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    display: block;
    width: auto;
    min-width: 280px;
    background-color: getColor(green);
    border-radius: 2px;
    border: 20px solid getColor(green);
    color: getColor(white);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);
    padding: 10px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
    text-align: center;
    transition: 250ms ease-in-out;
    position: absolute;
    z-index: 1000;

    & a {
      color: inherit;
    }
  }

  @media screen and (max-width: 768px) {
    label::before,
    .tooltip__text {
      top: 50%;
      transform: translateY(-50%);
    }

    .tooltip__text {
      right: 150%;
    }

    &:hover {
      .tooltip__text {
        right: 130%;
      }

      label::before {
        left: -30%;
      }
    }

    label {
      &::before {
        border-left-color: getColor(green);
        left: -50%;
      }
    }
  }

  @media screen and (min-width: 767px) {
    &--top {
      .tooltip__text {
        bottom: 150%;
      }

      &:hover {
        .tooltip__text {
          bottom: 130%;
        }

        label::before {
          top: -30%;
        }
      }

      label {
        &::before {
          border-top-color: getColor(green);
          bottom: 0;
          top: -50%;
        }
      }
    }

    &--bottom {
      .tooltip__text {
        top: 150%;
      }

      &:hover {
        .tooltip__text {
          top: 130%;
        }

        label::before {
          bottom: -30%;
        }
      }

      label {
        &::before {
          border-bottom-color: getColor(green);
          top: 0;
          bottom: -50%;
        }
      }
    }

    &--top,
    &--bottom {
      .tooltip__text,
      label::before {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &--left {
      label::before,
      .tooltip__text {
        top: 50%;
        transform: translateY(-50%);
      }

      .tooltip__text {
        right: 150%;
      }

      &:hover {
        .tooltip__text {
          right: 130%;
        }

        label::before {
          left: -30%;
        }
      }

      label {
        &::before {
          border-left-color: getColor(green);
          left: -50%;
        }
      }
    }

    &--right {
      label::before,
      .tooltip__text {
        top: 30%;
        transform: translateY(-50%);
      }

      .tooltip__text {
        left: 150%;
      }

      &:hover {
        .tooltip__text {
          left: 130%;
        }

        label::before {
          right: -30%;
        }
      }

      label {
        &::before {
          border-left-color: getColor(green);
          right: calc(-100% + 10px);
          transform: scaleX(-1);
        }
      }
    }
  }
}
</style>
