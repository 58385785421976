import { differenceInMonths, differenceInYears, getYear } from 'date-fns';
import { get, isArray, isBoolean, isNull, set } from 'lodash';
import type { StateTree } from 'pinia';
import axios from 'axios';
import { citizenshipCountries } from './data/citizenshipOptions';
import type { Address, File } from '@/js/stores/types.ts';
import { setStoreFields } from '@/js/stores/utils.ts';
import { contracts } from '@/js/api';
import contractFiles from '@/js/api/contractFiles';
import env from '@/js/env.ts';

export function clamp (min: number | null, max: number | null, val: number): number {
  if (min !== null && val < min) {
    return min;
  }

  if (max !== null && val > max) {
    return max;
  }

  return val;
}

export function isObject (item: unknown) {
  return item !== null && typeof item === 'object';
}

export interface TraverseCallbackParams {
  nodeValue: unknown
  nodeKey: string | null
  path: string
  depth: number
  parent: Record<string, unknown> | null
  parentKey: string | null
  parentPath: string | null
}

export function traverseObject (obj: Record<string, unknown>, callback: (params: TraverseCallbackParams) => void) {
  const traverse = ({
    nodeValue,
    nodeKey,
    path,
    depth,
    parent,
    parentKey,
    parentPath,
  }: TraverseCallbackParams) => {
    callback({
      nodeValue,
      nodeKey,
      path,
      depth,
      parent,
      parentKey,
      parentPath,
    });

    if (isObject(nodeValue)) {
      Object.entries(nodeValue as Record<string, unknown>).forEach(([key, value]) => {
        traverse({
          nodeValue: value,
          nodeKey: key,
          path: `${path}.${key}`.replace(/^\.+/, ''),
          depth: depth + 1,
          parent: nodeValue as Record<string, unknown>,
          parentKey: nodeKey,
          parentPath: path,
        });
      });
    }
  };

  traverse({
    nodeKey: null,
    nodeValue: obj,
    path: '',
    depth: 0,
    parent: null,
    parentKey: null,
    parentPath: null,
  });
}

// @see https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
export function doubleRaf () {
  return new Promise((resolve) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve);
    });
  });
}

export function scrollToError (
  selectors = '.control--error, .contribution--error',
  offset = 30,
  timeout = 300,
) {
  const [el] = document.querySelectorAll(selectors);

  if (el) {
    setTimeout(() => {
      window.scrollTo(
        {
          behavior: 'smooth',
          top: (el as HTMLElement).offsetTop - offset,
        },
      );
    }, timeout);
  }
}

export function clearBankIdData (state: StateTree) {
  traverseObject(state, ({ nodeKey, parentPath }) => {
    if (nodeKey === '__storeField') {
      const bankIdField = get(state, `${parentPath}.bankIdReceivable`);

      if (bankIdField === true) {
        set(state, `${parentPath}.value`, null);
        set(state, `${parentPath}.readOnly`, false);
      }
    }
  });
}

export function isBankIdDataFilled (state: StateTree): boolean {
  traverseObject(state, ({ nodeKey, parentPath }) => {
    if (nodeKey === '__storeField') {
      const bankIdField = get(state, `${parentPath}.bankIdReceivable`);
      const val = get(state, `${parentPath}.value`);

      return parentPath === 'personalData.personalIdNumber' && val !== null && bankIdField === true;
    }
  });

  return false;
}

// @ts-expect-error: TODO: fix this
export function personalIdNumber (personalNumber) {
  const regex = /^\s*(\d\d)(\d\d)(\d\d)\/?(\d\d\d)(\d?)\s*$/g;
  const match = String(personalNumber).match(regex);

  if (match === null) {
    return null;
  }

  let year = personalNumber.substring(0, 2);
  let month = personalNumber.substring(2, 4);
  let day = personalNumber.substring(4, 6);

  const personalNumberEnding = personalNumber.substring(6).replace('/', '');
  const extension = personalNumberEnding.substring(0, 3);
  const lastChar = personalNumberEnding.substring(3);

  if (lastChar === '') {
    year = Number.parseInt(year, 10);
    year += year < 54 ? 1900 : 1800;
  } else {
    let mod = Number.parseInt([year, month, day, extension].join(''), 10) % 11;

    if (mod === 10) {
      mod = 0;
    }

    if (mod !== Number.parseInt(lastChar, 10)) {
      throw new Error('Modulo 11 verification failed');
    }

    year = Number.parseInt(year, 10);
    year += year < 54 ? 2000 : 1900;
  }

  let gender = '';
  const female = 'female';
  const male = 'male';

  month = Number.parseInt(month, 10);
  day = Number.parseInt(day, 10);

  if (month > 70 && year > 2003) {
    gender = female;
    month -= 70;
  } else if (month > 50) {
    gender = female;
    month -= 50;
  } else if (month > 20 && year > 2003) {
    gender = female;
    month -= 20;
  } else {
    gender = male;
  }

  const monthIndexCorrection = 1;

  // in ms
  const birthDay = new Date(year, month - monthIndexCorrection, day);
  const today = new Date();

  // in years
  const ageInYears = differenceInYears(today, birthDay);
  const isChild = ageInYears < 18;
  const bornYear = year;

  return {
    age: ageInYears,
    gender,
    isChild,
    birthDay,
    bornYear,
  };
}

export function clientBirthDay (birthDay: string | Date) {
  return new Date(birthDay);
}

export function removeCharsFromString (value: string, chars: string[]) {
  chars = isArray(chars) ? chars : [chars];

  chars.forEach((char) => {
    if (value.includes(char)) {
      value = value.replace(char, '').trim();
    }
  });

  return value;
}

export function clientBornYear (birthDay: string | Date) {
  return getYear(clientBirthDay(birthDay));
}

export function ageForRentEntry (birthDay: string | Date) {
  const currentRentAge = 65;

  const yearsAndRentAge: { [key: string]: number } = {
    1940: 60,
    1946: 61,
    1952: 62,
    1958: 63,
    1964: 64,
  };

  const rentYear = Object.keys(yearsAndRentAge).find((year) => clientBornYear(birthDay) <= Number.parseInt(year));

  if (rentYear) {
    return yearsAndRentAge[rentYear];
  }

  return currentRentAge;
}

export function getMonthsAddedToRentAge (cycleNumber: number) {
  //            bornYear     Age In years of Rent Entry   Add months    Months before rent Entry
  //                                                                     (5years + added months)
  //            1935         60                               0                   660
  //            1936         60                               2                   662
  //            1937         60                               4                   664
  //            1938         60                               6                   666
  //            1939         60                               8                   668
  //            1940         60                              10                   670
  //            1941         61                               0                   672
  //            1942         61                               2                   674
  //            1943         61                               4                   676
  //             ...        ...                             ...                   ...
  //            1964         64                              10                   718
  // Months which shall be added to age are periodically repeated
  // every 6 years => rentYearsCycle = 6
  // and Modulo 6 is used to set correct number of months

  const yearsInCycle = 6;

  return (cycleNumber % yearsInCycle) * 2;
}

export function canEnterRent (birthDay: string | Date | undefined) {
  if (!birthDay) {
    return;
  }

  const today = new Date();
  const clientAgeInMonths = differenceInMonths(today, clientBirthDay(birthDay));

  // acc. to law, the age of rent enter changes in PERIOD from 1935 to 1964 inc.
  const lastYear = 1964;
  const startYear = 1935;

  const monthsInYear = 12;

  // conservative fund is offered to clients how are 5 or less years before rent entry
  const yearsBeforeRent = 5;

  let clientAgeRentEntry;
  let monthsAddedToAge;

  if (clientBornYear(birthDay) < startYear || clientBornYear(birthDay) > lastYear) {
    clientAgeRentEntry = ageForRentEntry(birthDay);
    monthsAddedToAge = 0;
  } else {
    for (let i = startYear; i <= lastYear; i += 1) {
      monthsAddedToAge = getMonthsAddedToRentAge(i - startYear);

      if (clientBornYear(birthDay) === i) {
        clientAgeRentEntry = ageForRentEntry(birthDay);

        break;
      }
    }
  }

  const monthsBeforeRetirement = (clientAgeRentEntry as number) * monthsInYear + monthsAddedToAge!
    - (yearsBeforeRent * monthsInYear);

  return clientAgeInMonths >= monthsBeforeRetirement;
}

export function stateContribution (monthContributionAmount: number, isForNovel: boolean, isGrantedPension: boolean) {
  const minAmount = isForNovel ? 500 : 300;
  const midAmount = isForNovel ? 1700 : 1000;

  if (monthContributionAmount < minAmount) {
    return {
      class: 'text-danger',
      value: 0,
    };
  }

  if (monthContributionAmount < midAmount) {
    return {
      class: isGrantedPension ? 'text-danger' : 'text-warning',
      value: isGrantedPension ? 0 : (isForNovel ? Math.floor(monthContributionAmount * 0.2) : Math.floor(90 + ((monthContributionAmount - 300) * 0.2))),
    };
  }

  if (monthContributionAmount >= midAmount) {
    return {
      class: isGrantedPension ? 'text-danger' : 'text-success',
      value: isGrantedPension ? 0 : (isForNovel ? 340 : 230),
    };
  }

  return {
    class: 'text-danger',
    value: 0,
  };
}

export function annualTaxSaving (monthContributionAmount: number, isForNovel: boolean, isGrantedPension: boolean) {
  const midAmount = isForNovel ? 1700 : 1000;
  const maxAmount = isGrantedPension ? 4000 : (isForNovel ? 5700 : 5000);
  const minAmount = 100;

  if (monthContributionAmount < minAmount) {
    return {
      class: 'text-danger',
      value: 0,
    };
  }

  if (monthContributionAmount <= midAmount) {
    return {
      class: isGrantedPension ? 'text-warning' : 'text-danger',
      value: isGrantedPension ? Math.round(monthContributionAmount * 12 * 0.15) : 0,
    };
  }

  if (monthContributionAmount < maxAmount) {
    return {
      class: 'text-warning',
      value: isGrantedPension ? Math.round(monthContributionAmount * 12 * 0.15) : Math.round((monthContributionAmount - midAmount) * 12 * 0.15),
    };
  }

  if (monthContributionAmount >= maxAmount) {
    return {
      class: 'text-success',
      value: 7200,
    };
  }

  return {
    class: 'text-danger',
    value: 0,
  };
}

export function formatThousands (amount: number): string {
  const noBreakWhiteSpace = '\xA0';

  return amount.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, noBreakWhiteSpace);
}

export function getHomepageUrl () {
  const localEnvs = ['127.0.0.1:8000', 'localhost:8000'];

  if (localEnvs.includes(window.location.host)) {
    return 'http://localhost:8003/';
  }

  const { protocol, host } = window.location;

  return `${protocol}//${host.replace('sjednani.', '')}/`;
}

export function getStringDistance (primaryString: string, secondaryString: string) {
  const s1 = primaryString.toLowerCase();
  const s2 = secondaryString.toLowerCase();

  const costs = [];

  for (let i = 0; i <= s1.length; i += 1) {
    let lastValue = i;

    for (let j = 0; j <= s2.length; j += 1) {
      if (i === 0) {
        costs[j] = j;
      } else if (j > 0) {
        let newValue = costs[j - 1];

        if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
          newValue = Math.min(
            Math.min(newValue, lastValue),
            costs[j],
          ) + 1;
        }

        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }

    if (i > 0) {
      costs[s2.length] = lastValue;
    }
  }

  return costs[s2.length];
}

export function removeSpaces (word: string) {
  return word.split(' ').join('');
}

function getStorage (storageType: string) {
  switch (storageType) {
    case 'localStorage':
      return window.localStorage;

    case 'sessionStorage':
      return window.sessionStorage;

    default:
      throw new Error(`Invalid storage type: ${storageType}`);
  }
}

export function removePersistedData (key: string, storageType = 'localStorage') {
  return getStorage(storageType).removeItem(key);
}

export function getPersistedData (key: string, storageType = 'localStorage') {
  return getStorage(storageType).getItem(key);
}

export function persistData (data: unknown, key: string, storageType = 'localStorage') {
  getStorage(storageType).setItem(key, JSON.stringify(data));
}

export function syncPersistedDistributor (
  isTiedAgent: boolean = false,
  distributionName: string,
  storageType: string = 'localStorage',
  storageKey: string = 'distribution',
) {
  if (!distributionName) {
    return;
  }

  const storage = getStorage(storageType);

  if (!storage) {
    console.error('Storage not found');

    return;
  }

  const storageData = storage.getItem(storageKey);
  const distributorData = { isTiedAgent, distribution: distributionName };

  if (storageData === null) {
    persistData(distributorData, storageKey);

    return;
  }

  try {
    const parsedData = JSON.parse(storageData);

    if (parsedData.distribution !== distributionName) {
      persistData(
        {
          isTiedAgent: isBoolean(parsedData.isTiedAgent) ? parsedData.isTiedAgent : isTiedAgent,
          distribution: distributionName,
        },
        storageKey,
      );
    }
  } catch (e) {
    console.warn(e);

    throw new Error('Failed to parse storage data. Please ensure the data is in valid JSON format.');
  }
}

export function persistContractMetadata (data: Record<string, unknown>, key = 'contracts', storageType = 'localStorage') {
  const persistedData = getPersistedData(key);
  let metadata = {} as Record<string, unknown>;

  if (persistedData !== null) {
    metadata = JSON.parse(persistedData);
  }

  if (!data.contractUuid || !data.contractUuid) {
    throw new Error('accessTokenValue and contractUuid are required');
  }

  const {
    accessTokenValue,
    accessTokenValidTo,
    contractUuid,
    contractType,
    draftName = null,
    createdAt = null,
    updatedAt = null,
    savedAtRoute = null,
    isDraft = false,
  } = data as Record<string, unknown>;

  metadata[contractUuid as keyof typeof data] = {
    contractType,
    accessToken: {
      value: accessTokenValue,
      expiresAt: accessTokenValidTo,
    },
    draftName,
    createdAt,
    updatedAt,
    savedAtRoute,
    isDraft,
  };

  persistData(metadata, key, storageType);
}

export function getContractMetadata (uuid: string, key = 'contracts') {
  let contracts = getPersistedData(key);

  if (contracts === null) {
    return undefined;
  }

  contracts = JSON.parse(contracts);
  return contracts ? contracts[uuid as keyof typeof contracts] : undefined;
}

export function removeContractMetadata (uuid: string, key = 'contracts') {
  let contracts = getPersistedData(key);

  if (contracts === null) {
    console.warn(`LocalStorage contract with key: ${key} is not exists`);
    return false;
  }

  contracts = JSON.parse(contracts);

  // @ts-expect-error: contracts is not null
  if (uuid in contracts) {
    // @ts-expect-error: contracts is not null
    delete contracts[uuid];

    persistData(contracts, key, 'localStorage');
  }

  return true;
}

export function removeContractsMetadata (uuids: string[], key = 'contracts') {
  uuids = isArray(uuids) ? uuids : [uuids];

  uuids.forEach((uuid) => {
    removeContractMetadata(uuid, key);
  });
}

export function removeDiacritics (word: string) {
  return word.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
}

export function getStringSimilarity (s1: string, s2: string) {
  let longer = s1;
  let shorter = s2;

  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }

  const longerLength = longer.length;

  if (longerLength === 0) {
    return 1.0;
  }

  return (longerLength - getStringDistance(longer, shorter)) / Number.parseFloat(longerLength.toString());
}

export function convertStringToBoolean (value: string): boolean | never {
  if (value.toLowerCase() === 'true') {
    return true;
  } else if (value.toLowerCase() === 'false') {
    return false;
  } else {
    throw new Error('Input string must be \'true\' or \'false\'');
  }
}

export function getCitizenship (citizenship: string) {
  if (citizenship === 'cz') {
    return citizenship;
  }

  const trimmedCitizenship = removeSpaces(citizenship).toLowerCase();
  const similarResult = {
    value: 0,
    name: '',
  };

  citizenshipCountries.forEach((country) => {
    const refactoredCountry = removeDiacritics(removeSpaces(country.label));

    const stringSimilarity = getStringSimilarity(refactoredCountry, trimmedCitizenship) * 100;

    if (stringSimilarity > similarResult.value) {
      similarResult.name = country.value;
      similarResult.value = stringSimilarity;
    }
  });

  return !similarResult.name.length ? 'cz' : similarResult.name;
}

export default {};

export function getIconUrl (name: string, ext = 'svg') {
  const url = new URL(`../images/icons/${name}.${ext}`, import.meta.url).href;

  if (typeof url === 'undefined' || !url) {
    throw new Error(`Icon ${name}.${ext} not found`);
  }

  return url;
}

export function getImageUrl (name: string, ext = 'svg') {
  const url = new URL(`../images/${name}.${ext}`, import.meta.url).href;

  if (typeof url === 'undefined' || !url) {
    throw new Error(`Image ${name}.${ext} not found`);
  }

  return url;
}

export function isPrintAllowed (s1: string, s2: string) {
  // these s1 and s2 values are only for tests. Real list will be sent.
  // this method is not used anywhere till real values of s1 and s2 are submitted
  const DISALLOWED_S1 = '418933';
  const DISALLOWED_S2 = '1978';
  const SPECIAL_S1 = '411111';

  // printing contract documents is not allowed where also digital signature is possible and for:
  // S1 = DISALLOWED_S1 (no matter what value of s2 is)
  // and for S1 = SPECIAL_S1 but only for those whose s2 equals DISALLOWED_S2
  return !(s1 === DISALLOWED_S1 || (s1 === SPECIAL_S1 && s2 === DISALLOWED_S2));
}

export function updateAddress (activeStore: StateTree, prefix: string, name: string, params: Address) {
  setStoreFields(activeStore.value, [
    {
      path: `${prefix}.${name}.city.value`,
      value: params.city,
    },
    {
      path: `${prefix}.${name}.street.value`,
      value: params.street,
    },
    {
      path: `${prefix}.${name}.streetNumber.value`,
      value: params.streetNumber,
    },
    {
      path: `${prefix}.${name}.zip.value`,
      value: params.zip,
    },
    {
      path: `${prefix}.${name}.country.value`,
      value: params.countryCode?.toLowerCase(),
    },
  ]);
}

export async function waitForSignedPdf (uuid: string, contractType: string, maxFileAttemptTries: number) {
  return new Promise((resolve, reject) => {
    let attempts = 0;
    let isUploaded = false;

    const interval = setInterval(async () => {
      try {
        const { data } = await contracts.show(uuid as string, contractType);

        if (isNull(data.rest_api_contract_id)) {
          reject(new Error('Failed to fetch rest api contract id'));
        }

        const contractId = data.rest_api_contract_id;
        const files: File[] = await contractFiles.index(contractId);

        const signedFile = files.find((file) => file.file_type === 'signed_contract');
        attempts += 1;

        if (signedFile && !isUploaded && attempts <= maxFileAttemptTries) {
          try {
            const response = await axios.get(`${env.REST_API_URI}/v1/files/${signedFile.id}?touch=true`);

            if (response.data === 1) {
              isUploaded = true;
              clearInterval(interval);
              resolve(true);
            }
          } catch (error) {
            console.warn(error);

            clearInterval(interval);
            reject(new Error('Failed to fetch signed file.'));
          }
        } else if (attempts >= maxFileAttemptTries) {
          clearInterval(interval);
          reject(new Error('Exceeded maximum attempts to check for signed file upload.'));
        }
      } catch (error) {
        clearInterval(interval);
        reject(error);
      }
    }, 5 * 1000);
  });
}
