<script setup lang="ts" generic="T extends ContactInformationReturn">
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import type { ContactInformationReturn } from '@/js/stores/createStoreFields';
import RecapitulationTableRow from '@/js/components/RecapitulationTableRow.vue';

interface Props {
  storePath: T
  namespace: string
  title?: string
  displayTableHead?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  displayTableHead: true,
});

const tableRows = [
  {
    path: props.storePath.firstName,
    tableKey: 'Jméno',
  },

  {
    path: props.storePath.lastName,
    tableKey: 'Příjmení',
  },

  {
    path: props.storePath.email,
    tableKey: 'E-mail',
  },
];
</script>

<template>
  <div>
    <table
      class="recapitulation-table mb-16"
    >
      <thead>
        <tr v-if="props.displayTableHead">
          <th colspan="2">
            <h3>{{ props.title }}</h3>
          </th>
          <th>
            <router-link
              :to="{ name: `${props.namespace}.contactInformation` }"
              class="edit-section"
            >
              <LitIconSvg icon-name="edit_green" />

              Upravit
            </router-link>
          </th>
        </tr>
      </thead>

      <tbody>
        <RecapitulationTableRow
          v-for="(row, index) in tableRows"
          :key="index"
          :path="row.path"
          :table-key="row.tableKey"
        />

        <RecapitulationTableRow
          v-if="props.storePath.phonePrefix"
          :path="props.storePath.phonePrefix"
          :additional-validation-paths="[props.storePath.phoneNumber]"
          table-key="Mobil"
        >
          <template #tableValue>
            +{{ props.storePath.phonePrefix.value }} {{ props.storePath.phoneNumber.value }}
          </template>
        </RecapitulationTableRow>
      </tbody>
    </table>
  </div>
</template>
