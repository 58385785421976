import { defineStore } from 'pinia';
import { reactive, toRefs } from 'vue';

import { useStoreField } from '@/js/composables/useStoreField';

export const useGeneralStore = defineStore('general', () => {
  const state = reactive({
    isTiedAgent: useStoreField<boolean>(false),
    distribution: useStoreField<string>(''),
  });

  return {
    ...toRefs(state),
  };
});
