<template>
  <div dusk="distributor-data">
    <div>
      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.s1`"
        :validate-on-input="true"
        :debounce="0"
        @validate="props.validateFnc"
      >
        <LitInput
          name="s1"
          dusk="s1"
          class="mb-20"
          placeholder="1234"
          label="S1 (Sjednatelské číslo distribuce)"
          :errors="errors"
          :model-value="value"
          @blur="input($event.target.value)"
        >
          <template #link>
            <router-link
              v-if="errors.length !== 0"
              :to="{ name: 'distribution.home' }"
              class="error"
              @click="removePersistedData('distribution')"
            >
              Změnit distribuci
            </router-link>
          </template>
        </LitInput>
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.s2`"
        :validate-on-input="true"
        :debounce="0"
        @validate="props.validateFnc"
      >
        <LitInput
          name="s2"
          dusk="s2"
          class="mb-20"
          placeholder="1234"
          label="S2 (Sjednatelské číslo prodejce)"
          :errors="errors"
          :model-value="value"
          @blur="input($event.target.value)"
        />
      </ServerValidatedFormField>

      <div v-if="displayOptionalFields">
        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.s3`"
          :debounce="0"
          @validate="props.validateFnc"
        >
          <LitInput
            name="s3"
            dusk="s3"
            class="mb-20"
            placeholder="1234"
            label="S3 (Sjednatelské číslo tipaře)"
            :errors="errors"
            :model-value="value"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.promoCode`"
          :debounce="0"
          @validate="props.validateFnc"
        >
          <LitInput
            class="mb-20"
            label="Kód akce"
            name="marketingCode"
            dusk="input-marketing-code"
            placeholder="Např. AB"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>
      </div>

      <div v-if="displayAddressField">
        <h3 v-if="showMeetingAddressTitle">
          Adresa pošty
        </h3>

        <AddressEntry
          label="Adresa pošty"
          name="meeting-address"
          dusk="meeting-address"
          tooltip="Postupným psaním názvu ulice, čísla popisného
          a PSČ si můžete ze seznamu vybrat potřebnou adresu."
          :country-selection-allowed="false"
          :namespace="namespace"
          :store-path="`${storePath}.meetingAddress`"
          :searchable-country="true"
          :validate-fnc="props.validateFnc"
          @place-detail-loaded="updateMeetingAddress"
        />
      </div>
    </div>

    <div>
      <h3>{{ title }}</h3>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.distributor.firstName`"
        :validate-on-input="true"
        :debounce="0"
        @validate="props.validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Jméno obchodního zástupce"
          name="csobFirstName"
          dusk="csob-first-name"
          placeholder="Např. Karel"
          :model-value="value"
          :errors="errors"
          @blur="input($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.distributor.lastName`"
        :validate-on-input="true"
        :debounce="0"
        @validate="props.validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Příjmení obchodního zástupce"
          name="csobLastName"
          dusk="csob-last-name"
          placeholder="Např. Novotný"
          :model-value="value"
          :errors="errors"
          @blur="input($event.target.value)"
        />
      </ServerValidatedFormField>

      <LitAlert v-if="showAlert">
        {{ alertText }}
      </LitAlert>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.distributor.email`"
        :debounce="0"
        @validate="props.validateFnc"
      >
        <EmailInput
          name="csobEmail"
          dusk="csob-email"
          label="E-mail obchodního zástupce"
          :errors="errors"
          :model-value="value"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getActivePinia } from 'pinia';
import { get } from 'lodash';

import AddressEntry from '@/js/components/AddressEntry.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import EmailInput from '@/js/components/EmailInput.vue';
import type { Address } from '@/js/stores/types.ts';
import type { ValidateStoreParamsFnc } from '@/js/stores/utils.ts';
import { setStoreFields } from '@/js/stores/utils.ts';
import { removePersistedData } from '@/js/utils.ts';

interface Props {
  namespace: string
  storePath: string
  title?: string
  displayOptionalFields?: boolean
  displayAddressField?: boolean
  alertText?: string
  showAlert: boolean
  showMeetingAddressTitle: boolean
  validateFnc: ValidateStoreParamsFnc
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Smlouvu za ČSOB PS uzavírá',
  displayOptionalFields: true,
  alertText: 'Vyplňte svou e-mailovou adresu, zašleme na ni kopii kompletní'
  + ' dokumentace, kterou zasíláme na e-mail účastníka.',
  showMeetingAddressTitle: false,
});

const route = useRoute();

const activeStore = computed(() => {
  const store = getActivePinia();

  if (!store) {
    throw new Error('Pinia store is not active. Make sure you have initialized Pinia and it is active in the current context.');
  }

  const storeField = get(store.state.value, `${props.namespace}.${props.storePath}`);

  if (!storeField) {
    throw new Error(`Store field ${props.namespace}.${props.storePath} does not exist. Check the namespace and storePath props.`);
  }

  return storeField;
});

const s1 = computed({
  get () {
    return get(activeStore.value, 's1.value');
  },
  set (value) {
    activeStore.value.s1.value = value;
  },
});

const s2 = computed({
  get () {
    return get(activeStore.value, 's2.value');
  },
  set (value) {
    activeStore.value.s2.value = value;
  },
});

const s3 = computed({
  get () {
    return get(activeStore.value, 's3.value');
  },
  set (value) {
    activeStore.value.s3.value = value;
  },
});

const promoCode = computed({
  get () {
    return get(activeStore.value, 'promoCode.value');
  },
  set (value) {
    activeStore.value.promoCode.value = value;
  },
});

function updateAddress (prefix: string, name: string, params: Address) {
  setStoreFields(activeStore.value, [
    {
      path: `${prefix}.${name}.city.value`,
      value: params.city,
    },
    {
      path: `${prefix}.${name}.street.value`,
      value: params.street,
    },
    {
      path: `${prefix}.${name}.streetNumber.value`,
      value: params.streetNumber,
    },
    {
      path: `${prefix}.${name}.zip.value`,
      value: params.zip,
    },
    {
      path: `${prefix}.${name}.country.value`,
      value: params.countryCode?.toLowerCase(),
    },
  ]);
}

function updateMeetingAddress (addressDetail: Address) {
  updateAddress('beforeStart', 'meetingAddress', addressDetail);
}

function getAffiliatedNumbers () {
  if (route.query.s1) {
    s1.value = route.query.s1;
  }

  if (route.query.s2) {
    s2.value = route.query.s2;
  }

  if (route.query.s3) {
    s3.value = route.query.s3;
  }
}

function getPromoCodeFromUrl () {
  if (route.query.kod) {
    promoCode.value = route.query.kod;
  }
}

onMounted(() => {
  getAffiliatedNumbers();
  getPromoCodeFromUrl();
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

.error {
  color: getColor(danger, 1);
}
</style>
