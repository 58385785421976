<template>
  <div class="body">
    <TheHeader>
      <template #heading>
        <h1><strong>Převod smlouvy</strong></h1>
      </template>

      <template #header_contact_phone>
        <a
          href="tel:+420224116655"
        >
          <img
            :src="getIconUrl('phone')"
            alt="Icon tel."
          ><span>224 116 655</span>
        </a>
      </template>
    </TheHeader>

    <InactivityModal
      contract-type="transfer"
      :contract-uuid="$route.params.contractUuid as string"
      :forbidden-route-names="modalForbiddenRoutes"
    />

    <main v-if="rehydrated.value">
      <router-view />
    </main>

    <TheFooter />
  </div>
</template>

<script lang="ts">
import { shallowRef } from 'vue';

import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { storeToRefs } from 'pinia';
import TheHeader from '../../TheHeader.vue';
import TheFooter from '../../TheFooter.vue';

import InactivityModal from '@/js/components/Modals/InactivityModal.vue';

import { accessTokens, contracts } from '@/js/api';
import { getIconUrl, getPersistedData, persistContractMetadata } from '@/js/utils';
import { useDistributionStore, useGeneralStore, useTransferStore } from '@/js/stores';
import { restoreState } from '@/js/stores/utils';

type StatusResponse = 404 | 500;

export default {
  components: {
    TheHeader,
    TheFooter,
    InactivityModal,
  },

  async beforeRouteUpdate (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { contractUuid } = to.params;

    // This method on every update checks whether the
    // contract exists/is available.

    // We do not want to do that after the last step
    // when the contract is submitted/signed and becomes unavailable.
    // Therefor we skip the validation for the thankYou page.
    if (to.name === 'transfer.transferConfirmation') {
      next();
      return;
    }

    try {
      await contracts.show(contractUuid as string, 'transfer');
      next();
    } catch (e: any) {
      const status = e?.response?.status as StatusResponse;

      const pages = {
        404: 'notFound',
        500: 'internalError',
      };

      const name = pages[status] || 'error';
      const type = (to.name as string).split('.')[0];

      next({
        name,
        query: {
          type,
        },
      });
    }
  },

  async beforeRouteEnter (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { contractUuid } = to.params;
    const { draftToken } = to.query;
    const { newContractUuid } = to.query;

    try {
      if (typeof draftToken !== 'undefined') {
        const { data } = await accessTokens.create(contractUuid, { name: 'draftToken', value: draftToken });

        persistContractMetadata({
          accessTokenValue: data.token,
          accessTokenValidTo: data.valid_to,
          contractUuid,
          contractType: 'transfer',
        });
      }

      const { data } = await contracts.show(contractUuid as string, 'transfer');
      const newContractFormData = newContractUuid !== undefined ? JSON.parse((await contracts.show(newContractUuid as string, 'distribution')).data.form_data) : null;

      if (data.form_data) {
        const formData = JSON.parse(data.form_data);

        next((vm: any) => {
          restoreState(vm.store.$state, formData);

          if (newContractUuid !== undefined) {
            const distributionStore = useDistributionStore();
            restoreState(distributionStore.$state, newContractFormData);
          }
        });
      } else {
        next((vm: any) => {
          vm.rehydrated.value = true;
        });
      }
    } catch (e: any) {
      const status = e?.response?.status as StatusResponse;

      const pages = {
        404: 'notFound',
        500: 'internalError',
      };

      const name = pages[status] || 'error';
      const type = (to.name as string).split('.')[0];

      next({
        name,
        query: {
          type,
        },
      });
    }
  },

  setup () {
    const store = useTransferStore();
    const generalStore = useGeneralStore();

    const { rehydrated } = storeToRefs(store);

    const modalForbiddenRoutes = shallowRef([
      'transfer.transferConfirmation',
    ]);

    function setDataFromLocalStorage () {
      const generalStoreData = getPersistedData('distribution');

      if (generalStoreData !== null) {
        const data = JSON.parse(generalStoreData);

        generalStore.isTiedAgent.value = data.isTiedAgent;
        generalStore.distribution.value = data.distribution;
      }
    }

    setDataFromLocalStorage();

    return {
      getIconUrl,
      modalForbiddenRoutes,
      rehydrated,
      store,
    };
  },
};
</script>
