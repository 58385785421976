<template>
  <div>
    <div dusk="signature-thank-you">
      <div class="benefits bg-blue pt-30 pb-10" />
      <div class="container--sm center pt-30">
        <LitIconSvg icon-name="icon-redirect_to_litsign" />

        <h2>
          Děkujeme, pokračujte prosím na jiném zařízení
        </h2>

        <div class="center">
          <p class="my-20">
            Na e-mail <strong>{{ beforeStart.distributor.email.value }}</strong> jsme odeslali
            odkaz na aplikaci, kde podepíšete žádost elektronicky.
          </p>

          <p class="mb-20">
            Podpis žádosti je možný i na mobilním zařízení.
          </p>

          <p v-if="draftTokenLocal.value !== null">
            Odkaz je platný do <strong>{{ contractDraftTokenValidity.label }}</strong>.
          </p>

          <p>Na podpis můžete přejít i skrze QR kód:</p>

          <div class="qr-code">
            <QrcodeVue
              :value="signingLink"
              :size="180"
              level="H"
            />
          </div>
        </div>

        <LitButton
          class="mt-20"
          variant="outline"
          :to="{ name: 'distribution.home' }"
        >
          <span>
            Zpět na hlavní rozcestník
          </span>
        </LitButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { format } from 'date-fns';

import QrcodeVue from 'qrcode.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitButton from '@/js/components/Base/LitButton.vue';
import { useTerminationStore } from '@/js/stores';
import { resetState } from '@/js/stores/utils';

const draftTokenLocal = ref<{ value: null | string, expiresAt: string | null }>({
  value: null,
  expiresAt: null,
});

const route = useRoute();
const store = useTerminationStore();
const { beforeStart, draftToken } = storeToRefs(store);

onBeforeRouteLeave((to, _from, next) => {
  resetState(store.$state, false);

  const disallowedPaths = [
    'termination.recapitulation',
  ];

  if (to.name && disallowedPaths.includes(to.name.toString())) {
    next({ name: 'distribution.home' });
  } else {
    next();
  }
});

const signingLink = computed(() => {
  const { contractUuid } = route.params;
  const protocolValues = ['http', 'https'];
  const currentLocation = window.location;

  const redirectUri = `${window.location.host}/vypoved/${contractUuid}/rekapitulace?draftToken=${draftTokenLocal.value.value}`;
  const protocolIncluded = protocolValues.some((value) => redirectUri.includes(value));

  return protocolIncluded ? redirectUri : `${currentLocation.protocol}//${redirectUri}`;
});

const contractDraftTokenValidity = computed(() => {
  const validUntil = new Date(draftTokenLocal.value.expiresAt as string);

  return {
    value: validUntil && format(validUntil, 'yyyy-MM-dd'),
    label: format(validUntil, 'dd. MM. yyyy'),
  };
});

onMounted(() => {
  draftTokenLocal.value = {
    value: draftToken.value.value.value,
    expiresAt: draftToken.value.value.expiresAt && new Date(draftToken.value.value.expiresAt).toString(),
  };
});
</script>

<style scoped>
  .mt-10 {
    margin-top: 10px;
  }
</style>
