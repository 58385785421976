<template>
  <div class="body">
    <TheHeader />

    <InactivityModal
      contract-type="online"
      :contract-uuid="$route.params.contractUuid as string"
      :forbidden-route-names="modalForbiddenRoutes"
    />

    <main v-if="rehydrated.value">
      <router-view />
    </main>
    <TheFooter
      :display-link="false"
    />
  </div>
</template>

<script lang="ts">
import { mapWritableState } from 'pinia';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import TheHeader from '../../TheHeader.vue';
import TheFooter from '../../TheFooter.vue';
import InactivityModal from '@/js/components/Modals/InactivityModal.vue';

import { accessTokens, contracts } from '@/js/api';
import { useOnlineAgreementStore } from '@/js/stores/online/agreement';
import { persistContractMetadata } from '@/js/utils';
import { restoreState } from '@/js/stores/utils';

type StatusResponse = 404 | 500;

export default {
  components: {
    TheHeader,
    TheFooter,
    InactivityModal,
  },

  async beforeRouteUpdate (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { contractUuid } = to.params;

    // This method on every update checks whether the
    // contract exists/is available.

    // We do not want to do that after the last step
    // when the contract is submitted/signed and becomes unavailable.
    // Therefor we skip the validation for the thankYou page.
    if (to.name === 'onlineAgreement.thankYouPage') {
      next();
      return;
    }

    try {
      await contracts.show(contractUuid as string);

      next();
    } catch (e: any) {
      const status = e?.response?.status as StatusResponse;

      const pages = {
        404: 'notFound',
        500: 'internalError',
      };

      const name = pages[status] || 'error';
      const type = (to.name as string).split('.')[0];

      next({
        name,
        query: {
          type,
        },
      });
    }
  },

  async beforeRouteEnter (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { contractUuid } = to.params;
    const { redirectToken } = to.query;
    const { draftToken } = to.query;

    try {
      if (typeof redirectToken !== 'undefined' || typeof draftToken !== 'undefined') {
        const tokenName = typeof redirectToken !== 'undefined' ? 'redirectToken' : 'draftToken';
        const tokenValue = typeof redirectToken !== 'undefined' ? redirectToken : draftToken;

        const { data } = await accessTokens.create(
          contractUuid,
          {
            name: tokenName,
            value: tokenValue,
          },
        );

        persistContractMetadata({
          accessTokenValue: data.token,
          accessTokenValidTo: data.valid_to,
          contractUuid,
          contractType: 'online',
        });
      }

      const { data } = await contracts.show(contractUuid as string);

      if (data.form_data) {
        const formData = JSON.parse(data.form_data);

        next((vm: any) => {
          vm.restoreState(formData);
        });
      } else {
        next((vm: any) => {
          vm.rehydrated.value = true;
        });
      }
    } catch (e: any) {
      const status = e?.response?.status as StatusResponse;

      const pages = {
        404: 'notFound',
        500: 'internalError',
      };

      const name = pages[status] || 'error';
      const type = (to.name as string).split('.')[0];

      next({
        name,
        query: {
          type,
        },
      });
    }
  },

  data: () => ({
    modalForbiddenRoutes: [
      'onlineAgreement.thankYouPage',
    ],
  }),

  computed: {
    ...mapWritableState(useOnlineAgreementStore, ['rehydrated']),
  },

  methods: {
    restoreState (formData: Record<string, unknown>) {
      const store = useOnlineAgreementStore();

      restoreState(store.$state, formData);
    },
  },
};
</script>
