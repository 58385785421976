<template>
  <div>
    <TerminationStepper current-step="additionInformation" />

    <div class="container--sm my-30">
      <h2>Doplňující údaje</h2>
      <div class="flex flex__md additional__box">
        <p
          v-if="isAdditionalPensionSaving || isSmartMoney"
          class="additional__text"
        >
          Typ dávky:
        </p>
        <p
          v-else
          class="additional__text"
        >
          Výplata {{ terminationTypeText }} místo
        </p>

        <p
          v-if="isAdditionalPensionSaving || isSmartMoney"
        >
          <span class="additional__span">{{ terminationTypeText }}</span>
        </p>

        <div v-if="!isAdditionalPensionSaving && !isSmartMoney">
          <div class="additional__switch">
            <ServerValidatedFormField
              v-slot="{ value, input, errors }"
              namespace="termination"
              field-path="additionalInformation.insuranceType.retirementPension"
              @validate="validateField"
            >
              <LitSwitch
                dusk="retirement-pension"
                label="Výsluhová penze"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
              />
            </ServerValidatedFormField>
          </div>

          <div class="additional__switch">
            <ServerValidatedFormField
              v-slot="{ value, input, errors }"
              namespace="termination"
              field-path="additionalInformation.insuranceType.medicalInsurance"
              @validate="validateField"
            >
              <LitSwitch
                dusk="medical-insurance"
                label="Invalidní penze"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
              />
            </ServerValidatedFormField>
          </div>

          <div class="additional__switch">
            <ServerValidatedFormField
              v-slot="{ value, input, errors }"
              namespace="termination"
              field-path="additionalInformation.insuranceType.pensionInsurance"
              @validate="validateField"
            >
              <LitSwitch
                dusk="pension-insurance"
                label="Starobní penze"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
              />
            </ServerValidatedFormField>
          </div>
        </div>
      </div>

      <div
        v-if="isPensionType"
        class="flex flex__md additional__box"
      >
        <div class="additional__text">
          <p>Doba vyplácení:</p>
        </div>
        <div>
          <ServerValidatedFormField
            v-slot="{ value, errors, input }"
            namespace="termination"
            field-path="terminationSettings.oldAgePension.selectedYears"
            :debounce="0"
            :validate-on-input="true"
            @validate="validateField"
          >
            <LitSelect
              class="mb-20"
              type="number"
              :options="pensionYearsOption"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
            />
          </ServerValidatedFormField>
        </div>
      </div>

      <div
        v-if="isPensionType"
        class="flex flex__md additional__box max-h-70"
      >
        <div class="additional__text">
          Typ výplaty penze
        </div>
        <div>
          <ServerValidatedFormField
            v-slot="{ value, errors, input }"
            namespace="termination"
            :debounce="0"
            :validate-on-input="true"
            field-path="terminationSettings.oldAgePension.paymentFrequency"
            @validate="validateField"
          >
            <LitRadio
              class="mb-20"
              type="number"
              :options="paymentFrequencyType"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
            />
          </ServerValidatedFormField>
        </div>
      </div>

      <div class="flex flex__md additional__box max-h-70">
        <p class="additional__text">
          Způsob výplaty
        </p>
        <div>
          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="termination"
            field-path="additionalInformation.paymentMethod"
            :validate-on-input="true"
            :debounce="0"
            @validate="validateField"
          >
            <LitRadio
              dusk="payment-method"
              class="mb-20"
              :options="paymentTypeOptions"
              :error="errors.length > 0 ? errors[0] : ''"
              :model-value="value"
              @update:model-value="input"
            >
              <template #after>
                <div
                  v-if="!isBankTransfer"
                  class="address--box"
                >
                  <p>
                    {{ fullAddress }}
                    <span>
                      <router-link :to="{ name: 'termination.personalData' }">změnit</router-link>
                    </span>
                  </p>
                </div>
              </template>
            </LitRadio>
          </ServerValidatedFormField>
        </div>
      </div>

      <hr v-if="isBankTransfer">

      <div
        v-if="isBankTransfer"
        class="custom-field--bank mb-20 mt-20"
        for="prefix"
      >
        <label class="custom-field--bank__label">
          Číslo bankovního účtu
        </label>

        <div class="bank-account flex">
          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="termination"
            field-path="additionalInformation.bankAccount.prefix"
            @validate="validateField"
          >
            <LitInput
              name="prefix"
              class="control--xs"
              placeholder="Předčíslí"
              dusk="prefix"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, errors, validate, input }"
            namespace="termination"
            field-path="additionalInformation.bankAccount.number"
            @validate="validateField"
          >
            <LitInput
              name="cc-number"
              class="control--md"
              placeholder="Číslo účtu"
              dusk="bank-number"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <span class="bank-account__slash">/</span>

          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="termination"
            field-path="additionalInformation.bankAccount.bankCode"
            :debounce="0"
            @validate="validateField"
          >
            <LitSelect
              class="bank-code"
              name="bankCode"
              dusk="code-of-bank"
              options-left="auto"
              option-label-key="label"
              option-value-key="bankCode"
              :model-value="value"
              :errors="errors"
              :searchable="true"
              :options-min-width="242"
              :options="bankCodeOptions"
              @update:model-value="input"
            >
              <template #selected-option="option">
                {{ option.value }}
              </template>

              <template #select-option="option">
                <div class="bank-code__item">
                  <span>{{ option.value }}</span>
                  <span>{{ option.label }}</span>
                </div>
              </template>
            </LitSelect>
          </ServerValidatedFormField>
        </div>
      </div>

      <div v-if="isNewContractEnabled">
        <hr>
        <h3>
          Převod prostředků na novou smlouvu
        </h3>

        <div
          class="custom-field"
        >
          <p class="m-0 flex-center">
            Částka, která bude převedena na novou smlovu

            <LitTooltip
              text="Tato částka musí být rovna nebo menší než celková částka k výplatě."
            />
          </p>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="termination"
            field-path="terminationSettings.transferContractAmount"
            :debounce="300"
            @validate="validateField"
          >
            <CurrencyInput
              dusk="transfer-amount"
              :model-value="value"
              :errors="errors"
              :show-tooltip="false"
              :min="1"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>
        </div>
      </div>
      <hr v-if="isTerminationTransfer">
      <hr class="my-30">
    </div>

    <StepButtons
      dusk="submit-step"
      namespace="termination"
      previous-step="paymentOption"
      @submit="submitStep"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import StepButtons from '../../common/StepButtons.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import LitSwitch from '@/js/components/Base/LitSwitch.vue';
import LitTooltip from '@/js/components/Base/LitTooltip.vue';

import CurrencyInput from '@/js/components/CurrencyInput.vue';
import TerminationStepper from '@/js/components/Steppers/TerminationStepper.vue';

import bankCodeOptions from '@/js/data/bankCodeOptions';
import { useTerminationStore } from '@/js/stores';

const route = useRoute();
const router = useRouter();

const store = useTerminationStore();
const { validateField, validateFields } = store;
const {
  terminationSettings,
  hasClientEmail,
  additionalInformation,
  currentContractInformation,
  personalData,
} = storeToRefs(store);

const fields = shallowRef([
  'additionalInformation.insuranceType.pensionInsurance',
  'additionalInformation.insuranceType.medicalInsurance',
  'additionalInformation.insuranceType.retirementPension',

  'additionalInformation.bankAccount.prefix',
  'additionalInformation.bankAccount.number',
  'additionalInformation.bankAccount.bankCode',
  'additionalInformation.paymentMethod',
  'terminationSettings.transferContractAmount',

  'terminationSettings.oldAgePension.selectedYears',
  'terminationSettings.oldAgePension.paymentFrequency',
]);

const paymentTypeOptions = shallowRef([
  { value: 'bankTransfer', label: 'Na bankovní účet' },
  { value: 'postOrder', label: 'Poštovní poukázkou' },
]);

const paymentFrequencyType = shallowRef([
  { label: 'měsíčně', value: 'monthly' },
  { label: 'čtvrtletně', value: 'quarterly' },
]);

const terminationType = computed(() => {
  return terminationSettings.value.paymentOption.value;
});

const isSmartMoney = computed(() => {
  return terminationType.value === 'smartMoney';
});

const isTerminationTransfer = computed({
  get () {
    return terminationSettings.value.withTransfer.value;
  },
  set (value) {
    terminationSettings.value.withTransfer.value = value;
  },
});

const isNewContractEnabled = computed(() => {
  return isTerminationTransfer.value && hasClientEmail.value;
});

const isPensionType = computed(() => {
  return terminationType.value === 'oldAgePensionShortTerm' || terminationType.value === 'oldAgePensionLongTerm';
});

const isOldAgePensionLongTerm = computed(() => {
  return terminationType.value === 'oldAgePensionLongTerm';
});

const isAdditionalPensionSaving = computed(() => {
  return currentContractInformation.value.isAdditionalPensionSaving.value;
});

const terminationTypeText = computed(() => {
  switch (terminationType.value) {
    case 'smartMoney':
      return 'Odbytné';
    case 'oneTimeSettlement':
      return (isAdditionalPensionSaving.value || isSmartMoney.value) ? 'Jednorázové vyrovnání' : 'jednorázového vyrovnání';
    case 'oldAgePensionShortTerm':
      return 'Starobní penze 3-9 let';
    case 'oldAgePensionLongTerm':
      return 'Starobní penze 10 a více let';
    default:
      return '';
  }
});

const pensionYearsOption = computed(() => {
  if (isOldAgePensionLongTerm.value) {
    return [
      { label: '10 let', value: 10 },
      { label: '11 let', value: 11 },
      { label: '12 let', value: 12 },
      { label: '13 let', value: 13 },
      { label: '14 let', value: 14 },
      { label: '15 let', value: 15 },
      { label: '16 let', value: 16 },
      { label: '17 let', value: 17 },
      { label: '18 let', value: 18 },
      { label: '19 let', value: 19 },
      { label: '20 let', value: 20 },
    ];
  }

  return [
    { label: '3 roky', value: 3 },
    { label: '4 roky', value: 4 },
    { label: '5 let', value: 5 },
    { label: '6 let', value: 6 },
    { label: '7 let', value: 7 },
    { label: '8 let', value: 8 },
    { label: '9 let', value: 9 },
  ];
});

const isBankTransfer = computed(() => {
  return additionalInformation.value.paymentMethod.value === 'bankTransfer';
});

const pensionInsurance = computed({
  get () {
    return additionalInformation.value.insuranceType.pensionInsurance.value;
  },
  set (value) {
    additionalInformation.value.insuranceType.pensionInsurance.value = value;
  },
});

const pensionInsuranceErrors = computed({
  get () {
    return additionalInformation.value.insuranceType.pensionInsurance.errors;
  },
  set (value) {
    additionalInformation.value.insuranceType.pensionInsurance.errors = value;
  },
});

const medicalInsurance = computed({
  get () {
    return additionalInformation.value.insuranceType.medicalInsurance.value;
  },
  set (value) {
    additionalInformation.value.insuranceType.medicalInsurance.value = value;
  },
});

const medicalInsuranceErrors = computed({
  get () {
    return additionalInformation.value.insuranceType.medicalInsurance.errors;
  },
  set (value) {
    additionalInformation.value.insuranceType.medicalInsurance.errors = value;
  },
});

const retirementPension = computed({
  get () {
    return additionalInformation.value.insuranceType.retirementPension.value;
  },
  set (value) {
    additionalInformation.value.insuranceType.retirementPension.value = value;
  },
});

const retirementPensionErrors = computed({
  get () {
    return additionalInformation.value.insuranceType.retirementPension.errors;
  },
  set (value) {
    additionalInformation.value.insuranceType.retirementPension.errors = value;
  },
});

function emptyPensionErrors () {
  retirementPensionErrors.value = [];
  pensionInsuranceErrors.value = [];
  medicalInsuranceErrors.value = [];
}

const validating = ref(false);
async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    await router.push({
      name: 'termination.recapitulation',
      params: {
        contractUuid,
      },
    });
  } catch (e) {
    console.warn(e);
  }
}

onMounted(() => {
  if (!isNewContractEnabled.value) {
    isTerminationTransfer.value = false;
  }
});

const fullAddress = computed(() => {
  return personalData.value.specifyMailingAddress.value
    ? `${personalData.value.mailingAddress.street.value} ${personalData.value.mailingAddress.city.value}`
    : `${personalData.value.permanentAddress.city.value} ${personalData.value.permanentAddress.street.value}`;
});

watch(pensionInsurance, (value) => {
  emptyPensionErrors();

  if (value && medicalInsurance.value) {
    medicalInsurance.value = false;
  }
});

watch(medicalInsurance, (value) => {
  emptyPensionErrors();

  if (
    (value && pensionInsurance.value)
    || (value && retirementPension.value)
    || (value && retirementPension.value && pensionInsurance.value)
  ) {
    pensionInsurance.value = false;
    retirementPension.value = false;
  }
});

watch(retirementPension, (value) => {
  emptyPensionErrors();

  if (value && medicalInsurance.value) {
    medicalInsurance.value = false;
  }
});
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.additional__text {
  width: 232px;
}

.justify-content-center {
  justify-content: center;
  flex: .5;
}

.custom-field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 82%;
}

.wrapper {
  height: 158px;
}

.custom-field--bank {
  display: flex;
}

.custom-field--bank__label {
  display: flex;
  flex: .6;
  align-items: center;
}

.additional__box {
  margin-top: 1rem;
}

.additional__span {
  font-size: 1.05rem;
}

.max-h-70 {
  max-height: 70px;
}

.bank-account {
  display: flex;

  &__slash {
    margin-top: (math.div($input-height, 2)) - 7px;
  }

  > *:not(:last-child) {
    margin-right: 5px;
  }

  .control {
    &--xs {
      flex-basis: 76px;
      max-width: 76px;
    }

    &--sm {
      flex-basis: 108px;
      max-width: 108px;
    }

    &--md {
      flex-basis: 155px;
      max-width: 155px;
    }
  }
}

.number-actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;

  .number-actions__btn {
    height: math.div($input-height, 2);
    width: math.div($input-height, 2);
    color: getColor(light-blue);
    outline: none;
    font-size: $root;

    &:hover {
      background: getColor(light-blue);
      color: getColor(white);
    }

    &:focus {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(light-blue);
    }
  }
}

.bank-code {
  width: 100px;

  &__item {
    display: flex;

    & > span:first-child {
      min-width: 50px;
    }
  }
}

.additional__switch {
  margin-bottom: .5rem;
}

.unit--dark {
  font-size: 1.1rem !important;
  width: 65px !important;
  pointer-events: none;
  right: 5px !important;
}

.m-0 {
  margin: 0;
  flex: .43;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-70 {
  width: 70px;
}

.address--box {
  position: relative;
  top: -32px;
  left: 173px;

  p {
    color: #afafaf;
    font-weight: bold;

    span {
      margin-left: 4px;
    }
  }
}

.amount-w {
  width: 140px;
}
</style>
