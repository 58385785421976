<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import EmailInput from '@/js/components/EmailInput.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';

import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import StepButtons from '@/js/views/common/StepButtons.vue';
import gtm from '@/js/services/gtm';
import { scrollToError } from '@/js/utils.ts';
import ChangeRequestFormsOfferModal from '@/js/components/Modals/ChangeRequestFormsOfferModal.vue';

const route = useRoute();
const router = useRouter();

const store = useChangeRequestStore();
const { validateField, validateFields } = store;

const validating = ref(false);
const showFormsOfferModal = ref(false);

function offerForms () {
  showFormsOfferModal.value = true;
}

async function handleSubmit () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
      ],
      documentsSent: store.beforeStart.isTiedAgent.value as boolean,
    });

    await router.push({
      name: `changeRequest.${store.changeSettings.involvedForms.value[0]}`,
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit(`changeRequest.${store.changeSettings.involvedForms.value[0]}`);
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation changeRequest ${store.changeSettings.involvedForms.value[0]}: ${e}`);
  } finally {
    validating.value = false;
  }
}
</script>

<template>
  <div class="container--sm">
    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate }"
      namespace="consentsRequest"
      field-path="email"
      :debounce="0"
      @validate="validateField"
    >
      <EmailInput
        name="email"
        dusk="email"
        label="E-mail"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
        @blur="validate($event.target.value)"
      />
    </ServerValidatedFormField>

    <h3>Souhlas s marketingovými účely</h3>

    <ServerValidatedFormField
      v-slot="{ value, input, errors }"
      namespace="consentsRequest"
      field-path="marketingPurposes"
      :default-value="false"
      :debounce="0"
      :validate-on-input="true"
      @validate="validateField"
    >
      <LitCheckbox
        class="mb-20"
        dusk="marketing-purposes"
        :model-value="value"
        :error="errors.length > 0 ? errors[0] : ''"
        @update:model-value="input"
      >
        Souhlasím se

        <a
          href="https://www.csob.cz/portal/csob/ochrana-osobnich-udaju"
          target="_blank"
        >
          zpracováním a sdílením svých údajů ve skupině ČSOB pro marketingové účely</a>.
        Souhlas je účinný okamžikem podpisu smlouvy.
      </LitCheckbox>
    </ServerValidatedFormField>

    <hr class="my-30">

    <div class="flex flex-center">
      <StepButtons
        namespace="changeRequest"
        previous-step="clientData"
        @submit="offerForms"
      />
    </div>

    <ChangeRequestFormsOfferModal
      v-model="showFormsOfferModal"
      :forms="store.changeSettings.involvedForms.value"
      @close="showFormsOfferModal = false"
      @finish="handleSubmit"
    />
  </div>
</template>
