<template>
  <div dusk="contact-information">
    <TerminationStepper current-step="contactInformation" />

    <div class="container--sm my-20">
      <h2>Kontaktní údaje podepisující osoby...</h2>

      <form
        dusk="contact-form"
        @submit.prevent="submitStep"
      >
        <LitAlert
          v-show="signerType === 'procuracy'"
          class="mb-20 mt-20"
        >
          Budeme potřebovat originál nebo úředně ověřenou kopii
          <a
            href="https://www.csob-penze.cz/media/2016/05/plna_moc-1.pdf"
            target="_blank"
            class="text-bold"
          >
            plné moci
          </a>, případně generální plné moci. Podpis zmocnitele musí být ověřen úředně.
        </LitAlert>

        <LitAlert
          v-show="signerType === 'legalRepresentative' && currentContractInformation.isAdditionalPensionSaving.value"
          class="mb-20 mt-20"
        >
          Budeme potřebovat doložit originál či úředně ověřenou kopii <strong>souhlasu
            opatrovnického soudu s ukončením Smlouvy</strong>, abychom si ověřili,
          že jste oprávněn/a jménem dítěte tuto Smlouvu ukončit.
        </LitAlert>

        <LitAlert
          v-show="signerType === 'legalRepresentative' && !currentContractInformation.isAdditionalPensionSaving.value"
          class="mb-20 mt-20"
        >
          Budeme potřebovat doložit kopii rodného listu nebo originál či úředně ověřenou kopii
          <strong>souhlasu opatrovnického soudu s ukončením Smlouvy</strong>,
          abychom si ověřili, že jste oprávněn/a jménem dítěte tuto Smlouvu ukončit.
        </LitAlert>

        <LitAlert
          v-show="signerType === 'committee'"
          class="mb-20 mt-20"
        >
          Budeme potřebovat rozsudek opatrovnického soudu, kterým soud schvaluje právní jednání
          za opatrovaného, kterým je ukončení penzijního spoření za opatrovance podle
          žádosti opatrovníka a listinu o jmenování opatrovníka. Kopie musí být ověřena podle
          originálu (samolepka vidimace/ razítko souhlasí s originálem/ text
          „souhlasí s originálem“).
        </LitAlert>

        <ContactInformation
          store-path="contactInformation"
          namespace="termination"
          title="Podpis na ukončení smlouvy je podpisem"
          :signer-types="signerTypes"
          :signers-visible="!isBankId"
          :validate-fnc="validateField"
        />
      </form>
    </div>

    <div class="container my-30">
      <hr>
    </div>

    <StepButtons
      namespace="termination"
      previous-step="salesInterview"
      @submit="submitStep"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import ContactInformation from '../../common/ContactInformation.vue';
import StepButtons from '../../common/StepButtons.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import TerminationStepper from '@/js/components/Steppers/TerminationStepper.vue';
import { contactInformationFields } from '@/js/data/validationFields/commonFields';

import gtm from '@/js/services/gtm';

import { scrollToError } from '@/js/utils';
import { useTerminationStore } from '@/js/stores';

const route = useRoute();
const router = useRouter();

const store = useTerminationStore();
const { signer, isBankId, currentContractInformation, hasClientEmail } = storeToRefs(store);
const { validateField, validateFields } = store;

const signerTypes = shallowRef([
  { value: 'participant', label: 'účastníka' },
  { value: 'legalRepresentative', label: 'zákonného zástupce' },
  { value: 'committee', label: 'opatrovníka' },
  { value: 'procuracy', label: 'na základě plné moci' },
]);

const signerType = computed(() => {
  return signer.value.type.value;
});

const validating = ref(false);
const contractDocumentsRequired = ref(false);

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;
    contractDocumentsRequired.value = store.beforeStart.isTiedAgent.value && hasClientEmail.value;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        ...contactInformationFields,
        'contactInformation.phonePrefix',
        'signer.document.type',
      ],
      documentsSent: contractDocumentsRequired.value,
    });

    validating.value = false;

    await router.push({
      name: 'termination.personalData',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('kontaktni-udaje');
  } catch (e) {
    validating.value = false;
    contractDocumentsRequired.value = false;

    scrollToError();

    console.warn(`There was a validation personalDataError: ${e}`);
  }
}

onMounted(() => {
  if (isBankId.value) {
    signer.value.type.value = 'participant';
  }
});
</script>
