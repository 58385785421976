<template>
  <div dusk="legal-requirements">
    <ServerValidatedFormField
      v-slot="{ value, input, errors }"
      :namespace="namespace"
      :field-path="`${storePath}.paysTaxesInCzechia`"
      :validate-on-input="true"
      :debounce="0"
      @validate="props.validateFnc"
    >
      <LitRadio
        class="mb-20"
        name="payTaxesInCZ"
        dusk="tax-payment"
        :tooltip="tooltips.paysTaxesInCzechia"
        :label="labels.paysTaxesInCzechia"
        :show-inline="true"
        :options="payTaxesInCZOptions"
        :model-value="value"
        :error="errors.length > 0 ? errors[0] : ''"
        @update:model-value="input"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-if="!isFromTermination"
      v-slot="{ value, input, errors }"
      :namespace="namespace"
      :field-path="`${storePath}.isPoliticallyExposed`"
      :validate-on-input="true"
      :debounce="0"
      @validate="props.validateFnc"
    >
      <LitRadio
        class="mb-20"
        dusk="politically-exposed"
        name="politicallyExposedPerson"
        :tooltip="tooltips.isPoliticallyExposed"
        :label="labels.isPoliticallyExposed"
        :show-inline="true"
        :options="politicallyExposedPersonOptions"
        :model-value="value"
        :error="errors.length > 0 ? errors[0] : ''"
        @update:model-value="input"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors }"
      :namespace="namespace"
      :field-path="`${storePath}.sourceOfIncome`"
      :validate-on-input="true"
      :debounce="0"
      @validate="props.validateFnc"
    >
      <LitSelect
        class="mb-20"
        dusk="income-from"
        :tooltip="tooltips.sourceOfIncome"
        :label="labels.sourceOfIncome"
        :options="incomeFromOptions"
        :model-value="value"
        :error="errors.length > 0 ? errors[0] : ''"
        @update:model-value="input"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-if="displayOccupationField"
      v-slot="{ value, input, errors, validate }"
      :namespace="namespace"
      :field-path="`${storePath}.occupation`"
      :validate-on-input="false"
      :debounce="0"
      @validate="props.validateFnc"
    >
      <LitInput
        name="job"
        class="mb-30"
        dusk="employment"
        placeholder="Např. Vedoucí provozu"
        :tooltip="tooltips.occupation"
        :label="labels.occupation"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
        @blur="validate($event.target.value)"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors }"
      :namespace="namespace"
      :field-path="`${storePath}.grantedPension`"
      :default-value="false"
      :debounce="0"
      :validate-on-input="true"
      @validate="props.validateFnc"
    >
      <LitCheckbox
        class="mb-20"
        dusk="granted-pension"
        tooltip="Od 1.7.2024 nemá klient, kterému již byl přiznám starobní důchod, nárok na státní příspěvek. Daňový odpočet lze uplatnit již od 1 Kč zaplaceného vlastního příspěvku."
        :show-tooltip="true"
        :model-value="value"
        :error="errors.length > 0 ? errors[0] : ''"
        @update:model-value="input"
      >
        Klientovi již byl přiznán starobní důchod
      </LitCheckbox>
    </ServerValidatedFormField>
  </div>
</template>

<script setup lang="ts">
import { computed, shallowRef, watch } from 'vue';

import { getActivePinia } from 'pinia';
import { get } from 'lodash';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';

import incomeFromOptions from '@/js/data/incomeFromOptions';
import type { ValidateStoreParamsFnc } from '@/js/stores/utils.ts';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';

interface Props {
  namespace: string
  storePath: string
  isFromTermination: boolean
  isParticipant: boolean
  validateFnc: ValidateStoreParamsFnc
}

const props = defineProps<Props>();

const payTaxesInCZOptions = shallowRef([
  { value: true, label: 'Ano' },
  { value: false, label: 'Ne' },
]);

const politicallyExposedPersonOptions = shallowRef([
  { value: false, label: 'Ne, nejsem' },
  { value: true, label: 'Ano, jsem' },
]);

const labels = computed(() => {
  if (!props.isParticipant) {
    return {
      paysTaxesInCzechia: 'Odvádí klient daně v České republice?',
      isPoliticallyExposed: 'Je klient politicky exponovanou osobou?',
      sourceOfIncome: 'Z čeho pocházejí příjmy klienta?',
      occupation: 'Povolání klienta',
    };
  }

  return {
    paysTaxesInCzechia: 'Odvádí klient daně v České republice?',
    isPoliticallyExposed: 'Je klient politicky exponovanou osobou?',
    sourceOfIncome: 'Z čeho pocházejí příjmy klienta?',
    occupation: 'Povolání klienta?',
  };
});

const tooltips = computed(() => {
  if (!props.isParticipant) {
    return {
      paysTaxesInCzechia: 'Možnost Ne označte pouze v případě, že alespoň jeden z příjmů klient daní v zahraničí. '
      + 'V případě, že žádný zdanitelný příjem nemá, protože je např. student nebo je v důchodu, '
      + 'je stále daňovým rezidentem ČR. Zvolte tedy možnost Ano.',
      isPoliticallyExposed: 'Politicky exponovaná osoba je osoba ve významné veřejné funkci nebo fyzická osoba '
      + 's takovou osobou příbuzná či v partnerském vztahu.',
      sourceOfIncome: 'Vyberte zdroj příjmů, ze kterého si bude klient přispívat na penzijní spoření.',
      occupation: 'Uveďte prosím co nejpřesněji profesi klienta (např. lékař, podnikající v oblasti zemědělství, '
      + 'jednatel ve společnosti aj.).',
    };
  }

  return {
    paysTaxesInCzechia: 'Možnost Ne označte pouze v případě, že alespoň jeden z příjmů klient daní v zahraničí. '
    + 'V případě, že žádný zdanitelný příjem nemá, protože je např. student nebo je v důchodu, '
    + 'je stále daňovým rezidentem ČR. Zvolte tedy možnost Ano.',
    isPoliticallyExposed: 'Politicky exponovaná osoba je osoba ve významné veřejné funkci nebo fyzická osoba '
    + 's takovou osobou příbuzná či v partnerském vztahu.',
    sourceOfIncome: 'Vyberte zdroj příjmu, ze kterého si bude klient přispívat na své penzijní spoření.',
    occupation: 'Uveďte prosím co nejpřesněji profesi klienta (např. lékař, podnikající v oblasti zemědělství, '
    + 'jednatel ve společnosti aj.).',
  };
});

const activeStore = computed(() => {
  const store = getActivePinia();

  if (!store) {
    throw new Error('Pinia store is not active. Make sure you have initialized Pinia and it is active in the current context.');
  }

  const storeField = get(store.state.value, `${props.namespace}.${props.storePath}`);

  if (!storeField) {
    throw new Error(`Store field ${props.namespace}.${props.storePath} does not exist. Check the namespace and storePath props.`);
  }

  return storeField;
});

const occupation = computed({
  get () {
    return get(activeStore.value, 'occupation.value');
  },
  set (value) {
    activeStore.value.occupation.value = value;
  },
});

const sourceOfIncome = computed({
  get () {
    return get(activeStore.value, 'sourceOfIncome.value');
  },
  set (value) {
    activeStore.value.sourceOfIncome.value = value;
  },
});

const grantedPension = computed({
  get () {
    return get(activeStore.value, 'grantedPension.value');
  },
  set (value) {
    activeStore.value.grantedPension.value = value;
  },
});

const displayOccupationField = computed(() => {
  return sourceOfIncome.value === 'ZAM' || sourceOfIncome.value === 'POD';
});

watch(displayOccupationField, (newVal) => {
  if (!newVal) {
    occupation.value = '';
  }
});

watch(sourceOfIncome, (newVal) => {
  if (newVal !== 'REN') {
    grantedPension.value = false;
  }
});
</script>
