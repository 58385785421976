import type { RouteRecordRaw } from 'vue-router';

import Layout from '@/js/views/distribution/changeRequest/Layout.vue';
import index from '@/js/views/distribution/changeRequest/index.vue';

import ContractSettingsContributions from '@/js/views/distribution/changeRequest/Contributions.vue';
import PersonalDataChange from '@/js/views/distribution/changeRequest/PersonalDataChange.vue';
import EmailAndConsentsChange from '@/js/views/distribution/changeRequest/EmailAndConsentsChange.vue';
import StrategyChange from '@/js/views/distribution/changeRequest/StrategyChange.vue';
import NomineesChange from '@/js/views/distribution/changeRequest/NomineesChange.vue';
import LegalRequirementsChange from '@/js/views/distribution/changeRequest/LegalRequirementsChange.vue';
import IdentityDocumentChange from '@/js/views/distribution/changeRequest/IdentityDocumentChange.vue';
import Recapitulation from '@/js/views/distribution/changeRequest/Recapitulation.vue';
import ThankYouSignaturePage from '@/js/views/distribution/changeRequest/ThankYouSignaturePage.vue';
import LitSignThankYouRedirectPage from '@/js/views/distribution/changeRequest/LitSignThankYouRedirectPage.vue';
import ThankYouPage from '@/js/views/distribution/changeRequest/ThankYouPage.vue';
import ClientData from '@/js/views/distribution/changeRequest/ClientData.vue';

export const changeRequest: RouteRecordRaw = {
  path: '/zmenove-formulare/:contractUuid',
  component: Layout,
  children: [
    {
      path: 'nez-zacneme',
      name: 'changeRequest.beforeStart',
      component: index,
    },
    {
      path: 'identifikace-ucastnika',
      name: 'changeRequest.clientData',
      component: ClientData,
    },
    {
      path: 'nastaveni-smlouvy',
      name: 'changeRequest.contractSettings',
      component: ContractSettingsContributions,
    },
    {
      path: 'zakonne-pozadavky',
      name: 'changeRequest.legalRequirements',
      component: LegalRequirementsChange,
    },
    {
      path: 'osobni-a-kontaktni-udaje',
      name: 'changeRequest.personalData',
      component: PersonalDataChange,
    },
    {
      path: 'email-a-souhlasy',
      name: 'changeRequest.emailAndConsents',
      component: EmailAndConsentsChange,
    },
    {
      path: 'strategie',
      name: 'changeRequest.strategy',
      component: StrategyChange,
    },
    {
      path: 'urcene-osoby',
      name: 'changeRequest.nominees',
      component: NomineesChange,
    },
    {
      path: 'doklad-totoznosti',
      name: 'changeRequest.identityDocument',
      component: IdentityDocumentChange,
    },
    {
      path: 'rekapitulace',
      name: 'changeRequest.recapitulation',
      component: Recapitulation,
    },
    {
      path: 'dekujeme-odeslani-odkazu',
      name: 'changeRequest.thankYouSignaturePage',
      component: ThankYouSignaturePage,
    },
    {
      path: 'dekujeme-za-zmenu',
      name: 'changeRequest.thankRedirectPage',
      component: LitSignThankYouRedirectPage,
    },
    {
      path: 'dekujeme',
      name: 'changeRequest.thankYouPage',
      component: ThankYouPage,
    },
  ],
};
