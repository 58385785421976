<template>
  <div>
    <LitModal
      :model-value="modelValue"
    >
      <template #header>
        <h3>
          Došlo ke změně smluvních podmínek
        </h3>
      </template>

      <template #body>
        <h3>Upozornění na změnu termínu {{ message }}</h3>

        <p>
          Protože dochází k podpisu smlouvy až v následujícím měsíci oproti měsíci,
          kdy byla žádost vytvořena, posouvá se datum ukončení stávajícího
          a založení nového penzijka o 1 měsíc tak, aby termíny odpovídaly pravidlům převodů domluvenými mezi
          penzijními společnostmi.
        </p>

        <p><strong>Nové datumy jsme ve smluvní dokumentaci automaticky upravili.</strong></p>
      </template>

      <template #footer>
        <div class="modal__buttons">
          <LitButton
            dusk="close-modal"
            variant="outline"
            @click="$emit('click', $event)"
          >
            <span>Pokračovat k podpisu smlouvy</span>
          </LitButton>
        </div>
      </template>
    </LitModal>
  </div>
</template>

<script setup lang="ts">
import { computed, shallowRef } from 'vue';
import LitModal from '../Base/LitModal.vue';
import LitButton from '../Base/LitButton.vue';

interface FormType {
  distribution: string
  jps: string
  changeRequest: string
}

interface Props {
  modelValue: boolean
  formType: keyof FormType
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  formType: 'distribution',
});

defineEmits(['click']);

const text = shallowRef({
  distribution: 'ukončení stávající smlouvy a vzniku nové',
  jps: 'převodu penzijka od konkurence',
  changeRequest: 'změnového formuláře',
});

const message = computed(() => {
  return text.value[props.formType];
});
</script>
