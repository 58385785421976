import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat';
import { useStoreField } from '@/js/composables/useStoreField';

import {
  beforeStart,
  contactInformation,
  personalData,
  personalDocument,
  signer,
} from '@/js/stores/createStoreFields';
import type { NullableString, SubmitResponse } from '@/js/stores/types';

export const state = {
  rehydrated: useStoreField(false, false),

  beforeStart: {
    ...beforeStart({}),
    secondDistributor: {
      firstName: useStoreField<NullableString>(''),
      lastName: useStoreField<NullableString>(''),
      email: useStoreField<NullableString>(''),
    },
  },

  contactInformation: {
    ...contactInformation(),
    phonePrefix: useStoreField('420'),
  },

  personalData: {
    ...personalData({}),
    birthDate: useStoreField(''),
    gender: useStoreField(''),
    permanentAddressArea: useStoreField('cz'),
    citizenshipInEu: useStoreField(true),
    permanentAddressInEu: useStoreField(true),

    insuranceNumbers: {
      medicalInsurance: useStoreField<NullableString>(''),
      pensionInsurance: useStoreField<NullableString>(''),
      retirementPension: useStoreField<NullableString>(''),
    },

    isPoliticallyExposed: useStoreField<null | boolean>(null),

    document: personalDocument({}),
  },

  signer: signer({}),

  currentContractInformation: {
    contractNumber: useStoreField<NullableString>(''),
    totalAmount: useStoreField(0),
    isAdditionalPensionSaving: useStoreField(false),
  },

  terminationSettings: {
    isBankId: useStoreField(false, true),
    signatureBank: useStoreField<NullableString>('', false),
    transferContractAmount: useStoreField<NullableString>(
      '',
      true,
      (val: string) => {
        if (typeof val.replace === 'function') {
          return Number(val.replace(/\s/g, ''));
        }

        return val;
      },
    ),
    currentContractEndDate: useStoreField(useDateTimeFormat(new Date(), 'yyyy-MM-dd')),

    oneTimeSettlement: {
      isEnabled: useStoreField(true),
    },

    oldAgePension: {
      isEnabled: useStoreField(false),
      selectedYears: useStoreField(13),
      paymentFrequency: useStoreField('monthly'),
    },

    transferSignaturePlace: useStoreField<NullableString>(null),
    paymentOption: useStoreField('oneTimeSettlement'),
    withTransfer: useStoreField(false),
  },

  additionalInformation: {
    paymentMethod: useStoreField('bankTransfer'),
    insuranceType: {
      pensionInsurance: useStoreField(false),
      medicalInsurance: useStoreField(false),
      retirementPension: useStoreField(false),
    },

    bankAccount: {
      prefix: useStoreField<NullableString>(''),
      number: useStoreField<NullableString>(''),
      bankCode: useStoreField<NullableString>(null),
    },
  },

  newContract: {
    uuid: useStoreField<NullableString>(null),
    contractNumber: useStoreField<NullableString>(null),
  },

  draftToken: useStoreField<{ value: NullableString, expiresAt: NullableString }>({
    value: null,
    expiresAt: null,
  }, false),

  meetingRecordFilePath: useStoreField<NullableString>(null),

  // These value is received at the very and after contract is submitted
  submitResponse: useStoreField<SubmitResponse | null>(null, false),
};
