<template>
  <div>
    <div dusk="signature-thank-you">
      <div class="benefits bg-blue pt-30 pb-10" />
      <div class="container--sm center pt-30">
        <LitIconSvg icon-name="icon-redirect_to_litsign" />

        <h2>
          Děkujeme, pokračujte prosím na jiném zařízení
        </h2>

        <div class="center">
          <p class="my-20">
            Na e-mail <strong>{{ beforeStart.distributor.email.value }}</strong> jsme odeslali
            odkaz na aplikaci, kde podepíšete smlouvu elektronicky.
          </p>

          <p class="mb-20">
            Podpis smlouvy je možný i na mobilním zařízení.
          </p>

          <p v-if="draftTokenLocal.value !== null">
            Odkaz je platný do <strong>{{ contractDraftTokenValidity.label }}</strong>.
          </p>

          <p>Na podpis můžete přejít i skrze QR kód:</p>

          <div
            v-if="draftTokenLocal.value !== null"
            class="qr-code"
          >
            <QrcodeVue
              :value="signingLink"
              :size="180"
              level="H"
            />
          </div>
        </div>

        <LitButton
          class="my-30"
          variant="outline"
          :to="{ name: 'distribution.home' }"
        >
          <span>
            Zpět na hlavní rozcestník
          </span>
        </LitButton>

        <div
          v-show="showPensionCompanyNotice === true"
          class="py-20"
        >
          <p>
            Vyplňte prosím s klientem také <strong>výpověď doplňkového penzijního spoření </strong>
            se žádostí o převod prostředků. Výpověď se žádostí o převod musí být úředně ověřená
            (matrika/Czech Point/notář) a odeslaná do původní penzijní společnosti.
          </p>

          <div>
            <div class="inside">
              <a
                class="download flex"
                href="/storage/documents/vypoved_DPS_se_zadosti_o_prevod_k_CSOBPS_512.pdf"
                download
              >
                <LitIconSvg
                  class="btn__icon btn__icon--big big"
                  icon-name="icon-download"
                />

                <div>
                  Výpověď doplňkového penzijního spoření se žádostí
                  o převod prostředků k ČSOB Penzijní společnosti
                  <br>
                  <span class="grey">(PDF, 257 kB)</span>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div
          v-show="showTransformationFundNotice === true"
          class="py-20"
        >
          <p>
            Vyplňte prosím s klientem také <strong>výpověď doplňkového penzijního spoření</strong>
            se žádostí o převod prostředků.
          </p>

          <div>
            <div class="inside">
              <a
                class="download flex"
                href="/storage/documents/vypoved_penzijniho_pripojisteni_514.pdf"
                download
              >
                <LitIconSvg
                  class="btn__icon btn__icon--big big"
                  icon-name="icon-download"
                />

                <div>
                  Výpověď Penzijního připojištění se žádostí o převod prostředků
                  z Transformovaného fondu Stabilita do Účastnických fondů ČSOB PS
                  <br>
                  <span class="grey">(PDF, 97 kB)</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns';
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import QrcodeVue from 'qrcode.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitButton from '@/js/components/Base/LitButton.vue';

import { useDistributionStore } from '@/js/stores';
import { resetState } from '@/js/stores/utils';

const route = useRoute();

const store = useDistributionStore();
const { draftToken, beforeStart, contractSettings } = storeToRefs(store);

const dateFormat = ref('dd. MM. yyyy');

const draftTokenLocal = ref<{ value: null | string, expiresAt: string | null }>({
  value: null,
  expiresAt: null,
});

const contractDraftTokenValidity = computed(() => {
  const validUntil = new Date(draftTokenLocal.value.expiresAt as string);

  return {
    value: format(validUntil, 'yyyy-MM-dd'),
    label: format(validUntil, dateFormat.value),
  };
});

const showTransformationFundNotice = computed(() => {
  return contractSettings.value.transferExistingRetirementSavings.value === true
    && contractSettings.value.currentContract.type.value === 'transformFund';
});

const showPensionCompanyNotice = computed(() => {
  return contractSettings.value.transferExistingRetirementSavings.value === true
    && contractSettings.value.currentContract.type.value === 'pensionCompany';
});

const signingLink = computed(() => {
  const { contractUuid } = route.params;
  const protocolValues = ['http', 'https'];
  const currentLocation = window.location;

  const redirectUri = `${currentLocation.host}/distribuce/${contractUuid}/rekapitulace?draftToken=${draftTokenLocal.value.value}`;
  const protocolIncluded = protocolValues.some((value) => redirectUri.includes(value));

  return protocolIncluded ? redirectUri : `${currentLocation.protocol}//${redirectUri}`;
});

onBeforeRouteLeave((to, _from, next) => {
  resetState(store.$state);

  const disallowedPaths = [
    'distribution.recapitulation',
  ];

  if (to.name && disallowedPaths.includes(to.name as string)) {
    next({ name: 'distribution.home' });
  } else {
    next();
  }
});

onMounted(() => {
  draftTokenLocal.value = {
    value: draftToken.value.value.value,
    expiresAt: new Date(draftToken.value.value.expiresAt as string).toString(),
  };
});
</script>

<style scoped>
  .mt-10 {
    margin-top: 10px;
  }
</style>
