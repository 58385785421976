<template>
  <div dusk="investment-questionnaire">
    <DistributionStepper current-step="contributionAndStrategy" />

    <div class="container--sm mt-20">
      <h2 dusk="contribution-heading">
        Výše příspěvku, který si bude klient měsíčně platit
      </h2>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="distribution"
        field-path="contributionAndStrategy.contributionAmount"
        class="mb-40"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateField"
      >
        <ContributionSelection
          v-model:contribution-group="contributionGroup"
          class="mb-30"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          :is-for-distributor="true"
          :is-granted-pension="legalRequirements.grantedPension.value"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <hr class="my-30">

      <div
        v-if="!isFromRequest"
        class="mb-40"
        dusk="current-contract"
      >
        <h3 dusk="existing-contract">
          Už si na penzi spořím
        </h3>

        <ServerValidatedFormField
          v-slot="{ value, input }"
          namespace="distribution"
          field-path="contractSettings.transferExistingRetirementSavings"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateField"
        >
          <LitCheckbox
            class="mb-20"
            dusk="pensionCompany"
            :model-value="value"
            @update:model-value="input"
          >
            Už si spořím na jiné smlouvě
          </LitCheckbox>
        </ServerValidatedFormField>

        <div
          v-if="transferExistingRetirementSavings"
          class="py-20"
          dusk="current-contract"
        >
          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="distribution"
            field-path="contractSettings.currentContract.companyName"
            :debounce="0"
            @validate="validateField"
          >
            <LitSelect
              label="Stávající penzijní společnost"
              dusk="current-contract-company"
              class="mb-20"
              searchable
              :options="currentPensionCompanyOptions"
              :model-value="value"
              :error="errors.length > 0 ? errors[0] : ''"
              @update:model-value="input"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="distribution"
            field-path="contractSettings.currentContract.contractNumber"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              name="currentContractNumber"
              dusk="current-contract-number"
              placeholder="Např. 5875737291"
              label="Číslo současné smlouvy"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="distribution"
            field-path="contractSettings.currentContract.contractEndsAt"
            :validate-on-input="true"
            :debounce="0"
            @validate="validateField"
          >
            <LitSelect
              class="mb-20"
              tooltip="Uveďte datum ukončení/poslední den výpovědní lhůty."
              dusk="current-contract-end"
              label="Datum ukončení tohoto penzijního spoření"
              :options="endDateCurrentPensionSavingsOptions"
              :model-value="value"
              :error="errors.length > 0 ? errors[0] : ''"
              @update:model-value="input"
            />
          </ServerValidatedFormField>
        </div>
        <hr class="my-30">
      </div>

      <h3 dusk="questionnaire-heading">
        Nastavte správnou strategii penzijního spoření
      </h3>

      <LitAlert>
        Musíme se zeptat na klientovy znalosti a zkušenosti z oblasti financí
        a investičních nástrojů, abychom mu mohli doporučit vhodnou

        <button
          type="button"
          class="btn-simple"
          @click="showSavingsStrategy = true"
        >
          strategii spoření
        </button>.
      </LitAlert>

      <div class="stepper-vertical">
        <div
          class="step"
          dusk="invest-questionnaire"
        >
          <div
            id="questionnaire"
            class="step__header"
          >
            <div class="step__icon">
              <span class="step__icon--number">
                1
              </span>
            </div>

            <div class="step__label">
              Vyplňte s klientem krátký penzijní dotazník
            </div>
          </div>

          <div class="step__container">
            <div class="step__content">
              <LitAlert
                v-if="isQuestionnaireProcessed"
                dusk="questionnaire-successful-alert"
                alert-type="success"
                class="mb-20"
              >
                Penzijní dotazník byl úspěšně vyplněn a vyhodnocen.
              </LitAlert>
              <div
                v-if="isQuestionnaireProcessed"
                dusk="toggle-show-questionnaire"
                class="btn-simple mb-20"
                @click="showQuestionnaire = !showQuestionnaire"
              >
                <span v-if="!showQuestionnaire">
                  Zobrazit odpovědi penzijního dotazníku, případně dotazník upravit
                </span>

                <span v-else>
                  Skrýt odpovědi penzijního dotazníku
                </span>

                <img
                  :src="getIconUrl('arrow_down-green')"
                  alt="šipka"
                  class="ml-7"
                  :class="{ rotated: showQuestionnaire }"
                >
              </div>

              <div v-show="!loading && showQuestionnaire">
                <div
                  v-for="question in questions"
                  :key="question.id"
                  :dusk="`question${question.id}`"
                  :class="{ selected: question.answer !== null }"
                  class="question mb-20"
                >
                  <template
                    v-if="isQuestionVisible(question.id)"
                  >
                    <div class="question__header">
                      <div class="question__count">
                        {{ question.id }}.
                      </div>

                      <div class="question__label">
                        {{ question.label }}
                      </div>

                      <div class="question__action">
                        <button
                          v-if="question.answer !== null"
                          type="button"
                          dusk="change-answer"
                          class="btn-simple"
                          @click="resetAnswer(question.id, setAnswer)"
                        >
                          <i>
                            <img
                              :src="getIconUrl('icon-edit')"
                              alt="Ikona upravit"
                            >
                          </i>
                          Změnit odpověď
                        </button>
                      </div>
                    </div>

                    <div class="question__content">
                      <ServerValidatedFormField
                        v-slot="{ value, input, errors }"
                        namespace="distribution"
                        :debounce="0"
                        :default-value="question.answer"
                        :field-path="`contributionAndStrategy.questionnaire.question${question.id}`"
                        :validate-on-input="true"
                        @validate="validateField"
                      >
                        <LitRadio
                          :show-only-selected="question.answer !== null"
                          :name="`question${question.id}Options`"
                          :options="question.options"
                          :model-value="value"
                          :error="errors.length > 0 ? errors[0] : ''"
                          @update:model-value="input"
                        />
                      </ServerValidatedFormField>
                    </div>
                  </template>
                </div>
              </div>

              <div v-if="loading">
                <div class="flex flex-center mb-20 flip">
                  <img
                    :src="getIconUrl('icon-loader_big')"
                    alt="Načítání..."
                    class="spin"
                  >
                </div>
                <div class="text-warning text-center text-bold">
                  Vyhodnocujeme klientovy odpovědi, chvíli strpení
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          dusk="strategy-step"
          class="step"
          :class="{ disabled: !showStrategySelection }"
        >
          <div
            id="customStrategy"
            class="step__header"
          >
            <div class="step__icon">
              <span class="step__icon--number">
                2
              </span>
            </div>

            <div class="step__label">
              Vyberte s klientem strategii
            </div>
          </div>

          <div class="step__container">
            <div class="step__content">
              <LitAlert
                v-if="!showStrategySelection"
                dusk="step-over-questionnaire-alert"
                alert-type="warning"
              >
                Vyplňte s klientem výše uvedený penzijní dotazník.
                Na jeho základě doporučíme spořicí strategii vhodnou právě pro vašeho klienta.
              </LitAlert>

              <div v-if="isQuestionnaireProcessed && selectCustomStrategy">
                <p class="mb-30">
                  Výběrem vlastní strategie spoření se klient vystavuje riziku,
                  že zvolená strategie spoření nebude odpovídat
                  jeho cílům, odborným znalostem nebo zkušenostem potřebným
                  pro pochopení rizik souvisejících s investováním.
                </p>

                <ServerValidatedFormField
                  v-slot="{ value, input }"
                  namespace="distribution"
                  field-path="contributionAndStrategy.selectedStrategy"
                  :debounce="0"
                  :validate-on-input="true"
                  @validate="validateField"
                >
                  <DistributionCustomStrategySelection
                    v-model:fund-combination="fundCombination"
                    :model-value="value"
                    :is-custom-strategy="selectCustomStrategy"
                    :prefer-conservative-fund="preferConservativeFund"
                    @update:model-value="input"
                  />
                </ServerValidatedFormField>
              </div>

              <div v-if="isQuestionnaireProcessed && !selectCustomStrategy">
                <p class="mb-30">
                  Na základě odpovědí klienta doporučujeme níže uvedené strategie.
                </p>

                <ServerValidatedFormField
                  v-slot="{ value, input, errors }"
                  namespace="distribution"
                  field-path="contributionAndStrategy.selectedStrategy"
                  :debounce="0"
                  :validate-on-input="true"
                  @validate="validateField"
                >
                  <StrategySelection
                    dusk="strategy-selection"
                    :total-points="totalPoints"
                    :model-value="value"
                    :error="errors.length > 0 ? errors[0] : ''"
                    :prefer-conservative-fund="preferConservativeFund"
                    :prefer-reinvestment="transferExistingRetirementSavings "
                    @update:model-value="input"
                  />
                </ServerValidatedFormField>
              </div>
            </div>
            <div v-show="showStrategySelection && isQuestionnaireProcessed" class="text-center mt-20">
              <button
                v-if="!selectCustomStrategy"
                class="btn-simple"
                type="button"
                @click="showCustomStrategyModal = true"
              >
                Případně vyberte vlastní strategii
              </button>
              <button
                v-else
                class="btn-simple"
                type="button"
                @click="selectCustomStrategy = false"
              >
                Zpět na doporučené strategie
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <StepButtons
      namespace="distribution"
      previous-step="legalRequirements"
      :disabled="!canBeSubmitted"
      @submit="submitStep"
    />

    <StrategyOfPensionSavingModal
      form-type="distribution"
      :model-value="showSavingsStrategy"
      @close="showSavingsStrategy = false"
    />

    <CustomStrategyWarningModal
      :model-value="showCustomStrategyModal"
      @close="showCustomStrategyModal = false"
      @click="onCustomStrategyClick"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, onMounted, ref, shallowRef, watch } from 'vue';
import {
  add,
  addMonths,
  eachMonthOfInterval,
  format,
  lastDayOfMonth,
  parse as parseDate,
} from 'date-fns';

import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import StrategyOfPensionSavingModal from '@/js/components/Modals/StrategyOfPensionSavingModal.vue';
import CustomStrategyWarningModal from '@/js/components/Modals/CustomStrategyWarningModal.vue';

import ContributionSelection from '@/js/components/ContributionSelection.vue';
import DistributionStepper from '@/js/components/Steppers/DistributionStepper.vue';

import StrategySelection from '@/js/components/StrategySelection.vue';

import gtm from '@/js/services/gtm';
import pensionCompanies from '@/js/data/pensionCompanies';
import { canEnterRent, getIconUrl, scrollToError } from '@/js/utils';

import { useDistributionStore } from '@/js/stores';
import { updateContract } from '@/js/stores/utils';
import { type Question, useQuestionnaire } from '@/js/composables/useQuestionnaire';
import { contributionAndStrategyFields, currentContractFields } from '@/js/data/validationFields/commonFields';
import availableAnswers from '@/js/data/availableFinancialQuestionnaireAnswers';
import StepButtons from '@/js/views/common/StepButtons.vue';

const DistributionCustomStrategySelection = defineAsyncComponent(
  () => import('@/js/components/Distribution/CustomStrategySelection.vue'),
);

const store = useDistributionStore();
const {
  isFromRequest,
  isFromTransfer,
  contributionAndStrategy,
  contractSettings,
  personalData,
  legalRequirements,
  participantsBirthDay,
} = storeToRefs(store);
const { validateField, validateFields } = store;

const route = useRoute();
const router = useRouter();

const showSavingsStrategy = ref(false);
const showCustomStrategyModal = ref(false);
const currentPensionCompanyOptions = ref(pensionCompanies);
const dateFormat = ref('dd. MM. yyyy');

const availableQuestions = shallowRef<Question[]>(availableAnswers);

const {
  questions,
  status,
  resetAnswer,
  loading,
  isQuestionVisible,
} = useQuestionnaire(availableQuestions.value, contributionAndStrategy.value.questionnaire);

const endDateCurrentPensionSavingsOptions = computed(() => {
  const today = new Date();

  const SAVING_AD_MONTH = 1;
  const startDate = addMonths(today, SAVING_AD_MONTH);

  const rangeOfMonths = eachMonthOfInterval({
    start: startDate,
    end: add(startDate, {
      months: 5,
    }),
  });

  return rangeOfMonths.map((date) => ({
    value: format(lastDayOfMonth(date), 'yyyy-MM-dd'),
    label: format(lastDayOfMonth(date), dateFormat.value),
  }));
});

const totalPoints = computed({
  get () {
    return contributionAndStrategy.value.totalPoints.value;
  },
  set (value) {
    contributionAndStrategy.value.totalPoints.value = value;
  },
});

const permanentAddressArea = computed({
  get () {
    return personalData.value.permanentAddressArea.value;
  },
  set (value) {
    personalData.value.permanentAddressArea.value = value;
  },
});

const isQuestionnaireProcessed = computed(() => {
  return totalPoints.value !== null && !loading.value;
});

const selectCustomStrategy = computed({
  get () {
    return contributionAndStrategy.value.selectCustomStrategy.value;
  },
  set (value) {
    contributionAndStrategy.value.selectCustomStrategy.value = value;
  },
});

const selectedStrategy = computed({
  get () {
    return contributionAndStrategy.value.selectedStrategy.value;
  },
  set (value) {
    contributionAndStrategy.value.selectedStrategy.value = value;
  },
});

const showStrategySelection = computed(() => {
  return isQuestionnaireProcessed.value || selectCustomStrategy.value;
});

const transferExistingRetirementSavings = computed({
  get () {
    return contractSettings.value.transferExistingRetirementSavings.value;
  },
  set (value) {
    contractSettings.value.transferExistingRetirementSavings.value = value;
  },
});

const fundCombination = computed({
  get () {
    return contributionAndStrategy.value.fundCombination.value;
  },
  set (value) {
    contributionAndStrategy.value.fundCombination.value = value;
  },
});

const isFundCombinationComplete = computed(() => {
  const sum = Object.values(fundCombination.value).reduce((accumulator, current) => accumulator + current, 0);

  return sum === 100;
});

const canBeSubmitted = computed(() => {
  if (selectedStrategy.value === 'customFundCombination') {
    return isFundCombinationComplete.value;
  }

  return selectedStrategy.value !== null;
});

const birthDay = computed(() => {
  if (permanentAddressArea.value !== 'cz') {
    return parseDate(personalData.value.birthDate.value, 'dd. MM. yyyy', new Date());
  }

  return participantsBirthDay.value;
});

const preferConservativeFund = computed(() => {
  return canEnterRent(birthDay.value);
});

const currentContractTransferType = computed({
  get () {
    return contractSettings.value.currentContract.type.value;
  },
  set (value) {
    contractSettings.value.currentContract.type.value = value;
  },
});

const currentContractCompanyName = computed({
  get () {
    return contractSettings.value.currentContract.companyName.value;
  },
  set (value) {
    contractSettings.value.currentContract.companyName.value = value;
  },
});

const contributionGroup = computed({
  get () {
    return contributionAndStrategy.value.contributionGroup.value;
  },
  set (value) {
    contributionAndStrategy.value.contributionGroup.value = value;
  },
});

const showQuestionnaire = computed({
  get () {
    return contributionAndStrategy.value.showQuestionnaire.value;
  },
  set (value) {
    contributionAndStrategy.value.showQuestionnaire.value = value;
  },
});

function setTotalPointsFromStatus () {
  if ((status.value.isKo || status.value.unansweredQuestions === 0)
    && totalPoints.value === null) {
    totalPoints.value = status.value.totalPoints;
  }
}

function setDefaultStrategy (transferCurrentContract: boolean) {
  if (selectedStrategy.value !== null) {
    return;
  }

  if (transferCurrentContract && selectedStrategy.value === 'lifeCycle') {
    selectedStrategy.value = 'reinvestment';
  }

  if (!transferCurrentContract && selectedStrategy.value === 'reinvestment') {
    selectedStrategy.value = 'lifeCycle';
  }
}

function setContributionGroup () {
  switch (contributionAndStrategy.value.contributionAmount.value) {
    case 0:
      contributionGroup.value = 'option-1';
      break;
    case 500:
      contributionGroup.value = 'option-500';
      break;
    case 1700:
      contributionGroup.value = 'option-1000';
      break;
    case 5700:
      contributionGroup.value = 'option-5000';
      break;
    default:
      contributionGroup.value = 'option-custom';
  }
}

function setShowQuestionnaire () {
  if (!selectCustomStrategy.value) {
    showQuestionnaire.value = !isQuestionnaireProcessed.value;
  } else {
    showQuestionnaire.value = false;
  }
}

function setAnswer (questionId: number, answer: string | null) {
  // @ts-expect-error: TODO: fix this
  contributionAndStrategy.value.questionnaire[`question${questionId}`].value = answer;
  contributionAndStrategy.value.selectedStrategy.value = null;
  contributionAndStrategy.value.selectCustomStrategy.value = false;
}

function onCustomStrategyClick () {
  selectedStrategy.value = null;
  selectCustomStrategy.value = true;
  showQuestionnaire.value = true;
  showCustomStrategyModal.value = false;
}

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        ...contributionAndStrategyFields,
        ...currentContractFields,
      ],
    });

    await router.push({
      params: {
        contractUuid,
      },
      name: 'distribution.contractSettings',
    });

    gtm.onStepSubmit('vyse-prispevku-a-strategie');
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation contractSettingsError: ${e}`);
  } finally {
    scrollToError();
  }
}

onMounted(() => {
  setTotalPointsFromStatus();
  setContributionGroup();
  setShowQuestionnaire();

  if (isFromTransfer.value) {
    transferExistingRetirementSavings.value = true;
    currentContractTransferType.value = 'transformFund';
  }

  setDefaultStrategy(transferExistingRetirementSavings.value);
});

watch(transferExistingRetirementSavings, (newValue) => {
  if (newValue && !isFromTransfer.value) {
    currentContractTransferType.value = 'pensionCompany';
  } else if (!newValue && !isFromTransfer.value) {
    currentContractTransferType.value = null;
  }

  store.contractSettings.contractCreationDate.value = null;

  setDefaultStrategy(newValue);
});

watch(status, (newValue) => {
  if (newValue.unansweredQuestions === 0 || newValue.isKo) {
    contributionAndStrategy.value.totalPoints.value = newValue.totalPoints;
    loading.value = true;
    contributionAndStrategy.value.showQuestionnaire.value = false;

    setTimeout(() => {
      loading.value = false;
    }, 1700);
  }

  if ((newValue.totalPoints === 0 && newValue.unansweredQuestions !== 0 && !newValue.isKo) || newValue.totalPoints === null) {
    contributionAndStrategy.value.totalPoints.value = null;
  }
});

watch(showStrategySelection, (val) => {
  if (!val) {
    selectedStrategy.value = null;
  }
});

watch(selectCustomStrategy, async (val) => {
  const { contractUuid } = route.params;

  if (val) {
    showQuestionnaire.value = false;
  } else {
    selectedStrategy.value = null;
  }

  await validateFields({
    contractUuid,
    throwOnErrors: false,
    fields: [
      {
        value: val,
        path: 'contributionAndStrategy.selectCustomStrategy',
      },
    ],
  });
});

watch (currentContractCompanyName, (newVal) => {
  if (newVal !== 'choose') {
    contractSettings.value.currentContract.companyName.errors = [];
  }
});

watch(fundCombination, () => {
  const { contractUuid } = route.params;

  updateContract({
    state: store.$state,
    contractUuid: contractUuid as string,
  });
}, {
  deep: true,
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';
@import '@sass/tools/functions';

.stepper-vertical {
  margin-bottom: 20px;

  .step {
    &__header {
      font-family: $fontSecondary;
      font-size: 21px;
      display: flex;
      align-items: center;
      padding: 18px 0;

      @include media(min, $sm) {
        font-size: 22px;
      }
    }

    &__icon {
      border-radius: 50%;
      height: 23px;
      width: 23px;
      flex-shrink: 0;
      position: relative;
      font-size: $root-mini;
      font-weight: $bold;
      background: getColor(default);
      color: getColor(white);
      margin-right: 10px;

      @include media(min, $sm) {
        height: 41px;
        width: 41px;
        font-size: 21px;
      }

      &--number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
        height: fit-content;
        margin-top: -1px;
      }
    }

    &__container {
      margin-left: 11.5px;
      border: 0;
      position: relative;

      @include media(min, $sm) {
        margin-left: 20.5px;
      }

      [class*='alert-'] {
        min-height: 55px;
      }

      .underlined {
        text-decoration: underline;

        .rotated {
          transform: rotate(180deg);
        }
      }
    }

    &:not(:last-of-type) {
      .step__container {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          border-left: 1px solid getColor(default);
          top: -6px;
          bottom: -6px;

          @include media(min, $sm) {
            left: -1px;
            border-left-width: 2px;
          }
        }
      }
    }

    &__content {
      padding: 5px 0  5px 30.5px;
    }

    &.disabled {
      .step__icon {
        background: getColor(light-blue);
        color: getColor(default);
      }
    }
  }
}

.question {
  &__header {
    display: flex;
    margin-bottom: 10px;
    font-weight: $bold;
    line-height: 1.45;
  }

  &__count {
    flex-shrink: 0;
    margin-right: 15px;
  }

  &__label {
    margin-right: 10px;
    flex-grow: 1;
  }

  &__action {
    flex-shrink: 0;
    justify-self: flex-end;
    font-weight: $normal;
  }

  &__content {
    margin-left: 10px;
  }

  &.selected &__header > *:not(.question__action) {
    opacity: .5;
  }

  &.selected &__content > * {
    opacity: .5;
  }
}

.btn-outline {
  margin-left: 5px;
}

.btn-green {
  margin-right: 5px;
}
</style>
