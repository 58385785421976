<template>
  <div>
    <OnlineAgreementStepper :current-step="1" />

    <div class="container--sm text-center">
      <h2 class="mt-20 pt-20">
        Vyberte banku pro ověření vaší identity
      </h2>

      <Suspense>
        <BankList
          v-model="bank"
          @click="bankIdLogin"
        />

        <template #fallback>
          <div>
            <div class="flex flex-center mb-20 flip">
              <img
                :src="getIconUrl('icon-loader_big')"
                alt="Načítání..."
                class="spin"
              >
            </div>
            <div class="text-warning text-center text-bold">
              Chvíli strpení, načítáme seznam dostupných bank
            </div>
          </div>
        </template>
      </Suspense>
      <div class="container my-30">
        <hr>
      </div>

      <router-link
        :to="{ name: 'onlineAgreement.personalDataEntryMethodSelection' }"
        class="btn btn-big btn-icon-back btn-outline"
        dusk="step-back"
      >
        <LitIconSvg
          class="btn__icon btn__icon--big"
          icon-name="arrow_green-left"
        />
        <span>
          Zpět
        </span>
      </router-link>
    </div>

    <ContractSubmissionModal
      type="online"
      :model-value="redirecting"
      :is-bank-id="true"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

import BankList from '../../common/BankList.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import OnlineAgreementStepper from '@/js/components/Steppers/OnlineAgreementStepper.vue';

import { bankId, bankMetadata } from '@/js/api';
import ContractSubmissionModal from '@/js/components/Modals/ContractSubmissionModal.vue';
import { clearBankIdData, getIconUrl, isBankIdDataFilled } from '@/js/utils';
import { useOnlineAgreementStore } from '@/js/stores/online/agreement';
import { updateContract } from '@/js/stores/utils';

const store = useOnlineAgreementStore();
const { personalData } = storeToRefs(store);

const route = useRoute();

const contractDocumentsRequired = ref(false);
const newlyChosenBank = ref<string | null>(null);
const redirecting = ref(false);
const error = ref(false);

const bank = computed({
  get () {
    return personalData.value.verifiedBank.value;
  },
  set (value) {
    personalData.value.verifiedBank.value = value;
  },
});

async function getRedirectUri () {
  const { contractUuid } = route.params;

  const response = await bankMetadata.create(contractUuid, bank.value, 'verification');
  const { data } = await bankId.getUri(contractUuid, response.data.bankId);

  window.location.href = data.bankIdUri;
}

async function bankIdLogin () {
  const { contractUuid } = route.params;
  redirecting.value = true;

  try {
    error.value = false;
    contractDocumentsRequired.value = true;

    if (isBankIdDataFilled(store.$state)) {
      clearBankIdData(store.$state);

      await bankMetadata.update(contractUuid, 'canceled', 'verification');

      bank.value = newlyChosenBank.value;
    }

    await getRedirectUri();

    await updateContract({
      state: store.$state,
      contractUuid: contractUuid as string,
      fields: [],
      documentsSent: contractDocumentsRequired.value,
    });
  } catch (e: any) {
    error.value = true;
    redirecting.value = false;

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    redirecting.value = false;
  }
}

watch(bank, (newVal) => {
  newlyChosenBank.value = newVal;
});

onMounted(() => {
  if (bank.value !== null) {
    bank.value = null;
  }
});
</script>
