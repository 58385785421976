<script>
import { h } from 'vue';

import FUNDS from '../data/fundsData';
import LitTabs from './Base/Tabs/LitTabs.vue';
import LitTab from './Base/Tabs/LitTab.vue';
import LitModal from './Base/LitModal.vue';
import FundsChart from './FundsChart.vue';
import LifeCycleStrategyDetail from './LifeCycleStrategyDetail.vue';

export default {
  components: {
    LitTabs,
    LitTab,
    LitModal,
    FundsChart,
    LifeCycleStrategyDetail,
  },

  props: {
    totalPoints: {
      type: [Number, null],
      default: null,
      required: false,
    },

    modelValue: {
      type: [String, Number],
      required: false,
      default: null,
    },

    preferConservativeFund: {
      type: Boolean,
      required: false,
      default: false,
    },

    preferReinvestment: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
  ],

  data: () => ({
    showStrategyDetail: null,
  }),

  render () {
    const createTab = ({
      title,
      slug,
      content,
      label,
      labelType,
      labelIcon,
      dusk,
    }) => (
      h(
        LitTab,
        {
          title,
          label,
          'label-type': labelType,
          dusk,
          labelIcon,
          'data-slug': slug,
          'key': slug,
        },

        () => [
          h('p', [content.description]),
          h('button', {
            onClick: () => {
              this.showStrategyDetail = slug;
            },

            class: 'btn-simple',
          }, [content.detailLink]),
        ],
      ));

    const children = [];

    if (this.preferConservativeFund) {
      FUNDS.lifeCycle.label = null;
      FUNDS.lifeCycle.labelType = null;
      FUNDS.lifeCycle.labelIcon = null;
      FUNDS.reinvestment.label = null;
      FUNDS.reinvestment.labelType = null;
      FUNDS.reinvestment.labelIcon = null;
    } else if (this.preferReinvestment) {
      FUNDS.reinvestment.label = 'Doporučujeme';
      FUNDS.reinvestment.labelType = 'warning';
      FUNDS.reinvestment.labelIcon = 'tag-star_white';
      FUNDS.lifeCycle.label = null;
      FUNDS.lifeCycle.labelType = null;
      FUNDS.lifeCycle.labelIcon = null;
    } else {
      FUNDS.lifeCycle.label = 'Doporučujeme';
      FUNDS.lifeCycle.labelType = 'warning';
      FUNDS.lifeCycle.labelIcon = 'tag-star_white';
    }

    if (this.totalPoints <= 2) {
      children.push(createTab(FUNDS.conservative));
    } else if (this.totalPoints <= 8) {
      // Change order of the strategies when necessary (older than 65 y.o.)
      // @see https://gitlab.litea.cz/csob-penze/csob-penze-onboarding/-/issues/270
      if (this.preferConservativeFund) {
        children.push(createTab(FUNDS.preferredConservativeFund));
      }

      if (this.preferReinvestment) {
        children.push(createTab(FUNDS.reinvestment));
      } else {
        children.push(createTab(FUNDS.lifeCycle));
      }

      children.push(createTab(FUNDS.pension));

      if (!this.preferConservativeFund) {
        children.push(createTab(FUNDS.conservative));
      }
    } else if (this.totalPoints <= 14) {
      if (this.preferConservativeFund) {
        children.push(createTab(FUNDS.preferredConservativeFund));
      }

      if (this.preferReinvestment) {
        children.push(createTab(FUNDS.reinvestment));
      } else {
        children.push(createTab(FUNDS.lifeCycle));
      }

      children.push(createTab(FUNDS.balanced));
    } else if (this.totalPoints <= 18) {
      if (this.preferConservativeFund) {
        children.push(createTab(FUNDS.preferredConservativeFund));
      }

      if (this.preferReinvestment) {
        children.push(createTab(FUNDS.reinvestment));
      } else {
        children.push(createTab(FUNDS.lifeCycle));
      }

      if (!this.preferConservativeFund) {
        children.push(createTab(FUNDS.dynamic));
      }

      FUNDS.esg.label = '';
      FUNDS.esg.labelType = 'success';
      FUNDS.esg.labelIcon = 'icon_leaf-white';
      children.push(createTab(FUNDS.esg));
    }

    const content = [
      h(LitTabs, {
        'returnAttribute': 'data-slug',
        'modelValue': this.modelValue,

        'onUpdate:modelValue': (event) => {
          this.$emit('update:modelValue', event);
        },
      }, () => children),
    ];

    const fundDetailBody = [];

    if (this.showStrategyDetail === 'lifeCycle') {
      fundDetailBody.push(
        h(LifeCycleStrategyDetail),
      );
    } else if (this.showStrategyDetail === 'reinvestment') {
      fundDetailBody.push(
        h('p', {
          innerHTML: FUNDS[this.showStrategyDetail]?.content?.description,
        }),
      );
    } else {
      fundDetailBody.push(
        h(FundsChart, {
          fundType: this.showStrategyDetail,
          class: 'mb-30',
        }),

        h('p', {
          innerHTML: FUNDS[this.showStrategyDetail]?.content?.description,
        }),
      );
    }

    if (this.showStrategyDetail !== null) {
      content.push(h(
        LitModal,
        {
          'modelValue': this.showStrategyDetail !== null,
          'max-width': 838,

          'onUpdate:modelValue': (show) => {
            if (show === false) {
              this.showStrategyDetail = null;
            }
          },
        },
        {
          header: () => h('h3', {
            class: 'text-left',
            innerHTML: FUNDS[this.showStrategyDetail].title,
          }),

          body: () => fundDetailBody,

          footer: () => h('div', {
            class: 'modal__buttons flex-end',
          }, [
            h('button', {
              class: 'btn btn-primary btn-outline',
              dusk: 'close-modal',
              onClick: () => {
                this.showStrategyDetail = null;
              },
              innerHTML: 'Zavřít',
            }),
          ]),

        },
      ));
    }

    return h('div', content);
  },
};
</script>
