<template>
  <div>
    <section class="benefits bg-blue pt-30 pb-10" />

    <div dusk="signature-thank-you">
      <div class="container--sm center pt-30">
        <LitIconSvg icon-name="icon-redirect_to_litsign" />

        <h2>
          Děkujeme, pokračujte prosím na jiném zařízení
        </h2>

        <div class="center">
          <p class="my-20">
            Na e-mail <strong>{{ beforeStart.distributor.email.value }}</strong> jsme odeslali
            odkaz na aplikaci, kde podepíšete dodatek elektronicky.
          </p>

          <p class="mb-20">
            Podpis dodatku je možný i na mobilním zařízení.
          </p>

          <p v-if="draftTokenLocal.value !== null">
            Odkaz je platný do <strong>{{ contractDraftTokenValidity.label }}</strong>, využít jej můžete maximálně 3x.
          </p>

          <p>Pro návrat na rekapitulaci a následný podpis můžete také využít QR kód:</p>

          <div class="qr-code">
            <QrcodeVue
              :value="signingLink"
              :size="180"
              level="H"
            />
          </div>
        </div>

        <div class="container--sm my-30 flex flex-center">
          <LitButton
            variant="outline"
            :to="{ name: 'distribution.home' }"
            title="Přejít na hlavní rozcestník"
          >
            Přejít na hlavní rozcestník
          </LitButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns';
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import QrcodeVue from 'qrcode.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

import { resetState } from '@/js/stores/utils.ts';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import LitButton from '@/js/components/Base/LitButton.vue';

const route = useRoute();

const store = useChangeRequestStore();
const { draftToken, beforeStart } = storeToRefs(store);

const dateFormat = ref('dd. MM. yyyy');

const draftTokenLocal = ref<{ value: null | string, expiresAt: string | null }>({
  value: null,
  expiresAt: null,
});

const contractDraftTokenValidity = computed(() => {
  const validUntil = new Date(draftTokenLocal.value.expiresAt as string);

  return {
    value: format(validUntil, 'yyyy-MM-dd'),
    label: format(validUntil, dateFormat.value),
  };
});

const signingLink = computed(() => {
  const { contractUuid } = route.params;
  const protocolValues = ['http', 'https'];
  const currentLocation = window.location;

  const redirectUri = `${currentLocation.host}/zmenove-formulare/${contractUuid}/rekapitulace?draftToken=${draftTokenLocal.value.value}`;
  const protocolIncluded = protocolValues.some((value) => redirectUri.includes(value));

  return protocolIncluded ? redirectUri : `${currentLocation.protocol}//${redirectUri}`;
});

onBeforeRouteLeave((to, _from, next) => {
  resetState(store.$state, false);

  const disallowedPaths = [
    'changeRequest.recapitulation',
  ];

  if (to.name && disallowedPaths.includes(to.name as string)) {
    next({ name: 'distribution.home' });
  } else {
    next();
  }
});

onMounted(() => {
  draftTokenLocal.value = {
    value: draftToken.value.value.value,
    expiresAt: new Date(draftToken.value.value.expiresAt as string).toString(),
  };
});
</script>

<style scoped>
  .mt-10 {
    margin-top: 10px;
  }
</style>
