<template>
  <div dusk="thank-you">
    <div class="benefits bg-blue pt-30 pb-10">
      <div class="container--sm">
        <h2 class="dash">
          Hotovo, žádost podepsána
        </h2>
      </div>
    </div>

    <div class="container--sm my-30">
      <LitAlert alert-type="success">
        <h2 class="thanksAlert">
          Teď se koukněte do e-mailu. Poslali jsme vám tam žádost o výplatu dávky.
        </h2>
      </LitAlert>
    </div>

    <div class="container--sm mb-20">
      <div class="flex flex-center">
        <LitButton
          variant="outline"
          :href="getHomepageUrl()"
          as="a"
        >
          <span>Zpět na hlavní stranu</span>
        </LitButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitButton from '@/js/components/Base/LitButton.vue';

import { getHomepageUrl } from '@/js/utils';
import { destroy } from '@/js/api/contracts';

const route = useRoute();

onMounted (async () => {
  const { contractUuid } = route.params;
  await destroy(contractUuid as string);
});
</script>

<style lang="scss" scoped>
  .thanksAlert {
    margin: auto;
  }
</style>
