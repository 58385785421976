<template>
  <div class="body">
    <TheHeader
      v-if="headersVisible"
    />

    <main>
      <div class="bg-red py-30 mb-30">
        <div class="container--sm">
          <LitAlert alert-type="danger">
            <template #prepend>
              <i>
                <img
                  :src="getIconUrl('icon-state_error-solid')"
                  alt="Ikona výstrahy"
                >
              </i>
            </template>
            <h2>{{ title }}</h2>
            {{ body }}
          </LitAlert>
        </div>
      </div>

      <div
        v-if="contactVisible"
        class="container--sm"
      >
        <div class="mb-30">
          Nebojte, není to vaše chyba. Velmi nás to mrzí a na nápravě usilovně
          pracujeme. Zkuste to znovu za chvíli nebo nás kontaktujte
          <a href="https://www.csob-penze.cz/pobocky/">
            osobně na pobočce
          </a> či na telefonu
          <a href="tel:+420495800600">
            495 800 600.
          </a>
        </div>
        <div>
          Případně můžete přejít <a :href="homeRedirectPath">
            na úvodní stránku našeho webu csob-penze.cz
          </a>.
        </div>
      </div>
    </main>

    <TheFooter
      v-if="headersVisible"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { convertStringToBoolean, getHomepageUrl, getIconUrl } from '../utils';

import LitAlert from '../components/Base/LitAlert.vue';
import TheHeader from './TheHeader.vue';
import TheFooter from './TheFooter.vue';

const homeRedirectPath = getHomepageUrl();
const route = useRoute();
const routeQuery = route.query;

const headersVisible = computed(() => {
  const query = routeQuery.headersVisible;

  if (query) {
    return convertStringToBoolean(query as string);
  }

  return true;
});

const title = computed(() => {
  const query = routeQuery.title;

  if (query) {
    return query;
  } else {
    return 'Oops, něco se pokazilo';
  }
});

const body = computed(() => {
  const query = routeQuery.body;

  if (query) {
    return query;
  } else {
    return 'Omlouváme se, bohužel se něco pokazilo na naší straně a vaši žádost nemůžeme dokončit.';
  }
});

const contactVisible = computed(() => {
  const query = routeQuery.contactVisible;

  if (query) {
    return convertStringToBoolean(query as string);
  } else {
    return true;
  }
});
</script>

<style lang="scss">
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';

.bg-red {
  background-color: getColor(bg-red);
}
</style>
