<template>
  <div dusk="contact-information">
    <JpsStepper current-step="contactInformation" />

    <div class="container--sm my-20">
      <h2>Kontaktní údaje podepisující osoby...</h2>

      <form
        dusk="contact-form"
        @submit.prevent="submitStep"
      >
        <LitAlert
          class="mb-20 mt-20"
        >
          Zřízení smlouvy převodem od jiné PS s využitím Bank ID umožňujeme účastníkovi spoření.
          Pokud je žadatelem zákonný zástupce či opatrovník,
          založte samostatně novou smlouvu k DPS. Pro výpověď s žádostí o převod stávající smlouvy
          k jiné PS využijte <a href="https://www.csob-penze.cz/dokumenty/formulare" target="_blank">
            formulář
          </a> na
          webu pro distributory.
        </LitAlert>

        <ContactInformation
          store-path="contactInformation"
          namespace="jps"
          :signer-types="signerTypes"
          :signers-visible="false"
          :validate-fnc="validateField"
        />
      </form>
    </div>

    <div class="container my-30">
      <hr>
    </div>

    <StepButtons
      namespace="jps"
      previous-step="salesInterview"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, shallowRef } from 'vue';

import { useRoute, useRouter } from 'vue-router';
import ContactInformation from '../../common/ContactInformation.vue';
import StepButtons from '../../common/StepButtons.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import JpsStepper from '@/js/components/Steppers/JpsStepper.vue';
import { contactInformationFields } from '@/js/data/validationFields/commonFields';

import gtm from '@/js/services/gtm';
import { scrollToError } from '@/js/utils';
import { useJpsStore } from '@/js/stores';

const validating = ref(false);
const contractDocumentsRequired = ref(false);

const signerTypes = shallowRef([
  { value: 'participant', label: 'účastníka' },
]);

const route = useRoute();
const router = useRouter();

const store = useJpsStore();
const { validateField, validateFields } = store;

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;
    contractDocumentsRequired.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        ...contactInformationFields,
        'contactInformation.phonePrefix',
        'signer.document.type',
      ],
      documentsSent: contractDocumentsRequired.value,
    });

    validating.value = false;

    await router.push({
      name: 'jps.contractDocumentsAndConsents',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('kontaktni-udaje');
  } catch (e) {
    validating.value = false;
    contractDocumentsRequired.value = false;

    scrollToError();

    console.warn(`There was a validation personalDataError: ${e}`);
  }
}
</script>
