<template>
  <div class="full-height">
    <TheHeader>
      <template #heading>
        <h1><strong>Distribuce</strong></h1>
      </template>

      <template #header_contact_phone>
        <a
          href="tel:+420224116655"
        >
          <img
            :src="getIconUrl('phone')"
            alt="Icon tel."
          ><span>224 116 655</span>
        </a>
      </template>
    </TheHeader>

    <main role="main">
      <div class="benefits bg-blue pt-30 pb-10">
        <div class="container--sm" />
      </div>
      <div class="container--md">
        <h2 class="center">
          Vyberte společnost, ve které pracujete
        </h2>

        <div class="distribution-list">
          <div
            v-for="(bank, index) in distributions"
            :key="index"
            class="distribution-list__tile"
          >
            <LogoTile
              v-model="bank.name"
              :dusk="bank.name"
              :icon-name="bank.name"
              :name="bank.name"
              @click="setDistribution(bank.name)"
            />
          </div>
        </div>
      </div>
    </main>

    <TheFooter
      :display-link="false"
    />
  </div>
</template>

<script lang="ts">
import { computed, onMounted, ref, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TheFooter from './TheFooter.vue';
import TheHeader from './TheHeader.vue';
import { getIconUrl, getPersistedData, persistData } from '@/js/utils';

import { useGeneralStore } from '@/js/stores';
import LogoTile from '@/js/components/LogoTile.vue';

export default {
  components: {
    LogoTile,
    TheFooter,
    TheHeader,
  },

  async beforeRouteEnter (from, _to, next) {
    if (from.params.cp) {
      const isTiedAgent = {
        isTiedAgent: true,
        distribution: 'cp',
      };

      const generalStoreData = getPersistedData('distribution');

      if (generalStoreData === null) {
        persistData(isTiedAgent, 'distribution');
      }

      next((vm) => {
        const store = useGeneralStore();

        store.isTiedAgent.value = true;
        store.distribution.value = 'cp';

        vm.$router.push({
          name: 'distribution.guideTiles',
        });
      });
    } else {
      next();
    }
  },

  setup () {
    const store = useGeneralStore();
    const router = useRouter();
    const route = useRoute();

    const distributions = shallowRef([
      { name: 'csob-banka' },
      { name: 'csob-ss' },
      { name: 'csob-poj' },
      { name: 'cp' },
      { name: 'others' },
    ]);

    const tiedAgents = computed(() => {
      return ['cp'];
    });

    const query = ref();

    async function setDistribution (distribution: string) {
      store.distribution.value = distribution;
      store.isTiedAgent.value = tiedAgents.value.includes(distribution);

      const isTiedAgent = {
        isTiedAgent: tiedAgents.value.includes(distribution),
        distribution,
      };

      const generalStoreData = getPersistedData('distribution');

      if (generalStoreData === null) {
        persistData(isTiedAgent, 'distribution');
      }

      await router.push({
        name: 'distribution.guideTiles',
        query: { ...query.value },
      });
    }

    onMounted(async () => {
      if (route.query?.bankIdSign === 'failed' || route.query?.bankIdSign === 'rejected') {
        query.value = route.query;

        await router.push({
          name: 'distribution.guideTiles',
          query: { ...query.value },
        });
      }

      const generalStoreData = getPersistedData('distribution');

      if (generalStoreData !== null) {
        const data = JSON.parse(generalStoreData);

        await setDistribution(data.distribution);
      }
    });

    return {
      store,
      distributions,
      query,
      setDistribution,
      getIconUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

.distribution-list {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 16px;
  padding-bottom: 20px;
  justify-content: center;

  > div {
    display: flex;
    justify-content: center;
  }

  @include media(min, $sm) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include media-between($xs, $sm) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
