<template>
  <div>
    <LitModal
      :model-value="modelValue"
      :max-width="480"
      @click="$emit('close', $event)"
    >
      <template #header>
        <h3>
          Strategie penzijního spoření
        </h3>
      </template>

      <template #body>
        <p>{{ description }}</p>
      </template>

      <template #footer>
        <div class="modal__buttons flex-center">
          <LitButton
            dusk="close-modal"
            variant="outline"
            @click="$emit('close', $event)"
          >
            <span>Zavřít</span>
          </LitButton>
        </div>
      </template>
    </LitModal>
  </div>
</template>

<script setup lang="ts">
import { computed, shallowRef } from 'vue';
import LitModal from '../Base/LitModal.vue';
import LitButton from '../Base/LitButton.vue';

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  formType: 'distribution',
});

defineEmits([
  'close',
]);

const DISTRIBUTION_TEXT = 'Penzijní spoření úspory klienta zhodnocuje tak, '
  + 'že je investuje do účastnických fondů. Klient si může vybrat z 5 fondů '
  + 'nebo využít služby Strategie životního cyklu, která sama mění spořicí '
  + 'strategii podle aktuálního věku klienta.';

const ONLINE_TEXT = 'Penzijní spoření vaše úspory zhodnocuje tak, '
  + 'že je investuje do účastnických fondů. Můžete si vybrat z 5 fondů '
  + 'nebo využít služby Strategie životního cyklu, '
  + 'která sama mění spořicí strategii podle vašeho aktuálního věku.';

interface FormType {
  distribution: string
  online: string
}

interface Props {
  modelValue: boolean
  formType: keyof FormType
}

const text = shallowRef({
  distribution: DISTRIBUTION_TEXT,
  online: ONLINE_TEXT,
});

const description = computed(() => {
  return text.value[props.formType];
});
</script>
