<template>
  <div dusk="personal-data">
    <TerminationStepper current-step="personalData" />

    <div class="container--sm mt-20">
      <form
        method="post"
        @submit.prevent="submitStep"
      >
        <ClientsPersonalData
          store-path="personalData"
          namespace="termination"
          title="Osobní údaje osoby, pro kterou je smlouva ukončována"
          :validate-fnc="validateField"
        />

        <div dusk="legal-legislative-data">
          <h3>Legislativní údaje</h3>

          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="termination"
            field-path="personalData.isPoliticallyExposed"
            :validate-on-input="true"
            :debounce="0"
          >
            <LitRadio
              class="mb-20"
              tooltip="Politicky exponovaná osoba je osoba ve významné veřejné funkci
              nebo fyzická osoba s takovou osobou příbuzná či v partnerském vztahu."
              dusk="politically-exposed"
              name="politicallyExposedPerson"
              label="Je klient politicky exponovanou osobou?"
              :show-inline="true"
              :options="politicallyExposedOptions"
              :model-value="value"
              :error="errors.length > 0 ? errors[0] : ''"
              @update:model-value="input"
            />
          </ServerValidatedFormField>
        </div>

        <hr
          v-if="!isParticipant"
          class="my-30"
        >

        <SignerPersonalData
          store-path="signer"
          namespace="termination"
          copy-address-source="personalData"
          copy-names-source="contactInformation"
          :validate-fnc="validateField"
        />
      </form>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <StepButtons
      namespace="termination"
      dusk="submit-step"
      previous-step="contactInformation"
      :fields="fields"
      @submit="submitStep"
    />
  </div>
</template>

<script lang="ts" setup>
import { shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import ClientsPersonalData from '../../common/ClientsPersonalData.vue';
import SignerPersonalData from '../../common/SignerPersonalData.vue';
import StepButtons from '../../common/StepButtons.vue';
import TerminationStepper from '@/js/components/Steppers/TerminationStepper.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';

import gtm from '@/js/services/gtm';

import { scrollToError } from '@/js/utils';
import { useTerminationStore } from '@/js/stores';
import { clientsPersonalDataFields } from '@/js/data/validationFields/commonFields';
import { documentInfoFields, signerFields } from '@/js/data/validationFields/distribution';

const route = useRoute();
const router = useRouter();

const store = useTerminationStore();
const { validateField, validateFields } = store;
const { isParticipant, personalData, signer } = storeToRefs(store);

const fields = shallowRef([
  'personalData.birthDate',
  'personalData.gender',
  'personalData.permanentAddressArea',
  'personalData.isPoliticallyExposed',
  ...clientsPersonalDataFields,
  ...documentInfoFields,
  ...signerFields,
]);

const politicallyExposedOptions = shallowRef([
  { value: false, label: 'Ne, není' },
  { value: true, label: 'Ano, je' },
]);

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    await router.push({
      name: 'termination.paymentOption',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zadani-osobnich-udaju');
  } catch (e) {
    personalData.value.permanentAddress.setManually.value = true;

    if (!isParticipant.value && typeof signer.value.permanentAddress !== 'undefined') {
      signer.value.permanentAddress.setManually.value = true;
    }

    scrollToError();

    console.warn(`There was a validation error: ${e}`);
  }
}
</script>
