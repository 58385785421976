<template>
  <div dusk="contract-settings">
    <DistributionStepper current-step="contractSettings" />

    <div class="container--sm mt-20">
      <div
        class="mb-40"
        dusk="contract-parameters"
      >
        <h2>Nastavení parametrů penzijního spoření</h2>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="distribution"
          field-path="contractSettings.contractSignaturePlace"
          :debounce="0"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            label="Místo podepsání smlouvy"
            name="contractSignPlace"
            dusk="contract-sign-place"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input }"
          namespace="distribution"
          field-path="contractSettings.taxOptimization"
          :debounce="0"
          @validate="validateField"
        >
          <LitCheckbox
            class="mb-20 mt-20"
            dusk="tax-optimization"
            tooltip-position="right"
            tooltip="Program daňové optimalizace zajistí, že finanční prostředky
            zaplacené nad sjednaný měsíční příspěvek budou použity
            na maximální možné snížení základu daně. Více informací <a
            href='https://csob-penze.cz/faq/jak-funguje-program-danove-optimalizace/'
            target='_blank'>
            jak funguje Program daňové optimalizace.
            </a>"
            :show-tooltip="true"
            :model-value="value"
            @update:model-value="input"
          >
            Klient chce využít Program daňové optimalizace
          </LitCheckbox>
        </ServerValidatedFormField>

        <div class="pt-20">
          <ServerValidatedFormField
            v-slot="{ value, input }"
            namespace="distribution"
            field-path="contractSettings.contributionFromEmployer"
            :debounce="0"
            @validate="validateField"
          >
            <LitCheckbox
              class="mb-20"
              dusk="employer-contribution"
              tooltip="Odškrtnutím/odznačením je možné pokračovat
              bez vyplnění příspěvku zaměstnavatele."
              :show-tooltip="true"
              :model-value="value"
              @update:model-value="input"
            >
              <span class="checkbox__heading">
                Na penzijní spoření bude klientovi přispívat i jeho zaměstnavatel
              </span>
            </LitCheckbox>
          </ServerValidatedFormField>

          <div v-if="contributionFromEmployer">
            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate }"
              namespace="distribution"
              field-path="contractSettings.employerInfo.name"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                dusk="employer-name"
                name="nameOfEmployer"
                label="Název zaměstnavatele"
                placeholder="Např. AAA Auto, a.s."
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @blur="validate($event.target.value)"
              />
            </ServerValidatedFormField>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate }"
              namespace="distribution"
              field-path="contractSettings.employerInfo.vat"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                name="employerId"
                dusk="employer-id"
                label="IČ zaměstnavatele"
                placeholder="Např. 95874375"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @blur="validate($event.target.value)"
              >
                <template #actions>
                  <div class="actions-group">
                    <a
                      href="https://ares.gov.cz/ekonomicke-subjekty"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vyhledat IČO v obchodním rejstříku
                    </a>
                  </div>
                </template>
              </LitInput>
            </ServerValidatedFormField>
          </div>
        </div>
      </div>

      <div dusk="nominees">
        <h2>Určené osoby</h2>

        <LitAlert
          class="mb-20"
          dusk="nominees-alert"
        >
          Pokud klient do své smlouvy uvede určené osoby, budou jim v případě
          klientova úmrtí naspořené peníze vyplaceny bez dědického řízení.
          Určené osoby může klient kdykoliv změnit nebo doplnit.
        </LitAlert>

        <ServerValidatedFormField
          v-slot="{ value, input }"
          namespace="distribution"
          field-path="contractSettings.specifyNominees"
          :debounce="0"
          @validate="validateField"
        >
          <LitCheckbox
            class="mb-20"
            dusk="want-nominees"
            :model-value="value"
            @update:model-value="input"
          >
            Klient chce uvést určenou osobu
          </LitCheckbox>
        </ServerValidatedFormField>

        <div
          v-if="specifyNominees"
        >
          <div
            v-for="(person, index) in nominees"
            :key="index"
            class="nominee mb-30"
          >
            <div class="pl-34">
              <h3 class="nominee__title">
                Určená osoba č. {{ index + 1 }}

                <button
                  v-if="nominees.length > 1"
                  class="btn-simple btn-simple--red"
                  :dusk="`remove-nominee-${index}`"
                  @click="removeNominee(index)"
                >
                  <i>
                    <img
                      :src="getIconUrl('icon-delete')"
                      alt="Ikona smazat"
                    >
                  </i>

                  <span>
                    Odstranit osobu č. {{ index + 1 }}
                  </span>
                </button>
              </h3>

              <ServerValidatedFormField
                v-slot="{ input, value, errors, validate }"
                namespace="distribution"
                :field-path="`contractSettings.nominees.${index}.firstName`"
                :debounce="0"
                @validate="validateField"
              >
                <LitInput
                  class="mb-20"
                  label="Jméno"
                  placeholder="Např. Karel"
                  :name="`firstName${index}`"
                  :model-value="value"
                  :errors="errors"
                  @update:model-value="input"
                  @blur="validate(
                    $event.target.value,
                    'contractSettings.nominees.*.firstName'
                  )"
                />
              </ServerValidatedFormField>

              <ServerValidatedFormField
                v-slot="{ value, errors, input, validate }"
                namespace="distribution"
                :field-path="`contractSettings.nominees.${index}.lastName`"
                :debounce="0"
                @validate="validateField"
              >
                <LitInput
                  class="mb-20"
                  label="Příjmení"
                  placeholder="Např. Novotný"
                  :name="`lastName${index}`"
                  :model-value="value"
                  :errors="errors"
                  @update:model-value="input"
                  @blur="validate(
                    $event.target.value,
                    'contractSettings.nominees.*.lastName'
                  )"
                />
              </ServerValidatedFormField>

              <div v-if="person.usePersonalId.value === false">
                <ServerValidatedFormField
                  v-slot="{ value, errors, input }"
                  namespace="distribution"
                  :validate-on-input="true"
                  :field-path="`contractSettings.nominees.${index}.birthdate`"
                  :debounce="0"
                  @validate="validateField"
                >
                  <DatePicker
                    class="mb-20"
                    label="Datum narození"
                    :name="`bday${index}`"
                    :model-value="value"
                    :errors="errors"
                    @update:model-value="input"
                  >
                    <template #actions>
                      <div class="actions-group">
                        <button
                          class="btn-simple"
                          @click="updateNomineeField(index, 'usePersonalId', true)"
                        >
                          Místo data a státu narození zadat rodné číslo
                        </button>
                      </div>
                    </template>
                  </DatePicker>
                </ServerValidatedFormField>

                <ServerValidatedFormField
                  v-slot="{ value, errors, input }"
                  namespace="distribution"
                  :debounce="0"
                  :validate-on-input="true"
                  :field-path="`contractSettings.nominees.${index}.countryOfBirth`"
                  @validate="validateField"
                >
                  <LitSelect
                    class="mb-20"
                    label="Stát narození"
                    :options="countryOfBirthOptions"
                    :model-value="value"
                    :error="errors.length > 0 ? errors[0] : ''"
                    @update:model-value="input"
                  />
                </ServerValidatedFormField>
              </div>

              <ServerValidatedFormField
                v-else
                v-slot="{ value, errors, input, validate }"
                namespace="distribution"
                :field-path="`contractSettings.nominees.${index}.personalId`"
                :debounce="0"
                @validate="validateField"
              >
                <LitInput
                  class="mb-20"
                  label="Rodné číslo"
                  placeholder="Např. 816008/0610"
                  :name="`personalId${index}`"
                  :model-value="value"
                  :errors="errors"
                  @update:model-value="input"
                  @blur="validate(
                    $event.target.value,
                    'contractSettings.nominees.*.personalId'
                  )"
                  @keydown.space.prevent
                >
                  <template #actions>
                    <div class="actions-group">
                      <button
                        class="btn-simple"
                        :dusk="`nominee${index}.useBirthDate`"
                        @click="updateNomineeField(index, 'usePersonalId', false)"
                      >
                        Místo RČ zadat datum a stát narození
                      </button>
                    </div>
                  </template>
                </LitInput>
              </ServerValidatedFormField>

              <AddressEntry
                namespace="distribution"
                label="Trvalá adresa"
                tooltip="Postupným psaním názvu ulice, čísla popisného
                  a PSČ si můžete ze seznamu vybrat potřebnou adresu."
                placeholder="Např. Na příkopech 12, Praha 1"
                :store-path="`contractSettings.nominees.${index}.permanentAddress`"
                :dusk="`permanent-address-${index}`"
                :name="`permanentAddress${index}`"
                :same-address="true"
                :query="person.permanentAddress.query.value"
                :editable="person.permanentAddress.editable.value"
                :allow-autocomplete="isClientFromCzechia"
                :validate-fnc="validateField"
                @update:query="updateNomineeField(
                  index,
                  'permanentAddress.query',
                  $event
                )"
                @update:editable="updateNomineeField(
                  index,
                  'permanentAddress.editable',
                  $event
                )"
                @place-detail-loaded="updateNomineesPermanentAddress(index, $event)"
                @set-address-manually="updateNomineeField(
                  index,
                  'permanentAddress.setManually',
                  true
                )"
                @input="debouncedUpdateNomineeField(index, 'permanentAddress', $event)"
              >
                <template #autocomplete-actions>
                  <button
                    class="btn-simple"
                    type="button"
                    :dusk="`same-address-as-clients-${index}`"
                    @click="useAddressFromPersonalData(index)"
                  >
                    Shodná s adresou účastníka smlouvy
                  </button>
                </template>
              </AddressEntry>
            </div>
          </div>

          <div
            v-if="nominees.length === 1"
            class="custom-field part-full mb-30 pl-34"
          >
            <label>
              Podíl
            </label>

            <div class="control">
              Tato určená osoba <strong>dostane 100 % naspořených prostředků</strong>
            </div>
          </div>

          <button
            v-if="nominees.length < 2"
            class="btn-add mb-30"
            dusk="add-nominee"
            @click="addNominee"
          >
            <i>
              <img
                :src="getIconUrl('btn-plus')"
                alt="Ikona plus"
              >
            </i>

            <span>
              Přidat další určenou osobu
            </span>
          </button>

          <div
            v-if="nominees.length > 1"
            class="pl-34"
          >
            <h3>Rozdělení naspořené částky mezi určené osoby</h3>

            <div class="part-share">
              <div
                v-for="(person, index) in nominees"
                :key="index"
                class="part-share__item"
              >
                <div class="part-share__header mb-10">
                  <div class="part-share__title">
                    {{ person.firstName.value }} {{ person.lastName.value }}
                  </div>

                  <div class="part-share__subtitle">
                    (Určená osoba č. {{ index + 1 }})
                  </div>
                </div>

                <ServerValidatedFormField
                  v-slot="{ value, errors }"
                  namespace="distribution"
                  :field-path="`contractSettings.nominees.${index}.share`"
                  @validate="validateField"
                >
                  <LitInput
                    class-name="control--xs"
                    type="number"
                    :min="1"
                    :max="99"
                    :show-append="true"
                    :model-value="value"
                    :errors="errors"
                    @update:model-value="calculateShare(index, $event)"
                  >
                    <template #append>
                      <span class="unit">
                        %
                      </span>
                    </template>

                    <template #number-actions>
                      <div class="number-actions">
                        <button
                          class="number-actions__btn"
                          @click="incrementShare(index)"
                        >
                          +
                        </button>

                        <button
                          class="number-actions__btn"
                          @click="decrementShare(index)"
                        >
                          -
                        </button>
                      </div>
                    </template>
                  </LitInput>
                </ServerValidatedFormField>
              </div>

              <RangeSlider
                v-model.number="nomineesShare"
                min="1"
                max="99"
                class="part-share__range"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-30">
        <hr>
      </div>
    </div>

    <StepButtons
      namespace="distribution"
      previous-step="contributionAndStrategy"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { cloneDeep, set } from 'lodash';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import { useDebounceFn } from '@vueuse/core';
import AddressEntry from '@/js/components/AddressEntry.vue';
import DatePicker from '@/js/components/DatePicker.vue';
import DistributionStepper from '@/js/components/Steppers/DistributionStepper.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import RangeSlider from '@/js/components/RangeSlider.vue';

import gtm from '@/js/services/gtm';
import { clamp, getIconUrl, scrollToError } from '@/js/utils';
import { countriesOfBirth as countryOfBirthOptions } from '@/js/data/countryOfBirthOptions';
import { addressField } from '@/js/stores/createStoreFields';
import { useStoreField } from '@/js/composables/useStoreField';
import { useDistributionStore } from '@/js/stores/distibution/newContract';
import type { Address, Nominee } from '@/js/stores/types';
import { copyAddress } from '@/js/stores/utils';
import StepButtons from '@/js/views/common/StepButtons.vue';

function createNominee (): Nominee {
  return {
    firstName: useStoreField(''),
    lastName: useStoreField(''),
    personalId: useStoreField(''),
    usePersonalId: useStoreField(true),
    countryOfBirth: useStoreField('cz'),
    birthdate: useStoreField(''),
    share: useStoreField(100),
    permanentAddress: addressField(),
  };
}

const getNomineeFields = (index: number) => [
  {
    path: `contractSettings.nominees.${index}.firstName`,
    pathToValidate: 'contractSettings.nominees.*.firstName',
  },
  {
    path: `contractSettings.nominees.${index}.lastName`,
    pathToValidate: 'contractSettings.nominees.*.lastName',
  },
  {
    path: `contractSettings.nominees.${index}.personalId`,
    pathToValidate: 'contractSettings.nominees.*.personalId',
  },
  {
    path: `contractSettings.nominees.${index}.usePersonalId`,
    pathToValidate: 'contractSettings.nominees.*.usePersonalId',
  },
  {
    path: `contractSettings.nominees.${index}.countryOfBirth`,
    pathToValidate: 'contractSettings.nominees.*.countryOfBirth',
  },
  {
    path: `contractSettings.nominees.${index}.birthdate`,
    pathToValidate: 'contractSettings.nominees.*.birthdate',
  },
  {
    path: `contractSettings.nominees.${index}.share`,
    pathToValidate: 'contractSettings.nominees.*.share',
  },
  {
    path: `contractSettings.nominees.${index}.permanentAddress.street`,
    pathToValidate: 'contractSettings.nominees.*.permanentAddress.street',
  },
  {
    path: `contractSettings.nominees.${index}.permanentAddress.streetNumber`,
    pathToValidate: 'contractSettings.nominees.*.permanentAddress.streetNumber',
  },
  {
    path: `contractSettings.nominees.${index}.permanentAddress.zip`,
    pathToValidate: 'contractSettings.nominees.*.permanentAddress.zip',
  },
  {
    path: `contractSettings.nominees.${index}.permanentAddress.city`,
    pathToValidate: 'contractSettings.nominees.*.permanentAddress.city',
  },
  {
    path: `contractSettings.nominees.${index}.permanentAddress.country`,
    pathToValidate: 'contractSettings.nominees.*.permanentAddress.country',
  },
];

const MAX_NOMINEE_SHARE = 100;

const route = useRoute();
const router = useRouter();

const store = useDistributionStore();
const { isClientFromCzechia, contractSettings, beforeStart } = storeToRefs(store);
const { validateFields, validateField } = store;

const nomineesShare = computed({
  get () {
    return contractSettings.value.nomineesShare.value;
  },
  set (value) {
    contractSettings.value.nomineesShare.value = value;
  },
});

const nominees = computed({
  get () {
    return contractSettings.value.nominees;
  },
  set (value) {
    contractSettings.value.nominees = value;
  },
});

const specifyNominees = computed(() => {
  return contractSettings.value.specifyNominees.value;
});

const fields = computed(() => {
  const result = [
    { path: 'contractSettings.contractSignaturePlace' },

    { path: 'contractSettings.taxOptimization' },

    { path: 'contractSettings.contributionFromEmployer' },
    { path: 'contractSettings.employerInfo.name' },
    { path: 'contractSettings.employerInfo.vat' },
  ];

  if (specifyNominees.value) {
    nominees.value.forEach((_nominee, index) => {
      result.push(...getNomineeFields(index));
    });
  }

  return result;
});

const contributionFromEmployer = computed(() => {
  return contractSettings.value.contributionFromEmployer.value;
});

const validating = ref(false);
async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    // in case of termination/transfer with new contract it sometimes happens
    // that the share is not set acc. to the default value and null is set instead
    // therefore we make this check and setting to avoid errors until the real reason
    // of this behaviour is found
    if (nominees.value.length === 1 && nominees.value[0].share.value !== 100) {
      setShare(0, 100);
    }

    if (nominees.value.length !== 0) {
      nominees.value.forEach((nominee, index) => {
        if (nominee.permanentAddress.query.value === null || nominee.permanentAddress.query.value === '') {
          const { permanentAddress } = nominee;

          contractSettings.value.nominees[index].permanentAddress.query.value
          = `${permanentAddress.street.value} ${permanentAddress.streetNumber.value}, ${permanentAddress.zip.value} ${permanentAddress.city.value}`;
        }
      });
    }

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fields: fields.value,
    });

    validating.value = false;

    await router.push({
      params: {
        contractUuid,
      },
      name: 'distribution.clientsStatement',
    });

    gtm.onStepSubmit('smlouva-nastaveni-parameteru');
  } catch (e) {
    validating.value = false;

    nominees.value.forEach((_nominee, index) => {
      updateNomineeField(index, 'permanentAddress.setManually', true);
    });

    scrollToError();

    console.error(`There was a validation ClientsStatementError: ${e}`);
  }
}

function addNominee () {
  if ([...nominees.value].length >= 2) {
    return;
  }

  nominees.value = [
    ...nominees.value,
    createNominee(),
  ];

  nomineesShare.value = MAX_NOMINEE_SHARE / nominees.value.length;
}

const debouncedUpdateNomineeField = useDebounceFn((index: number, fieldPath: string, value: unknown) => {
  updateNomineeField(index, fieldPath, value);
}, 300);

function updateNomineeField (index: number, fieldPath: string, value: unknown) {
  if (index >= nominees.value.length) {
    return;
  }

  const updatedNominee = cloneDeep(nominees.value[index]);

  set(updatedNominee, `${fieldPath}.value`, value);

  nominees.value = [
    ...nominees.value.slice(0, index),
    updatedNominee,
    ...nominees.value.slice(index + 1),
  ];
}

function removeNominee (index: number) {
  nominees.value = [
    ...nominees.value.slice(0, index),
    ...nominees.value.slice(index + 1),
  ];

  nomineesShare.value = MAX_NOMINEE_SHARE / nominees.value.length;
}

function updateNomineesPermanentAddress (nomineeIndex: number, placeDetail: Address) {
  updateAddress(
    `contractSettings.nominees.${nomineeIndex}`,
    'permanentAddress',
    placeDetail,
  );
}

function updateAddress (prefix: string, name: 'permanentAddress' | 'mailingAddress' | 'contactAddress', {
  city,
  street,
  streetNumber,
  zip,
  countryCode,
}: Address) {
  store.$patch((state) => {
    // @ts-expect-error: TODO: improve this
    state[prefix][name].street.value = street;
    // @ts-expect-error: TODO: improve this
    state[prefix][name].streetNumber.value = streetNumber;
    // @ts-expect-error: TODO: improve this
    state[prefix][name].city.value = city;
    // @ts-expect-error: TODO: improve this
    state[prefix][name].zip.value = zip;
    // @ts-expect-error: TODO: improve this
    state[prefix][name].country.value = countryCode;
  });
}

function setShare (nomineeIndex: number, newShare: number) {
  updateNomineeField(nomineeIndex, 'share', newShare);
}

function calculateShare (nomineeIndex: number, share: number) {
  const clampedShare = clamp(0, MAX_NOMINEE_SHARE, share);

  if (nomineeIndex === 0) {
    nomineesShare.value = Number.parseFloat(clampedShare.toString());
  } else if (nomineeIndex === 1) {
    nomineesShare.value = MAX_NOMINEE_SHARE - Number.parseFloat(clampedShare.toString());
  } else {
    throw new Error('There should be only two nominees');
  }
}

function setSignaturePlace () {
  if (typeof beforeStart.value.meetingAddress !== 'undefined') {
    contractSettings.value.contractSignaturePlace.value = beforeStart.value.meetingAddress.city.value;
  }
}

function incrementShare (index: number, val = 1) {
  calculateShare(index, ([...nominees.value][index].share.value || 0) + val);
}

function decrementShare (index: number, val = 1) {
  calculateShare(index, ([...nominees.value][index].share.value || 0) - val);
}

function useAddressFromPersonalData (index: number) {
  copyAddress(store.$state, {
    destination: `contractSettings.nominees.${index}.permanentAddress`,
    source: 'personalData.permanentAddress',
  });

  store.$patch((state) => {
    if (state.personalData.permanentAddress.query.value === '') {
      state.contractSettings.nominees[index].permanentAddress.setManually.value = true;
    }

    if (isClientFromCzechia.value) {
      state.contractSettings.nominees[index].permanentAddress.editable.value = false;
    } else {
      state.contractSettings.nominees[index].permanentAddress.editable.value = true;
      state.contractSettings.nominees[index].permanentAddress.setManually.value = true;
    }

    state.contractSettings.nominees[index].permanentAddress.query.value = state.personalData.permanentAddress.query.value;
    state.contractSettings.nominees[index].permanentAddress.setManually.value = state.personalData.permanentAddress.setManually.value;
    state.contractSettings.nominees[index].permanentAddress.city.value = state.personalData.permanentAddress.city.value;
    state.contractSettings.nominees[index].permanentAddress.zip.value = state.personalData.permanentAddress.zip.value;
    state.contractSettings.nominees[index].permanentAddress.street.value = state.personalData.permanentAddress.street.value;
    state.contractSettings.nominees[index].permanentAddress.streetNumber.value = state.personalData.permanentAddress.streetNumber.value;
    state.contractSettings.nominees[index].permanentAddress.country.value = state.personalData.permanentAddress.country.value;
  });
}

watch(specifyNominees, (newValue) => {
  if (newValue && nominees.value.length === 0) {
    addNominee();
  } else if (!newValue) {
    nominees.value = [];
  }
});

watch(nominees, (newValue) => {
  if (newValue.length === 1 && newValue[0].share?.value === null) {
    contractSettings.value.nominees[0].share.value = 100;
  } else if (newValue.length === 2
  && (newValue[0].share?.value === null || newValue[1].share?.value === null)
  ) {
    contractSettings.value.nominees[0].share.value = 50;
    contractSettings.value.nominees[1].share.value = 50;
  }
}, {
  deep: true,
});

watch(nomineesShare, (newShare) => {
  setShare(0, newShare);
  setShare(1, MAX_NOMINEE_SHARE - newShare);
});

onMounted(() => {
  if (beforeStart.value.isTiedAgent.value === true && (contractSettings.value.contractSignaturePlace.value === null || contractSettings.value.contractSignaturePlace.value === '')) {
    setSignaturePlace();
  }
});
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.part-share {
  position: relative;

  @include media(min, $sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    flex: 1;

    &:nth-child(2) {
      @include media(min, $sm) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    /* stylelint-disable */
    &:nth-child(2) :deep(.field) {
      @include media(min, $sm) {
        justify-content: flex-end;

        .control__wrapper {
          flex-basis: auto;
        }
      }
    }

    /* stylelint-enable */

    @include media(max, $sm) {
      margin-bottom: 20px;
    }
  }

  &__range {
    display: none;

    @include media(min, $sm) {
      display: inherit;
      position: absolute;
      left: 101px;
      right: 101px;
      bottom: (math.div($input-height, 2));
      width: auto;
    }
  }

  &__title,
  &__subtitle {
    font-weight: $bold;
    line-height: 1.45;
  }

  &__title {
    font-size: $root;
  }

  &__header {
    min-height: 43px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  }
}

.nominee {
  &__title {
    @include media(min, $sm) {
      display: flex;
      align-items: baseline;

      button {
        margin-left: 12px;
      }
    }

    @include media(max, $sm) {
      button {
        margin-top: 10px;
      }
    }
  }
}

.number-actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;

  .number-actions__btn {
    height: math.div($input-height, 2);
    width: math.div($input-height, 2);
    color: getColor(light-blue);
    outline: none;
    font-size: $root;

    &:hover {
      background: getColor(light-blue);
      color: getColor(white);
    }

    &:focus {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(light-blue);
    }
  }
}

.actions-group {
  pointer-events: all;
}

.control.control--unit {
  input[type=number] {
    padding-right: 50px;
    font-weight: $bold;
  }

  .unit {
    right: math.div($input-height, 2);
    width: 25px;
    font-weight: $bold;
    color: inherit;
  }
}

.custom-field {
  &.part-full {
    margin-top: -10px;
  }

  label {
    margin-bottom: 10px;
    display: block;
  }

  @include media(min, $md) {
    display: flex;
    align-items: center;

    label {
      flex-basis: 177px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &--bank {
    &__label {
      @include media(min, $md) {
        align-self: flex-start;
        margin-top: 8px;
      }
    }

    :deep(.field-info) {
      margin-left: 0;
    }
  }
}
</style>
