<template>
  <div
    dusk="contract-type-selection"
  >
    <div v-if="error.length > 0">
      <p class="error">
        {{ error }}
      </p>
    </div>

    <div
      class="flex flex-between"
    >
      <div
        v-for="(field, index) in fields"
        :key="index"
        class="w-90 mb-20"
      >
        <div
          dusk="contract-selection"
          :class="{
            'contract': true,
            'selected': syncContractSelect === field.value,
            'contract--error': error.length > 0
          }"
        >
          <label
            :for="field.value"
            :style="height ? { height } : null"
            class="select-label"
          >
            <div class="contract__value flex-column">
              <div class="contract__input">
                <input
                  :id="field.value"
                  v-model="syncContractSelect"
                  type="radio"
                  name="contractGroup"
                  :value="field.value"
                >

                <span class="contract__control" />

                <div
                  v-if="field.onlyBankId"
                  class="bankId"
                >
                  <span>
                    Pouze s Bank iD
                  </span>
                </div>
              </div>
              <div class="contract__label">
                {{ field.title }}
              </div>

              <div class="contract__result">
                <span>
                  {{ field.text }}
                </span>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Field } from '@/js/stores/types.ts';

export default {
  props: {
    contractType: {
      type: String,
      default: '',
    },

    modelValue: {
      type: [String, Number],
      default: '',
    },

    fields: {
      type: Array as () => Field[],
      required: true,
    },

    error: {
      type: String,
      default: '',
    },

    height: {
      type: String,
      default: null,
    },
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    syncContractSelect: {
      get () {
        return this.modelValue;
      },
      set (val: string) {
        this.$emit('update:modelValue', val as string);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

$breakpoint-mobile-small: 600px;

.contract {
  position: relative;
  width: 100%;

  label {
    display: block;
    line-height: 1.2;
    padding: 18px 12px;
    border: 1px solid getColor(lighter-grey);
    border-radius: 4px;

    &:focus-within {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(green);
    }
  }

  &__value {
    font-weight: $bold;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__input {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
    }
  }

  &__control {
    $control-bg: #d8e8c5;

    width: rem(23);
    height: rem(23);
    border-radius: 50%;
    border: 1px solid $control-bg;
    background: $control-bg;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      width: rem(9);
      height: rem(9);
      border-radius: 50%;
      transition: 180ms transform ease-in-out;
      transform: scale(0);
      background: getColor(green);
    }
  }

  input:checked + &__control {
    background: getColor(white);
    border-color: getColor(green);

    &::before {
      transform: scale(1);
    }
  }

  &__result {
    font-size: $root-mini;
    display: flex;
    justify-content: space-between;
    font-weight: normal;
  }

  &__batch {
    position: absolute;
    top: -10px;
    right: 20px;
    min-height: 20px;
    font-size: rem(10);
    padding: 4px 5px;
    z-index: 2;
    pointer-events: none;
    color: getColor(white);
    font-weight: $bold;
    background: getColor(default);

    &.bach--light {
      background: getColor(blue);
    }
  }

  &.selected label {
    border-color: getColor(green);
    box-shadow: inset 0 4px 0 getColor(green);
  }

  &--error label {
    border-color: getColor(danger, 1);
  }

  &--error.selected label {
    border-color: getColor(danger, 1);
    box-shadow: inset 0 4px 0 getColor(danger, 1);
  }

  &:not(.selected) label:hover {
    cursor: pointer;
  }
}

.contract-custom {
  display: flex;

  & label {
    width: 100%;

    @include media(min, $sm) {
      min-height: 94px;
      display: flex;
      align-items: center;
    }
  }

  & .contract__input {
    .field {
      display: flex;

      /* stylelint-disable */
      :deep(input) {
        font-weight: $bold;
      }

      /* stylelint-enable */

      @media screen and (max-width: $breakpoint-mobile-small) {
        max-width: 300px;
      }
    }

    @media screen and (max-width: $breakpoint-mobile-small) {
      margin-bottom: 0;
    }
  }

  & .contract__value {
    margin-bottom: 0;
  }

  &.selected {
    & .contract__value {
      margin-bottom: 10px;

      @media screen and (max-width: $breakpoint-mobile-small) {
        margin-bottom: 0;
        flex-basis: 157px;
      }
    }
  }

  & .contract__summary {
    margin-top: 10px;

    @media screen and (max-width: $breakpoint-mobile-small) {
      margin-top: 0;
      margin-left: auto;
    }
  }
}

.flex {
  gap: 8px;

  @media screen and (max-width: $breakpoint-mobile-small) {
    flex-direction: column;
  }
}

.bankId {
  font-weight: 700;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  top: 21.5px;
  right: 15px;
  padding: 5px 6px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 1);
  color: white;
}

.w-90 {
  width: 90%;
}

.error {
  display: block;
  padding: .125em 0;
  margin-top: .25em;
  color: getColor(danger, 1);
}

.select-label {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
