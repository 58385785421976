<template>
  <div>
    <div
      class="card"
      :class="{
        loading: uploading,
        uploaded,
        dragging,
        error: allErrors.length > 0
      }"
      dusk="document-uploader"
      @dragover.prevent="dragging = true"
      @dragleave="dragging = false"
      @drop.prevent="dragging = false;uploadDocument($event, true)"
    >
      <div
        v-if="disabled && !uploading"
        class="card__disabled"
      />

      <h4>{{ title }}</h4>

      <div
        v-show="uploading"
        class="card__wrapper"
      >
        <div class="card__loader">
          <div class="card__frame">
            <i class="icon__loader">
              <img
                :src="getIconUrl('icon-loader')"
                alt="Ikona načítání"
              >
            </i>
          </div>
        </div>

        <div class="button--actions">
          <button
            class="btn-simple"
            type="button"
            @click="changeDocument"
          >
            Nahrát znovu
          </button>
        </div>
      </div>

      <div
        v-show="uploaded && !uploading"
        class="card__wrapper"
      >
        <div class="card__loader">
          <div
            v-if="imageId"
            class="identityCard"
          >
            <img
              :src="documentUri"
              dusk="document-preview"
              alt=""
            >
            <i class="icon-check">
              <img
                :src="getIconUrl('step-ok')"
                alt="Ikona OK"
              >
            </i>
          </div>
        </div>

        <div class="button--actions">
          <button
            class="btn-simple"
            type="button"
            dusk="change-button"
            @click="changeDocument"
          >
            <i>
              <img
                :src="getIconUrl('icon-edit')"
                alt="Ikona upravit"
              >
            </i>
            Změnit
          </button>

          <button
            class="btn-simple btn-simple--red"
            type="button"
            dusk="delete-button"
            @click="deleteDocument"
          >
            <i>
              <img
                :src="getIconUrl('icon-delete')"
                alt="Ikona smazat"
              >
            </i>
            Smazat
          </button>
        </div>
      </div>

      <div
        v-show="!uploaded && !uploading"
        class="card__wrapper"
      >
        <div class="card__description">
          <i>
            <img
              :src="getIconUrl('icon-cloud-upload_blue')"
              alt="Ikona nahrát soubor"
            >
          </i>

          <span>
            {{ description }}
          </span>
        </div>

        <label
          :for="componentUuid"
          class="btn btn-primary"
          dusk="upload-button"
        >
          Nahrát

          <span class="button--mobile">
            {{ buttonText }}
          </span>

          <input
            :id="componentUuid"
            :ref="componentUuid"
            :name="name"
            type="file"
            accept="image/*,application/pdf"
            @change="uploadDocument"
          >
        </label>
      </div>
    </div>
    <div
      v-if="allErrors.length > 0"
      class="card__errors mt-10"
    >
      {{ allErrors[0] }}
    </div>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { mapWritableState } from 'pinia';
import contractFiles from '../api/contractFiles';
import { getCitizenship, getIconUrl } from '../utils';

import { useOnlineAgreementStore } from '../stores';
import { refreshContract } from '../stores/utils';

export default {
  name: 'DocumentUploader',
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      default: '',
    },

    description: {
      type: String,
      default: '',
    },

    buttonText: {
      type: String,
      default: '',
    },

    contractUuid: {
      type: String,
      required: true,
    },

    documentType: {
      type: String,
      default: '',
    },

    useOcr: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => [],
    },
  },

  emits: [
    'update:modelValue',
    'uploading',
  ],

  setup () {
    const uploading = ref(false);
    const uploaded = ref(false);
    const dragging = ref(false);
    const intErrors = ref([]);// interní chyb)
    const error = ref(false);

    const store = useOnlineAgreementStore();

    return {
      uploading,
      uploaded,
      dragging,
      intErrors,
      error,
      getIconUrl,
      store,
    };
  },

  data: () => ({

  }),

  computed: {
    imageId () {
      return this.modelValue;
    },

    ...mapWritableState(useOnlineAgreementStore, ['personalData']),

    citizenship: {
      get () {
        return this.personalData.citizenship.value;
      },
      set (value: string) {
        this.personalData.citizenship.value = value;
      },
    },

    componentUuid () {
      const uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);

      return `inputFileId${uniqueId}`;
    },

    documentUri () {
      const uuid = this.contractUuid;
      const fileId = this.imageId;

      return `/api/v1/contracts/${uuid}/files/${fileId}?width=170&height=113&bestfit=1`;
    },

    allErrors () {
      return [
        ...this.errors,
        ...this.intErrors,
      ];
    },
  },

  mounted () {
    if (this.modelValue !== '') {
      this.uploaded = true;
    }
  },

  methods: {
    async uploadDocument (event: any, isDropFile = false) {
      try {
        const { files } = isDropFile ? event.dataTransfer : event.target;
        this.uploading = true;
        this.$emit('uploading', { isUploading: this.uploading });

        const response = await contractFiles.create(
          this.contractUuid,
          files[0],
          this.$props.documentType,
        );

        if (this.useOcr) {
          await refreshContract(this.store.$state, this.contractUuid);

          this.citizenship = getCitizenship(this.citizenship);
        }

        this.uploading = false;
        this.uploaded = true;
        this.error = false;

        this.$emit('update:modelValue', response.id);
      } catch (e: any) {
        this.intErrors = e.response?.data.errors.document;
      } finally {
        this.uploading = false;

        this.$emit('uploading', { isUploading: this.uploading });
      }
    },

    deleteDocument () {
      this.uploaded = false;
      // @ts-expect-error - ref is not typed
      this.$refs[this.componentUuid].value = null;

      this.$emit('update:modelValue', null);
    },

    changeDocument () {
      // @ts-expect-error - ref is not typed
      this.$refs[this.componentUuid].click();

      this.deleteDocument();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/animations';

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &__disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    border-radius: 8px;
    background: rgba(0, 0, 0, .28);
  }

  @include media(min, $md) {
    border: 2px dashed transparent;
    border-color: getColor(light-grey);
    border-radius: 8px;
    min-height: 200px;
    flex-flow: column;
    padding: 20px;
    height: 100%;
  }

  &.dragging {
    @include media(min, $md) {
      border-color: rgb(27 153 204 / 50%);
      background: rgb(229 246 251 / 50%);
    }
  }

  label {
    &:focus-within {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(green);
    }
  }

  input[type=file] {
    width: .1px;
    height: .1px;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .btn {
    cursor: pointer;
  }

  button:not(.btn-simple) {
    flex-basis: 167px;

    @include media(min, $md) {
      flex-basis: auto;
      width: 100%;
    }
  }

  .button--mobile {
    display: none;

    @include media(min, $md) {
      display: contents;
    }
  }

  .card__description {
    display: none;

    @include media(min, $md) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: rem(20);
      line-height: 1.45;
    }

    i {
      margin-right: rem(16);
      min-width: 36px;
    }
  }

  .card__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .card__frame {
      width: 170px;
      height: 113px;
      border-radius: 9px;
      border: 3px solid getColor(light-blue);
      display: flex;
      justify-content: center;
      align-items: center;

      .icon__loader {
        height: 40px;
        animation: spin 1s linear infinite reverse;
      }
    }

    .identityCard {
      background-color: getColor(lighter-grey);
      justify-content: center;
      position: relative;
      display: flex;
      width: 170px;
      height: 113px;

      .icon-check {
        width: 41px;
        position: absolute;
        top: calc(50% - 20px);
        right: calc(0% - 20px);
        z-index: 1;
      }
    }
  }

  .button--actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    > button {
      flex: 1;
      justify-content: center;
    }

    i {
      width: 14px;
      margin: 2px;
      display: grid;
    }
  }

  h4 {
    font-size: $root;
    font-weight: $bold;
    margin: 0;

    @include media(min, $md) {
      margin: 0 0 15px;
      text-align: center;
    }
  }

  &__wrapper {
    display: flex;
    width: 100%;

    @include media(min, $md) {
      flex-flow: column;
    }

    @include media(max, $md) {
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  &.loading, &.uploaded {
    flex-flow: column;
    padding: 20px;

    h4 {
      margin-bottom: 14px;
    }

    .card__wrapper {
      flex-flow: column;
      width: 100%;

      @include media(max, $md) {
        justify-content: center;
      }
    }
  }

  &.loading {
    border-color: getColor(lighter-grey);
  }

  &.uploaded {
    border-color: getColor(green);
  }

  &.error {
    border-color: getColor(danger, 1);
  }

  &__errors {
    color: getColor(danger, 1);
  }
}
</style>
