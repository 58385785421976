<template>
  <div dusk="contract-settings">
    <JpsStepper current-step="contractSettings" />

    <div class="container--sm mt-20">
      <div
        class="mb-40"
        dusk="contract-parameters"
      >
        <h2>Nastavení parametrů penzijního spoření</h2>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="jps"
          field-path="contractSettings.contractSignaturePlace"
          :debounce="0"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            label="Místo podepsání smlouvy"
            name="contractSignPlace"
            dusk="contract-sign-place"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input }"
          namespace="jps"
          field-path="contractSettings.taxOptimization"
          :default-value="false"
          :debounce="0"
          @validate="validateField"
        >
          <LitCheckbox
            class="mb-20 mt-20"
            dusk="tax-optimization"
            tooltip-position="right"
            tooltip="Program daňové optimalizace zajistí, že finanční prostředky
            zaplacené nad sjednaný měsíční příspěvek budou použity
            na maximální možné snížení základu daně. Více informací <a
            href='https://csob-penze.cz/faq/jak-funguje-program-danove-optimalizace/'
            target='_blank'>
            jak funguje Program daňové optimalizace.
            </a>"
            :show-tooltip="true"
            :model-value="value"
            @update:model-value="input"
          >
            Klient chce využít Program daňové optimalizace
          </LitCheckbox>
        </ServerValidatedFormField>

        <div class="pt-20">
          <ServerValidatedFormField
            v-slot="{ value, input }"
            namespace="jps"
            field-path="contractSettings.contributionFromEmployer"
            :debounce="0"
            @validate="validateField"
          >
            <LitCheckbox
              class="mb-20"
              dusk="employer-contribution"
              tooltip="Odškrtnutím/odznačením je možné pokračovat
              bez vyplnění příspěvku zaměstnavatele."
              :show-tooltip="true"
              :model-value="value"
              @update:model-value="input"
            >
              <span class="checkbox__heading">
                Na penzijní spoření bude klientovi přispívat i jeho zaměstnavatel
              </span>
            </LitCheckbox>
          </ServerValidatedFormField>

          <div v-if="contractSettings.contributionFromEmployer.value">
            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate }"
              namespace="jps"
              field-path="contractSettings.employerInfo.name"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                dusk="employer-name"
                name="nameOfEmployer"
                label="Název zaměstnavatele"
                placeholder="Např. AAA Auto, a.s."
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @blur="validate($event.target.value)"
              />
            </ServerValidatedFormField>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate }"
              namespace="jps"
              field-path="contractSettings.employerInfo.vat"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                name="employerId"
                dusk="employer-id"
                label="IČ zaměstnavatele"
                placeholder="Např. 95874375"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @blur="validate($event.target.value)"
              >
                <template #actions>
                  <div class="actions-group">
                    <a
                      href="https://ares.gov.cz/ekonomicke-subjekty"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vyhledat IČO v obchodním rejstříku
                    </a>
                  </div>
                </template>
              </LitInput>
            </ServerValidatedFormField>
          </div>
        </div>
      </div>

      <div dusk="nominees">
        <h2>Určené osoby</h2>

        <LitAlert
          class="mb-20"
          dusk="nominees-alert"
        >
          Pokud klient do své smlouvy uvede určené osoby, budou jim v případě
          klientova úmrtí naspořené peníze vyplaceny bez dědického řízení.
          Určené osoby může klient kdykoliv změnit nebo doplnit.
        </LitAlert>

        <ServerValidatedFormField
          v-slot="{ value, input }"
          namespace="jps"
          field-path="contractSettings.specifyNominees"
          :debounce="0"
          @validate="validateField"
        >
          <LitCheckbox
            class="mb-20"
            dusk="want-nominees"
            :model-value="value"
            @update:model-value="input"
          >
            Klient chce uvést určenou osobu
          </LitCheckbox>
        </ServerValidatedFormField>

        <NomineesList
          v-if="specifyNominees"
          v-model:share="nomineesShare"
          namespace="jps"
          store-path="contractSettings.nominees"
          :validate-fnc="validateField"
          :is-client-from-czechia="isClientFromCzechia"
        />
      </div>

      <div class="mt-30">
        <hr>
      </div>
    </div>

    <StepButtons
      namespace="jps"
      previous-step="contributionAndStrategy"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { cloneDeep, set } from 'lodash';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import NomineesList from '@/js/components/NomineesList.vue';
import JpsStepper from '@/js/components/Steppers/JpsStepper.vue';

import gtm from '@/js/services/gtm';

import { scrollToError } from '@/js/utils';

import { addressField } from '@/js/stores/createStoreFields';
import { useStoreField } from '@/js/composables/useStoreField';
import { useJpsStore } from '@/js/stores';
import StepButtons from '@/js/views/common/StepButtons.vue';

function getNomineeFields (index: number) {
  return [
    {
      path: `contractSettings.nominees.${index}.firstName`,
      pathToValidate: 'contractSettings.nominees.*.firstName',
    },
    {
      path: `contractSettings.nominees.${index}.lastName`,
      pathToValidate: 'contractSettings.nominees.*.lastName',
    },
    {
      path: `contractSettings.nominees.${index}.personalId`,
      pathToValidate: 'contractSettings.nominees.*.personalId',
    },
    {
      path: `contractSettings.nominees.${index}.usePersonalId`,
      pathToValidate: 'contractSettings.nominees.*.usePersonalId',
    },
    {
      path: `contractSettings.nominees.${index}.countryOfBirth`,
      pathToValidate: 'contractSettings.nominees.*.countryOfBirth',
    },
    {
      path: `contractSettings.nominees.${index}.birthdate`,
      pathToValidate: 'contractSettings.nominees.*.birthdate',
    },
    {
      path: `contractSettings.nominees.${index}.share`,
      pathToValidate: 'contractSettings.nominees.*.share',
    },
    {
      path: `contractSettings.nominees.${index}.permanentAddress.street`,
      pathToValidate: 'contractSettings.nominees.*.permanentAddress.street',
    },
    {
      path: `contractSettings.nominees.${index}.permanentAddress.streetNumber`,
      pathToValidate: 'contractSettings.nominees.*.permanentAddress.streetNumber',
    },
    {
      path: `contractSettings.nominees.${index}.permanentAddress.zip`,
      pathToValidate: 'contractSettings.nominees.*.permanentAddress.zip',
    },
    {
      path: `contractSettings.nominees.${index}.permanentAddress.city`,
      pathToValidate: 'contractSettings.nominees.*.permanentAddress.city',
    },
    {
      path: `contractSettings.nominees.${index}.permanentAddress.country`,
      pathToValidate: 'contractSettings.nominees.*.permanentAddress.country',
    },
  ];
}

function createNominee () {
  return {
    firstName: useStoreField(''),
    lastName: useStoreField(''),
    personalId: useStoreField(''),
    usePersonalId: useStoreField(true),
    countryOfBirth: useStoreField('cz'),
    birthdate: useStoreField(''),
    share: useStoreField(100),
    permanentAddress: addressField(),
  };
}

const MAX_NOMINEE_SHARE = 100;

const route = useRoute();
const router = useRouter();

const store = useJpsStore();
const { validateField, validateFields } = store;
const { contractSettings, beforeStart, isClientFromCzechia } = storeToRefs(store);

const validating = ref(false);

const nomineesShare = computed({
  get () {
    return contractSettings.value.nomineesShare.value;
  },
  set (value) {
    contractSettings.value.nomineesShare.value = value;
  },
});

const nominees = computed({
  get () {
    return contractSettings.value.nominees;
  },
  set (value) {
    contractSettings.value.nominees = value;
  },
});

const specifyNominees = computed(() => contractSettings.value.specifyNominees.value);
const fields = computed(() => {
  const result = [
    { path: 'contractSettings.contractSignaturePlace' },

    { path: 'contractSettings.taxOptimization' },

    { path: 'contractSettings.contributionFromEmployer' },
    { path: 'contractSettings.employerInfo.name' },
    { path: 'contractSettings.employerInfo.vat' },
  ];

  if (specifyNominees.value) {
    nominees.value.forEach((_nominee, index) => {
      result.push(...getNomineeFields(index));
    });
  }

  return result;
});

watch(specifyNominees, (newValue) => {
  if (newValue && nominees.value.length === 0) {
    addNominee();
  } else if (!newValue) {
    nominees.value = [];
  }
});

function setSignaturePlace () {
  if (typeof beforeStart.value.meetingAddress !== 'undefined') {
    contractSettings.value.contractSignaturePlace.value = beforeStart.value.meetingAddress.city.value;
  }
}

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fields: fields.value,
    });

    validating.value = false;

    await router.push({
      params: {
        contractUuid,
      },
      name: 'jps.clientsStatement',
    });

    gtm.onStepSubmit('smlouva-nastaveni-parameteru');
  } catch (e) {
    validating.value = false;

    nominees.value.forEach((_nominee, index) => {
      updateNomineeField(index, 'permanentAddress.setManually', true);
    });

    scrollToError();

    console.error(`There was a validation ClientsStatementError: ${e}`);
  }
}

function addNominee () {
  if ([...nominees.value].length >= 2) {
    return;
  }

  nominees.value = [
    ...nominees.value,
    createNominee(),
  ];

  nomineesShare.value = MAX_NOMINEE_SHARE / nominees.value.length;
}

function updateNomineeField (index: number, fieldPath: string, value: unknown) {
  if (index >= nominees.value.length) {
    return;
  }

  const updatedNominee = cloneDeep(nominees.value[index]);

  set(updatedNominee, `${fieldPath}.value`, value);

  nominees.value = [
    ...nominees.value.slice(0, index),
    updatedNominee,
    ...nominees.value.slice(index + 1),
  ];
}

onMounted(() => {
  if (beforeStart.value.isTiedAgent.value === true && (contractSettings.value.contractSignaturePlace.value === null || contractSettings.value.contractSignaturePlace.value === '')) {
    setSignaturePlace();
  }
});
</script>
