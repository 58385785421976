<template>
  <div>
    <router-view />

    <AppVersion
      v-if="enableAppVersion"
      :enable-devtools="!isProduction"
    />
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import env from '../env';
import AppVersion from '../components/AppVersion.vue';

const loadSmartFormScript = () => {
  window.smartform = window.smartform || {};
  window.smartform.beforeInit = () => {
    window.smartform.setClientId(env.SMARTFORMS_CLIENT_ID);
  };
};

onMounted(() => {
  if (env.SMARTFORMS_CLIENT_ID) {
    loadSmartFormScript();
  }
});

const isProduction = computed(() => env.APP_ENV === 'production');
const isStaging = computed(() => env.APP_ENV === 'staging');
const isLocal = computed(() => env.APP_ENV === 'local');
const enableAppVersion = computed(() => (isLocal.value || isStaging.value) && env.DEVTOOLS_ENABLE);
</script>

<style lang="scss">
@import '@sass/app.scss';
</style>
