<script setup lang="ts" generic="T extends ContributionAndStrategyReturn">
import { annualTaxSaving, stateContribution } from '@/js/utils.ts';

import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import CurrentContract from '@/js/views/common/recapitulation/CurrentContract.vue';

import fundsData from '@/js/data/fundsData';
import type { ContributionAndStrategyReturn } from '@/js/stores/createStoreFields';
import type { CurrentContractReturn } from '@/js/stores/types';
import RecapitulationTableRow from '@/js/components/RecapitulationTableRow.vue';

interface Props {
  storePath: T
  namespace: string
  isFromRequest: boolean
  isFromTransfer: boolean
  isGrantedPension: boolean
  transferringCurrentContract: boolean
  currentContractStorePath: CurrentContractReturn
}

const props = defineProps<Props>();

function selectedStrategy (strategy: string | null) {
  switch (true) {
    case strategy === fundsData.lifeCycle.slug:
      return fundsData.lifeCycle.title;
    case strategy === fundsData.balanced.slug:
      return fundsData.balanced.title;
    case strategy === fundsData.dynamic.slug:
      return fundsData.dynamic.title;
    case strategy === fundsData.esg.slug:
      return fundsData.esg.title;
    case strategy === fundsData.pension.slug:
      return fundsData.pension.title;
    case strategy === fundsData.conservative.slug:
      return fundsData.conservative.title;
    case strategy === fundsData.reinvestment.slug:
      return fundsData.reinvestment.title;
    case strategy === 'customFundCombination':
      return 'Vlastní kombinace fondů';
    case strategy === 'reinvestment':
      return 'Postupná reinvestice';
    default:
      return strategy;
  }
}
</script>

<template>
  <table class="recapitulation-table mb-16">
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Příspěvek a strategie</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${props.namespace}.contributionAndStrategy` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <RecapitulationTableRow :path="props.storePath.contributionAmount" table-key="Výše měsíčního příspěvku" />
      <RecapitulationTableRow :path="props.storePath.contributionAmount" table-key="Státní příspěvek">
        <template #tableValue>
          {{ stateContribution(props.storePath.contributionAmount.value, true, props.isGrantedPension).value }} Kč/měsíc
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow :path="props.storePath.contributionAmount" table-key="Daňová úspora">
        <template #tableValue>
          {{ annualTaxSaving(props.storePath.contributionAmount.value, true, props.isGrantedPension).value }} Kč/rok
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow :path="props.storePath.selectedStrategy" table-key="Strategie penzijního spoření">
        <template #tableValue>
          {{ selectedStrategy(props.storePath.selectedStrategy.value) }}
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow
        v-if="props.storePath.selectedStrategy.value === 'customFundCombination'"
        :path="props.storePath.selectedStrategy"
        table-key="Zvolená kombinace fondů"
      >
        <template #tableValue>
          <ul class="list-unstyled">
            <li>
              Zodpovědný fond: {{ props.storePath.fundCombination.value.esg }}%
            </li>
            <li>
              Vyvážený fond: {{ props.storePath.fundCombination.value.balanced }}%
            </li>
            <li>
              Konzervativní fond: {{ props.storePath.fundCombination.value.conservative }}%
            </li>
            <li>
              Dynamický fond: {{ props.storePath.fundCombination.value.dynamic }}%
            </li>
            <li>
              Fond pro penzi: {{ props.storePath.fundCombination.value.pension }}%
            </li>
          </ul>
        </template>
      </RecapitulationTableRow>

      <tr v-if="!props.isFromRequest">
        <td>Převod z jiné penzijní společnosti</td>
        <td
          colspan="2"
        >
          {{ props.transferringCurrentContract === true ? 'Ano' : 'Ne' }}
        </td>
      </tr>

      <template v-if="props.transferringCurrentContract">
        <CurrentContract
          :store-path="currentContractStorePath"
          :is-from-transfer="isFromTransfer"
          :namespace="namespace"
        />
      </template>
    </tbody>
  </table>
</template>
