<template>
  <div>
    <AddressAutocomplete
      v-show="showAutocomplete"
      v-model:query="query"
      v-model:editable="editable"
      class="mb-20"
      :name="name"
      :dusk="dusk"
      :label="label"
      :show-alert="showAlert"
      :tooltip="tooltip"
      :show-action-buttons="showActionButtons"
      :description="description"
      :disabled="disabled"
      @set-address-manually="setManually = true"
      @place-detail-loaded="fillAddressComponents"
      @update:query="$event.target ? query = $event.target.value : null"
    >
      <template #actions>
        <slot name="autocomplete-actions" />
      </template>
    </AddressAutocomplete>

    <div
      v-show="!showAutocomplete"
      :dusk="`${name}-detail`"
    >
      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.street`"
        :validation-path="validationPaths.streetName"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          tooltip="Pokud vaše adresa neobsahuje ulici, uveďte do pole Ulice název obce."
          :name="`${name}-address-line1`"
          :dusk="`${name}-street`"
          :model-value="value"
          :errors="errors"
          :label="labels.streetName"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        >
          <template #actions>
            <div class="actions-group">
              <slot name="autocomplete-actions" />

              <div
                v-if="allowAutocomplete"
              >
                <button
                  type="button"
                  class="btn-simple"
                  @click="setManually = false"
                >
                  Zadat pomocí našeptávače
                </button>
              </div>
            </div>
          </template>
        </LitInput>
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.streetNumber`"
        :validation-path="validationPaths.streetNumber"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          :name="`${name}-address-line2`"
          :dusk="`${name}-street-number`"
          :model-value="value"
          :errors="errors"
          :label="labels.streetNumber"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.zip`"
        :validation-path="validationPaths.zip"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          :name="`${name}-postal-code`"
          :dusk="`${name}-zip`"
          :model-value="value"
          :errors="errors"
          :label="labels.zip"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.city`"
        :validation-path="validationPaths.city"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          :name="`${name}-address-level2`"
          :dusk="`${name}-city`"
          :model-value="value"
          :errors="errors"
          :label="labels.city"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.country`"
        :validation-path="validationPaths.country"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitSelect
          class="mb-20"
          :debounce="0"
          :dusk="`${name}-country`"
          :label="labels.country"
          :validate-on-input="true"
          :options="countrySelectionAllowed ? countryOptions : countryByValue"
          :errors="errors"
          :model-value="countrySelectionAllowed ? value : countryByValue[0].value"
          :searchable="countrySelectionAllowed ? searchableCountry : false"
          @update:model-value="input"
        />
      </ServerValidatedFormField>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { getActivePinia } from 'pinia';
import { get } from 'lodash';

import { countriesOfBirth } from '../data/countryOfBirthOptions';

import type { ValidateStoreParamsFnc } from '../stores/utils';
import AddressAutocomplete from './AddressAutocomplete.vue';
import LitInput from './Base/LitInput.vue';
import LitSelect from './Base/LitSelect.vue';

export default defineComponent({
  components: {
    AddressAutocomplete,
    LitInput,
    LitSelect,
  },

  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },

    labels: {
      type: Object,
      required: false,
      default: () => ({
        streetName: 'Ulice',
        streetNumber: 'Číslo popisné / orientační',
        zip: 'PSČ',
        city: 'Město',
        country: 'Stát',
      }),
    },

    name: {
      type: String,
      required: false,
      default: '',
    },

    description: {
      type: String,
      required: false,
      default: '',
    },

    dusk: {
      type: String,
      required: false,
      default: '',
    },

    showAlert: {
      type: Boolean,
      required: false,
      default: false,
    },

    tooltip: {
      type: String,
      required: false,
      default: '',
    },

    namespace: {
      type: String,
      required: true,
    },

    storePath: {
      type: String,
      required: true,
    },

    validationPath: {
      type: String,
      required: false,
      default: null,
    },

    allowAutocomplete: {
      type: Boolean,
      required: false,
      default: true,
    },

    countryOptions: {
      type: Array as PropType<Record<string, unknown>[]>,
      default: () => countriesOfBirth,
    },

    searchableCountry: {
      type: Boolean,
      default: false,
    },

    showActionButtons: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    validateFnc: {
      type: Function as PropType<ValidateStoreParamsFnc>,
      required: true,
    },

    countrySelectionAllowed: {
      type: Boolean,
      default: true,
    },

    onlyOfferedCounty: {
      type: String,
      default: 'cz',
    },
  },

  computed: {
    activeStore () {
      const store = getActivePinia();

      if (!store) {
        throw new Error('Pinia store is not active');
      }

      return get(store.state.value, `${this.namespace}.${this.storePath}`);
    },

    validationPaths () {
      if (!this.validationPath) {
        return {
          streetName: undefined,
          streetNumber: undefined,
          zip: undefined,
          city: undefined,
          country: undefined,
        };
      }

      return {
        streetName: `${this.validationPath}.street`,
        streetNumber: `${this.validationPath}.streetNumber`,
        zip: `${this.validationPath}.zip`,
        city: `${this.validationPath}.city`,
        country: `${this.validationPath}.country`,
      };
    },

    street: {
      get () {
        return this.activeStore.street.value;
      },
      set (newValue: string | null) {
        this.activeStore.street.value = newValue;
      },

    },

    streetNumber: {
      get () {
        return this.activeStore.streetNumber.value;
      },
      set (newValue: string | null) {
        this.activeStore.streetNumber.value = newValue;
      },

    },

    city: {
      get () {
        return this.activeStore.city.value;
      },
      set (newValue: string | null) {
        this.activeStore.city.value = newValue;
      },

    },

    zip: {
      get () {
        return this.activeStore.zip.value;
      },
      set (newValue: string | null) {
        this.activeStore.zip.value = newValue;
      },

    },

    country: {
      get () {
        return this.activeStore.country.value;
      },
      set (newValue: string | null) {
        this.activeStore.country.value = newValue;
      },
    },

    query: {
      get () {
        return this.activeStore.query.value;
      },
      set (newValue: string | null) {
        this.activeStore.query.value = newValue;
      },
    },

    setManually: {
      get () {
        return this.activeStore.setManually.value;
      },
      set (newValue: boolean) {
        this.activeStore.setManually.value = newValue;
      },
    },

    editable: {
      get () {
        return this.activeStore.editable.value;
      },
      set (newValue: boolean) {
        this.activeStore.editable.value = newValue;
      },
    },

    countryByValue () {
      return countriesOfBirth.filter((country) => country.value === this.onlyOfferedCounty);
    },

    showAutocomplete () {
      if (!this.allowAutocomplete) {
        return false;
      }

      return !this.setManually;
    },
  },

  methods: {
    fillAddressComponents ({
      street,
      streetNumber,
      city,
      zip,
      countryCode,
    }: {
      street: string
      streetNumber: string
      city: string
      zip: string
      countryCode: string
    }) {
      this.street = street;
      this.streetNumber = streetNumber;
      this.city = city;
      this.zip = zip;
      this.country = countryCode;
    },
  },
});
</script>
