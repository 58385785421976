<template>
  <div dusk="recapitulation">
    <TerminationStepper current-step="recapitulation" />

    <RecapitulationTemplate
      form-type="žádosti"
      :error="error"
    >
      <template #recapitulation>
        <RecapitulationDistributor
          namespace="termination"
          :store-path="beforeStart"
        />

        <hr class="my-30">

        <RecapitulationContactInformation
          v-if="isParticipant"
          namespace="termination"
          :store-path="contactInformation"
          title="Kontaktní údaje klienta"
        />

        <hr
          v-if="isParticipant"
          class="my-30"
        >

        <RecapitulationPersonalData
          namespace="termination"
          :store-path="personalData"
          :is-participant="isParticipant"
        />

        <hr class="my-30">

        <!-- signer contact information -->
        <RecapitulationContactInformation
          v-if="!isParticipant"
          namespace="termination"
          title="Kontaktní údaje podepisující osoby"
          :store-path="contactInformation"
        />

        <hr
          v-if="!isParticipant"
          class="my-30"
        >

        <RecapitulationSignerPersonalData
          v-if="!isParticipant"
          namespace="termination"
          :store-path="signer"
        />

        <hr
          v-if="!isParticipant"
          class="my-30"
        >

        <table class="recapitulation-table mb-16">
          <thead>
            <tr>
              <th
                colspan="2"
              >
                <h3>{{ labels.paymentMethod }}</h3>
              </th>
              <th>
                <router-link
                  :to="{ name: 'termination.paymentOption' }"
                  class="edit-section"
                >
                  <LitIconSvg icon-name="edit_green" />

                  Upravit
                </router-link>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Typ dávky</td>
              <td
                colspan="2"
              >
                {{ terminationOption }}
              </td>
            </tr>
            <tr v-if="isOldAgePensionSelected">
              <td>Penze bude vyplácena po dobu:</td>
              <td
                colspan="2"
              >
                {{ terminationSettings.oldAgePension.selectedYears.value }} let
              </td>
            </tr>
            <tr v-if="isOldAgePensionSelected">
              <td>Frekvence vyplácení penze</td>
              <td
                colspan="2"
              >
                {{ terminationSettings.oldAgePension.paymentFrequency.value === 'monthly' ? 'Měsíčně' : 'Čtvrtletně' }}
              </td>
            </tr>
          </tbody>
        </table>

        <hr class="my-30">

        <table class="recapitulation-table mb-16">
          <thead>
            <tr>
              <th
                colspan="2"
              >
                <h3>{{ labels.additionalInformation }}</h3>
              </th>
              <th>
                <router-link
                  :to="{ name: 'termination.paymentOption' }"
                  class="edit-section"
                >
                  <LitIconSvg icon-name="edit_green" />
                  Upravit
                </router-link>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Způsob výplaty dávky</td>
              <td
                colspan="2"
              >
                {{ paymentMethod === 'bankTransfer' ? 'Bankovní účet' : 'Poštovní poukázkou' }}
              </td>
            </tr>

            <tr v-if="paymentMethod === 'bankTransfer'">
              <td>Číslo bankovního účtu</td>
              <td
                colspan="2"
              >
                {{ bankAccount }}
              </td>
            </tr>

            <tr v-else>
              <td>Zaslat na korespondenční adresu</td>
              <td
                colspan="2"
              >
                <ul
                  v-if="personalData.specifyMailingAddress.value === true"
                  class="list-unstyled"
                >
                  <li>
                    {{
                      personalData.mailingAddress.street.value
                        ? personalData.mailingAddress.street.value
                        : personalData.mailingAddress.city.value
                    }} {{ personalData.mailingAddress.streetNumber.value }}
                  </li>
                  <li>{{ personalData.mailingAddress.city.value }}</li>
                  <li>{{ personalData.mailingAddress.zip.value }}</li>
                  <li>{{ country(personalData.mailingAddress.country.value) }}</li>
                </ul>
                <ul
                  v-else
                  class="list-unstyled"
                >
                  <li>
                    {{
                      personalData.permanentAddress.street.value
                        ? personalData.permanentAddress.street.value
                        : personalData.permanentAddress.city.value
                    }} {{ personalData.permanentAddress.streetNumber.value }}
                  </li>
                  <li>{{ personalData.permanentAddress.city.value }}</li>
                  <li>{{ personalData.permanentAddress.zip.value }}</li>
                  <li>{{ country(personalData.permanentAddress.country.value) }}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <hr class="my-30">

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="termination"
          field-path="terminationSettings.currentContractEndDate"
          :validate-on-input="false"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            label="Datum podpisu žádosti"
            name="terminationSignatureDate"
            dusk="termination-sign-date"
            disabled
            :model-value="formatDate(value)"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="termination"
          field-path="terminationSettings.transferSignaturePlace"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            label="Místo podepsání žádosti"
            name="terminationSignaturePlace"
            dusk="termination-sign-place"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <hr
          v-if="!isTerminationTransfer"
          class="my-30"
        >
      </template>
    </RecapitulationTemplate>

    <div class="flex flex-end">
      <StepButtons
        v-if="isTerminationTransfer"
        namespace="termination"
        previous-step="additionInformation"
        button-text="POKRAČOVAT"
        class="btn-recapitulation"
        :display-previous-step-button-text="true"
        @submit="transferSubmitStep"
      />

      <StepButtons
        v-if="!isTerminationTransfer && isLitSignEnabled"
        namespace="termination"
        previous-step="additionInformation"
        button-text="UKONČIT SMLOUVU"
        class="btn-recapitulation"
        :display-previous-step-button-text="true"
        @submit="submitStep('digital')"
      />

      <StepButtons
        v-if="!isTerminationTransfer && !isLitSignEnabled && !isBankId"
        namespace="termination"
        previous-step="additionInformation"
        button-text="VYTISKNOUT ŽÁDOST"
        class="btn-recapitulation"
        :display-previous-step-button-text="true"
        @submit="submitStep('offline')"
      />

      <StepButtons
        v-if="isBankId"
        namespace="termination"
        previous-step="additionInformation"
        button-text="PODEPSAT ELEKTRONICKY"
        class="btn-recapitulation"
        :show-submit-after-icon="false"
        :display-previous-step-button-text="true"
        @submit="isBankId && !isRedirected ? openModal('digital') : submitStep('digital')"
      />
    </div>
    <div
      v-show="showPrintHintSentence"
      dusk="print-info"
      class="print-info"
    >
      <div class="container mt-30">
        <hr>
      </div>

      <PrintOffer
        form-type="request"
        @show-modal="openModal('offline')"
      />
    </div>

    <ContractSubmissionModal
      type="termination"
      :model-value="isLitSignEnabled && redirecting"
      :is-digital="true"
    >
      <template #action>
        <p
          v-if="signingLink !== null"
          class="text-loading text-center"
        >
          Pokud nedojde k přesměrování, klikněte prosím na
          <a
            :href="signingLink"
          >
            tento odkaz
          </a>.
        </p>
      </template>
    </ContractSubmissionModal>

    <ContractSigningModal
      v-model="showDigitalSignModal"
      @sign-with-phone="sendDraftToken('digital')"
      @sign-later="sendDraftToken('digital')"
      @sign-with-computer="submitStep('digital')"
    />

    <FinishWithPaperPrintModal
      form-type="request"
      :model-value="showPrintConfirmationModal"
      @close="showPrintConfirmationModal = false"
      @click="submitStep('offline')"
    />

    <ContractSubmissionModal
      type="termination"
      :model-value="redirecting"
      :is-digital="true"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import {
  format,
} from 'date-fns';

import StepButtons from '../../common/StepButtons.vue';
import ContractSubmissionModal from '@/js/components/Modals/ContractSubmissionModal.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import TerminationStepper from '@/js/components/Steppers/TerminationStepper.vue';

import { draftTokens, signatures } from '@/js/api';
import env from '@/js/env';
import { scrollToError } from '@/js/utils';

import { countriesOfBirth } from '@/js/data/countryOfBirthOptions';

import { show } from '@/js/api/contracts';
import { useTerminationStore } from '@/js/stores';
import { submitContract } from '@/js/stores/utils';

import RecapitulationDistributor from '@/js/views/common/recapitulation/Distributor.vue';
import RecapitulationContactInformation from '@/js/views/common/recapitulation/ContactInformation.vue';
import RecapitulationPersonalData from '@/js/views/common/recapitulation/PersonalData.vue';
import RecapitulationSignerPersonalData from '@/js/views/common/recapitulation/SignerPersonalData.vue';
import RecapitulationTemplate from '@/js/views/common/recapitulation/RecapitulationTemplate.vue';
import PrintOffer from '@/js/components/PrintOffer.vue';
import ContractSigningModal from '@/js/components/Modals/ContractSigningModal.vue';
import FinishWithPaperPrintModal from '@/js/components/Modals/FinishWithPaperPrintModal.vue';

const route = useRoute();
const router = useRouter();

const store = useTerminationStore();
const { validateField, validateFields } = store;
const {
  isBankId,
  isParticipant,
  terminationSettings,
  signer,
  personalData,
  contactInformation,
  beforeStart,
  additionalInformation,
  draftToken,
  submitResponse,
} = storeToRefs(store);

const fields = shallowRef([
  'terminationSettings.currentContractEndDate',
  'terminationSettings.transferSignaturePlace',
]);

const validating = ref(false);
const error = ref(false);
const dateFormat = ref('dd. MM. yyyy');
const signingLink = ref(null);
const redirecting = ref(false);
const submitting = ref(false);
const isRedirected = ref(false);
const restApiContractId = ref(null);
const showDigitalSignModal = ref(false);
const showPrintConfirmationModal = ref(false);

const labels = computed(() => {
  return {
    paymentMethod: 'Žádost o výplatu dávky',
    additionalInformation: 'Doplňující údaje',
  };
});

const isLitSignEnabled = computed(() => {
  return env.ENABLE_LITSIGN;
});

const isTerminationTransfer = computed(() => {
  return terminationSettings.value.withTransfer.value;
});

const showPrintHintSentence = computed(() => {
  return (isParticipant.value && !isTerminationTransfer.value && isLitSignEnabled.value)
    || isBankId.value;
});

const isOldAgePensionSelected = computed(() => {
  return terminationSettings.value.paymentOption.value === 'oldAgePensionLongTerm'
    || terminationSettings.value.paymentOption.value === 'oldAgePensionShortTerm';
});

const terminationOption = computed(() => {
  switch (terminationSettings.value.paymentOption.value) {
    case 'oneTimeSettlement':
      return 'Jednorázové vyrovnání';
    case 'smartMoney':
      return 'Odbytné';
    case 'oldAgePensionLongTerm':
      return 'Dlouhodobá penze';
    case 'oldAgePensionShortTerm':
      return 'Krátkodobá penze';
    default:
      return '';
  }
});

function setSignatureDate () {
  terminationSettings.value.currentContractEndDate.value = format(new Date(), 'yyyy-MM-dd');
}

const paymentMethod = computed(() => {
  return additionalInformation.value.paymentMethod.value;
});

const bankAccount = computed(() => {
  if (additionalInformation.value.bankAccount.prefix.value !== null) {
    return `${additionalInformation.value.bankAccount.prefix.value}-${additionalInformation.value.bankAccount.number.value}/${additionalInformation.value.bankAccount.bankCode.value}`;
  }

  return `${additionalInformation.value.bankAccount.number.value}/${additionalInformation.value.bankAccount.bankCode.value}`;
});

async function openModal (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    if (signMethod === 'digital') {
      showDigitalSignModal.value = true;
    }

    if (signMethod === 'offline') {
      showPrintConfirmationModal.value = true;
    }
  } catch (e: any) {
    throw new Error(e);
  }
}

async function sendDraftToken (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    const signature = [
      {
        signer: 'distributor',
        signMethod,
      },
      {
        signer: 'client',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), false, false);

    const { data } = await draftTokens.create(contractUuid as string, signMethod);

    draftToken.value.value.value = data.draftToken;
    draftToken.value.value.expiresAt = data.validTo;

    await router.push({
      name: 'termination.thankYouSignaturePage',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}

function country (countryCode: string | null) {
  const country = countriesOfBirth.find((countryValues) => countryValues.value === countryCode);
  return country?.label;
}

function formatDate (date: string) {
  const dateToFormat = new Date(date);

  return format(dateToFormat, dateFormat.value);
}

function setSignaturePlace () {
  if (typeof beforeStart.value.meetingAddress !== 'undefined') {
    terminationSettings.value.transferSignaturePlace.value = beforeStart.value.meetingAddress.city.value;
  }
}

async function transferSubmitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    await router.push({
      name: 'termination.intermediateStep',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e) {
    validating.value = false;
    scrollToError();

    console.error(`There was a validation error: ${e}`);
  }
}

async function submitStep (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    showDigitalSignModal.value = false;
    error.value = false;
    validating.value = true;
    redirecting.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [],
    });

    const isDigital = signMethod === 'digital';

    if (isDigital) {
      redirecting.value = true;
    } else {
      submitting.value = true;
    }

    if (signMethod === 'offline') {
      terminationSettings.value.isBankId.value = false;
    }

    const signature = [
      {
        signer: 'distributor',
        signMethod,
      },
    ];

    if (!isBankId.value) {
      signature.push({
        signer: 'client',
        signMethod,
      });
    }

    await signatures.update(contractUuid, JSON.stringify(signature), true);

    await submitContract(store.$state, {
      contractUuid: route.params.contractUuid as string,
      throwOnErrors: true,
      signature,
    });

    if (isDigital) {
      // @ts-expect-error: TODO: fix this
      signingLink.value = submitResponse.value.value?.metadata.signingLinks[0].value;
      window.location.href = signingLink.value || '';
    } else {
      terminationSettings.value.isBankId.value = false;

      await router.push({
        name: 'termination.thankYouPage',
        params: {
          contractUuid: route.params.contractUuid,
        },
      });
    }
  } catch (e: any) {
    error.value = true;
    redirecting.value = false;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });

    throw new Error(`Termination contract ${contractUuid} with signMethod ${signMethod} submission error.
          Status code ${e?.response?.status}, Message: ${e?.response?.message}`);
  } finally {
    validating.value = false;
  }
}

watch(signingLink, (newValue) => {
  if (newValue !== null) {
    redirecting.value = false;
  }
});

onMounted(async () => {
  setSignatureDate();

  if (route.query.draftToken) {
    isRedirected.value = true;
  }

  if (beforeStart.value.isTiedAgent.value && (terminationSettings.value.transferSignaturePlace.value === null || terminationSettings.value.transferSignaturePlace.value === '')) {
    setSignaturePlace();
  }

  try {
    const { contractUuid } = route.params;
    const { data } = await show(contractUuid as string, 'termination');

    restApiContractId.value = data.rest_api_contract_id;

    if (restApiContractId.value !== null) {
      await router.push({
        name: 'notFound',
        query: { type: 'termination' },
      });
    }
  } catch (e: any) {
    console.error(e.message());
  }
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';

@include media(max, $xs) {
  .btn-recapitulation {
    flex-direction: column-reverse;
    gap: 16px;
  }
}
</style>
