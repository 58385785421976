<script setup lang="ts" generic="T extends BeforeStartReturn">
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import type { BeforeStartReturn } from '@/js/stores/createStoreFields';
import RecapitulationTableRow from '@/js/components/RecapitulationTableRow.vue';

interface Props {
  storePath: T
  namespace: string
}

const props = defineProps<Props>();

const tableRows = [
  {
    path: props.storePath.distributor.firstName,
    tableKey: 'Jméno',
  },

  {
    path: props.storePath.distributor.lastName,
    tableKey: 'Příjmení',
  },

  {
    path: props.storePath.distributor.email,
    tableKey: 'E-mail',
  },

  {
    path: props.storePath.s1,
    tableKey: 'S1',
  },

  {
    path: props.storePath.s2,
    tableKey: 'S2',
  },

];
</script>

<template>
  <table class="recapitulation-table mb-16">
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Kontaktní údaje distributora</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${props.namespace}.beforeStart` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <RecapitulationTableRow
        v-for="(row, index) in tableRows"
        :key="index"
        :table-key="row.tableKey"
        :path="row.path"
      />

      <RecapitulationTableRow
        v-if="props.storePath.s3.value"
        :path="props.storePath.s3"
        table-key="S3"
      />
    </tbody>
  </table>
</template>
